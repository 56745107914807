import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import StorefrontSharpIcon from '@mui/icons-material/StorefrontSharp';
import { Notification } from 'src/shared/interfaces/notification.interface';
import { StoreDtoInterface } from 'src/store/interfaces/stores/storeDto.interface';
import { Page } from 'src/shared/interfaces/page.interface';
import { UserProfile } from 'src/store/interfaces/users/userProfile';
import { useAppDispatch } from 'src/hooks/hooks';
import { STORAGE } from 'src/shared/consts/Storage.consts';
import { thunkUsersCurrent } from 'src/store/thunks';
import { thunkStores } from 'src/store/thunks/stores';
import { RequestStatuses } from 'src/utils/enums';
import LoadingCircularProgress from 'src/components/LoadingCircularProgress';
import { NotificationList } from 'src/components/Notifications/NotificationList';

const OnboardingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const accessToken = localStorage.getItem(STORAGE.ACCESS_TOKEN);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isStoreLoading, setIsStoreLoading] = useState(true);
  const [store, setStore] = useState<StoreDtoInterface>();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    setIsUserLoading(true);
    setIsStoreLoading(true);
    if (accessToken) {
      Promise.all([
        dispatch(thunkUsersCurrent({ token: accessToken })),
        dispatch(thunkStores({}))
      ]).then(([resultUser, resultStores]) => {
        let notifications: Notification[] = [];
        if (resultUser.meta.requestStatus === RequestStatuses.fulfilled) {
          notifications = [
            ...notifications,
            ...getVerificationNotifications(resultUser.payload as UserProfile)
          ];
          setIsUserLoading(false);
        }
        if (resultStores.meta.requestStatus === RequestStatuses.fulfilled) {
          const page = resultStores.payload as Page<StoreDtoInterface>;
          if (page.content.length > 0) {
            setStore(page.content[0]);
            notifications = [...notifications, getStoreAddedNotification(page.content[0])];
          }
          setIsStoreLoading(false);
        }
        setNotifications(notifications);
      });
    }
  }, [accessToken]);

  function getVerificationNotifications(user: UserProfile): Notification[] {
    const notifications: Notification[] = [];
    if (user.email && user.emailVerified) {
      const notification: Notification = {
        title: 'Email Verified',
        message: user.email
      };
      notifications.push(notification);
    }
    if (user.phoneNumber && user.phoneNumberVerified) {
      const notification: Notification = {
        title: 'Phone Number Verified',
        message: user.phoneNumber
      };
      notifications.push(notification);
    }
    return notifications;
  }

  function getStoreAddedNotification(store: StoreDtoInterface): Notification {
    return {
      title: `${store.name} Added`,
      message: store.formattedAddress || ''
    };
  }

  return (
    <LoadingCircularProgress isLoading={isUserLoading && isStoreLoading} fullElement>
      <Box width='100%' minHeight='100%' display='flex' justifyContent='center' alignItems='center'>
        <Box
          display='flex'
          flexDirection='column'
          border='1px solid rgba(0, 0, 0, 0.12)'
          borderRadius='10px'
          maxWidth='400px'
          sx={{ m: 1, p: 3 }}
        >
          <Box width='100%' display='flex'>
            <Box width='70%' textAlign='left'>
              <Typography variant='h5'>You are all set!</Typography>
              <Typography variant='body2' style={{ fontSize: 12 }}>
                You should now have access to your Digital Coupons from {store?.name} and more!
              </Typography>
            </Box>
            <Box width='30%'>
              <StorefrontSharpIcon style={{ fontSize: 70 }} />
            </Box>
          </Box>
          <NotificationList notifications={notifications} />
        </Box>
      </Box>
    </LoadingCircularProgress>
  );
};

export default OnboardingPage;

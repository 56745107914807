import React from 'react';
import { Box, Button, SvgIcon } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { ReactComponent as SearchIcon } from 'src/images/search_icon.svg';

export default function SearchButton(props: ButtonProps) {
  return (
    <Box
      sx={{
        bgcolor: 'white',
        borderRadius: '8px'
      }}
    >
      <Button
        {...props}
        variant='text'
        color={'primary'}
        startIcon={
          <SvgIcon
            sx={{
              fill: (theme) => theme.palette.primary.main,
              width: 20,
              height: 20
            }}
            component={SearchIcon}
            inheritViewBox
          />
        }
        sx={{
          ...props.sx,
          width: 44,
          height: 44,
          borderRadius: '8px',
          minWidth: 0,
          '& .MuiButton-startIcon': { margin: 0 }
        }}
      />
    </Box>
  );
}

SearchButton.muiName = 'Button';

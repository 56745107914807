import dayjs from 'dayjs';
import {
  DiscountStackTypeDescription,
  DiscountTriggeringType,
  DiscountType,
  DiscountTypeUnit
} from 'src/shared/enums';
import { Discount } from 'src/shared/interfaces/discount.interface';

function formatDiscountAmount(price: number): string {
  const priceInDollars = price / 100;
  return priceInDollars.toFixed(2);
}

export function getDiscountAmount(discount: Discount | null): string | null {
  if (discount?.amounts.length) {
    const unit = DiscountTypeUnit[discount.discountType];
    const discountAmount =
      discount.discountType == DiscountType.PERCENT ||
      discount.discountType == DiscountType.COMBO_MIX_PERCENT
        ? discount.amounts[0].amount
        : formatDiscountAmount(discount.amounts[0].amount);
    return discount.discountType == DiscountType.PERCENT ||
      discount.discountType == DiscountType.COMBO_MIX_PERCENT
      ? discountAmount + unit
      : unit + discountAmount;
  } else {
    return null;
  }
}

export function getDiscountName(discount: Discount | null): string | null {
  if (discount) {
    let discountName = discount.name;
    if (!discountName || discountName.trim() == '')
      discountName = discount.coupon ? 'Coupon' : 'Discount';
    return discountName;
  } else {
    return null;
  }
}

export function getDiscountProductsAmount(discount: Discount | null): string | null {
  if (discount) {
    if (discount.itemsAmount == 0) return 'Any products in the store';
    return discount.itemsAmount + (discount.itemsAmount > 1 ? ' products' : ' product');
  } else {
    return null;
  }
}

export function getDiscountTimeLimit(discount: Discount | null): string | null {
  if (discount) {
    if (!discount.dateTo) {
      return 'No limit time';
    } else {
      return (
        (discount.dateFrom
          ? dayjs(discount.dateFrom).format('MM.DD.YY') + ' - '
          : 'applicable until ') + dayjs(discount.dateTo).format('MM.DD.YY')
      );
    }
  } else {
    return null;
  }
}

export function getDiscountTermsDescription(discount: Discount | null): string | null {
  if (discount) {
    if (discount.coupon) return 'The discount is applied to each product unit.';
    let description = 'Loyalty Discount. Phone Number Required.';
    if (discount.amounts.length)
      description += `  \nQuantity required: ${discount.amounts[0].quantity}.`;
    description += '  \n' + DiscountStackTypeDescription[discount.itemStackType];
    description += '  \n' + getTriggeringType(discount);
    if (discount.notApplyForQuantity)
      description +=
        '  \n' +
        `The discount does not apply to product quantities of more than ${discount.notApplyForQuantity}.`;
    return description;
  } else {
    return null;
  }
}

function getTriggeringType(discount: Discount) {
  switch (discount.triggeringType) {
    case DiscountTriggeringType.RESTRICTED:
      return `Can be used ${
        discount.triggeringTimes == 1 ? 'once' : `${discount.triggeringTimes} times`
      } per transaction.`;
    case DiscountTriggeringType.INFINITE:
      return 'No limit of use per transaction.';
    default:
      throw new Error(`Non-existent discount triggering type`);
  }
}

export function isAvailable(discount: Discount): boolean {
  const dateTo = discount.dateTo == null ? new Date() : new Date(discount.dateTo);
  return discount.enable && (discount.noEnd || discount.dateTo == null || dateTo > new Date());
}

import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import SearchButton from 'src/components/SearchButton/SearchButton';
import MerchantFilter from 'src/components/MerchantFilter/MerchantFilter';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

interface Props {
  header: string;
  currentMerchant?: Merchant | null;
  onMerchantSelect(merchant: Merchant | null): void;
  onSearchSelected(): void;
}

const MerchantFilterSearchBar: React.FC<Props> = ({
  header,
  currentMerchant,
  onMerchantSelect,
  onSearchSelected
}) => {
  return (
    <AppBar position='static' sx={{ height: 'fit-content', zIndex: 1000 }}>
      <Typography variant='h4' color='white' mt={1}>
        {header}
      </Typography>
      <Toolbar sx={{ justifyContent: 'space-between', gap: 2, py: 0.9 }}>
        <MerchantFilter currentMerchant={currentMerchant} onSelect={onMerchantSelect} />
        <SearchButton onClick={() => onSearchSelected()} />
      </Toolbar>
    </AppBar>
  );
};

export default MerchantFilterSearchBar;

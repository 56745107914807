import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, IconButton, SvgIcon, Typography } from '@mui/material';
import IF from 'src/components/IF/IF';
import { AUTH_ROUTES, USER_ROUTES } from 'src/shared/consts/Rout.consts';
import { AuthLayout } from 'src/Layouts/AuthLayout/authLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks/hooks';
import { RequestStatuses } from 'src/utils/enums';
import { thunkResetPassword } from 'src/store/thunks/auth';
import { PasswordResetStatus } from 'src/shared/enums/PasswordReset';
import { getAccessToken } from 'src/utils/scripts';
import { PasswordInput, Validatable } from 'src/components/PasswordInput/PasswordInput';

//images
import { ReactComponent as ArrowLeft } from 'src/images/arrow_left_icon.svg';

interface ResetPasswordFormAttributes {
  password: string;
  confirmPassword: string;
}

export const PasswordResetPage = () => {
  const navigator = useNavigate();
  const accessToken = getAccessToken();
  const dispatch = useAppDispatch();
  const [search] = useSearchParams();
  const token = search.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const attributes: ResetPasswordFormAttributes = { password: '', confirmPassword: '' };
  const [form, setForm] = useState(attributes);
  const handleChange = useCallback(({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setForm((state) => ({ ...state, [name]: value }));
  }, []);
  const passwordInput = useRef<Validatable>(null);

  useEffect(() => {
    if (!token) {
      navigator(AUTH_ROUTES.LOGIN);
    }
  }, [token]);

  async function resetPassword() {
    return dispatch(
      thunkResetPassword({
        token: token!,
        password: form.password
      })
    );
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!passwordInput?.current?.isPasswordValid(form.password, form.confirmPassword)) return;
    setIsLoading(true);
    const result = await resetPassword();
    if (result.meta.requestStatus === RequestStatuses.fulfilled) {
      setIsLoading(false);
      setIsPasswordReset(true);
    } else {
      navigator(`${AUTH_ROUTES.FORGOT_PASSWORD}?status=${PasswordResetStatus.FAILED}`);
    }
  };

  return (
    <AuthLayout>
      <IF condition={!isPasswordReset}>
        <IconButton
          sx={{ width: '32px', height: '32px' }}
          onClick={() => {
            if (accessToken) {
              navigator(USER_ROUTES.PROFILE);
            } else {
              navigator(AUTH_ROUTES.LOGIN);
            }
          }}
        >
          <SvgIcon
            sx={{ fill: 'transparent', width: 32, height: 32 }}
            component={ArrowLeft}
            inheritViewBox
          />
        </IconButton>
        <Box
          display='flex'
          flexDirection='column'
          marginTop={2}
          marginBottom={4}
          gap={2}
          textAlign='start'
        >
          <Typography fontSize={32} sx={{ fontWeight: 'bold', lineHeight: '42px' }}>
            <IF condition={!isPasswordReset}>Reset password</IF>
            <IF condition={isPasswordReset}>Password changed</IF>
          </Typography>
          <Typography variant='body2'>
            <IF condition={!isPasswordReset}>Please type something you’ll remember.</IF>
            <IF condition={isPasswordReset}>Your password has been changed succesfully</IF>
          </Typography>
        </Box>
        <Box
          component='form'
          display='flex'
          flexDirection='column'
          gap={4}
          width='100%'
          onSubmit={handleFormSubmit}
        >
          <PasswordInput
            ref={passwordInput}
            password={form.password}
            confirmPassword={form.confirmPassword}
            passwordLabel='New password'
            onChange={handleChange}
          />
          <Button type='submit' variant='contained' sx={{ height: '48px' }}>
            <IF condition={isLoading}>
              <CircularProgress />
            </IF>
            <IF condition={!isLoading}>Reset password</IF>
          </Button>
        </Box>
      </IF>
      <IF condition={isPasswordReset}>
        <Box
          display='flex'
          flexDirection='column'
          marginBottom={4}
          gap={2}
          textAlign='center'
          sx={{
            marginTop: { xs: '50%', sm: 2 }
          }}
        >
          <Typography variant='body2' fontSize={32} sx={{ fontWeight: 'bold', lineHeight: '42px' }}>
            Password changed
          </Typography>
          <Typography variant='body2'>Your password has been changed successfully</Typography>
        </Box>
        {!!accessToken ? (
          <Button
            onClick={() => navigator(USER_ROUTES.PROFILE)}
            variant='contained'
            sx={{ height: '48px' }}
          >
            Back to profile
          </Button>
        ) : (
          <Button
            onClick={() => navigator(AUTH_ROUTES.LOGIN)}
            variant='contained'
            sx={{ height: '48px' }}
          >
            Back to login
          </Button>
        )}
      </IF>
    </AuthLayout>
  );
};

export enum DiscountType {
  AMOUNT = 'AMOUNT',
  PERCENT = 'PERCENT',
  OVERRIDE = 'OVERRIDE',
  COMBO_MATCH = 'COMBO_MATCH',
  COMBO_MIX_AMOUNT = 'COMBO_MIX_AMOUNT',
  COMBO_MIX_PERCENT = 'COMBO_MIX_PERCENT',
  COMBO_MIX_OVERRIDE = 'COMBO_MIX_OVERRIDE'
}

export const DiscountTypeUnit: { [key in DiscountType]: string } = {
  [DiscountType.AMOUNT]: '$',
  [DiscountType.PERCENT]: '%',
  [DiscountType.OVERRIDE]: '$',
  [DiscountType.COMBO_MATCH]: '$',
  [DiscountType.COMBO_MIX_AMOUNT]: '$',
  [DiscountType.COMBO_MIX_PERCENT]: '%',
  [DiscountType.COMBO_MIX_OVERRIDE]: '$'
};

export enum DiscountStackType {
  MATCH = 'MATCH',
  MIX = 'MIX'
}

export const DiscountStackTypeDescription: { [key in DiscountStackType]: string } = {
  [DiscountStackType.MATCH]: 'Products cannot be mixed.',
  [DiscountStackType.MIX]: 'Products can be mixed.'
};

export enum DiscountTriggeringType {
  RESTRICTED = 'RESTRICTED',
  INFINITE = 'INFINITE'
}

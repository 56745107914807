import React, { FC, useState } from 'react';
//components
import { Button, Stack, Typography } from '@mui/material';
import Modal from 'src/components/Modal/Modal';
import LoadingCircularProgress from 'src/components/LoadingCircularProgress';

//redux
import { thunkCancelOrder } from 'src/store/thunks';
import { setActiveOrder, setOrders } from 'src/store/slices/ordersSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';

//types
//enums
import { RequestStatuses } from 'src/utils/enums';
import { Order } from 'src/shared/interfaces/order.interface';

interface Props {
  orderId: number;
}

const CancelOrderButton: FC<Props> = ({ orderId }) => {
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state) => state.orders.orders);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function cancelOrder() {
    setIsLoading(true);
    const response = await dispatch(thunkCancelOrder({ id: orderId.toString() }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      updateOrder(response.payload as Order);
    }
    setIsModalOpen(false);
    setIsLoading(false);
  }

  function updateOrder(order: Order) {
    dispatch(setActiveOrder(order));
    const index = orders.findIndex((it) => it.id == order.id);
    if (index !== -1) {
      const currentOrder = Object.assign({}, orders[index]);
      currentOrder.state = order.state;
      dispatch(setOrders([...orders.slice(0, index), currentOrder, ...orders.slice(index + 1)]));
    }
  }

  return (
    <>
      <Button
        variant='contained'
        color='error'
        sx={{ flex: 1, m: 2, color: 'white', alignSelf: 'flex-start' }}
        onClick={() => setIsModalOpen(true)}
      >
        Cancel Order
      </Button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        sx={{ width: 'calc(100% - 6rem)', top: '35%', gap: 2 }}
      >
        {isLoading ? (
          <LoadingCircularProgress isLoading={isLoading} fullElement />
        ) : (
          <>
            <Typography variant='h5' component='h2' sx={{ fontWeight: 'bold' }}>
              Cancel Confirmation
            </Typography>
            <Typography variant='h6'>Are you sure you want to cancel your order?</Typography>
            <Stack
              sx={{ flexDirection: 'row', justifyContent: 'space-between', gap: 1, px: '14.5px' }}
            >
              <Button
                variant='contained'
                color='dark'
                sx={{ flex: 1, color: 'white' }}
                onClick={() => setIsModalOpen(false)}
              >
                No
              </Button>
              <Button
                variant='outlined'
                color='dark'
                sx={{ flex: 1, color: 'text.primary' }}
                onClick={cancelOrder}
              >
                Yes
              </Button>
            </Stack>
          </>
        )}
      </Modal>
    </>
  );
};

export default CancelOrderButton;

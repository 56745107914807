import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Link as ReactRouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { AuthLayout } from 'src/Layouts/AuthLayout/authLayout';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  Stack,
  Typography
} from '@mui/material';
import IF from 'src/components/IF/IF';
import { CustomInput, ValueValidatable } from 'src/components/CustomInput/customInput';
import {
  thunkRegister,
  thunkRegisterByMerchant,
  thunkRegistrationInfo,
  thunkResendEmailVerification,
  thunkVerifyByEmail
} from 'src/store/thunks/auth';
import { RequestStatuses } from 'src/utils/enums';
import { PostVerificationTokenDto, RegistrationInfo } from 'src/store/interfaces/auth';
import { InputHelperMessage } from 'src/shared/interfaces/inputHelperMessage.interface';
import { isEmailValid, isPasswordValid, isPhoneNumberValid } from 'src/features/inputValidation';
import { AUTH_ROUTES, MAIN } from 'src/shared/consts/Rout.consts';
import { STORAGE } from 'src/shared/consts/Storage.consts';
import { PasswordInput, Validatable } from 'src/components/PasswordInput/PasswordInput';
import Modal from 'src/components/Modal/Modal';
import { ERROR, HELPER_TEXT } from 'src/shared/consts/Messages.consts';

interface SignUpFormAttributes {
  phoneNumber?: string;
  email?: string;
  fullName?: string;
  password: string;
  confirmPassword: string;
}

const RegisterPage: React.FC = () => {
  const [search] = useSearchParams();
  const registrationToken = search.get('registration-token');
  const emailVerificationToken = search.get('email-verification');
  const dispatch = useAppDispatch();
  const passwordInput = useRef<Validatable>(null);
  const { loading } = useAppSelector((state) => state.main);
  const navigator = useNavigate();
  const attributes: SignUpFormAttributes = { password: '', confirmPassword: '' };
  const [form, setForm] = useState(attributes);
  const handleChange = useCallback(({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setForm((state) => ({ ...state, [name]: value }));
  }, []);
  const [storeName, setStoreName] = useState<string | undefined>();
  const [termsAccept, setTermsAccept] = useState(false);
  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(!!emailVerificationToken);
  const [isVerifyEmailResend, setIsVerifyEmailResend] = useState(false);
  const [isEmailVerifyReady, setIsEmailVerifyReady] = useState(false);
  const [emailVerifyStatus, setEmailVerifyStatus] = useState('');
  const emailInput = useRef<ValueValidatable>(null);
  const phoneInput = useRef<ValueValidatable>(null);
  const handleClose = () => navigator(AUTH_ROUTES.LOGIN);

  async function signUp() {
    if (registrationToken) {
      return dispatch(
        thunkRegisterByMerchant({
          email: form.email,
          fullName: form.fullName,
          phoneNumber: form.phoneNumber?.trim() == '' ? undefined : form.phoneNumber,
          password: form.password as string,
          token: registrationToken
        })
      );
    } else {
      return dispatch(
        thunkRegister({
          email: form.email,
          fullName: form.fullName,
          phoneNumber: form.phoneNumber?.trim() == '' ? undefined : form.phoneNumber,
          password: form.password as string
        })
      );
    }
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!passwordInput?.current?.isPasswordValid(form.password, form.confirmPassword)) return;
    if (
      !isEmailValid(form.email as string) ||
      !isPasswordValid(form.password) ||
      !isPhoneNumberValid(form.phoneNumber) ||
      !termsAccept
    ) {
      return;
    }
    const res = await signUp();
    if (res.meta.requestStatus === RequestStatuses.fulfilled) {
      setIsVerifyEmailOpen(true);
    }
  };

  useEffect(() => {
    if (registrationToken && !storeName) {
      Promise.all([dispatch(thunkRegistrationInfo({ token: registrationToken }))]).then(
        ([result]) => {
          if (result.meta.requestStatus === RequestStatuses.fulfilled) {
            const registrationInfo = result.payload as RegistrationInfo;
            setStoreName(registrationInfo.merchantName);
            form.email = registrationInfo.userEmail;
            form.phoneNumber = registrationInfo.userPhoneNumber;
            if (registrationInfo.userEmail)
              emailInput?.current?.validateValue(registrationInfo.userEmail);
            if (registrationInfo.userPhoneNumber)
              phoneInput?.current?.validateValue(registrationInfo.userPhoneNumber);
            setForm(form);
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    if (emailVerificationToken) {
      Promise.all([dispatch(thunkVerifyByEmail({ token: emailVerificationToken }))]).then(
        ([result]) => {
          if (result.meta.requestStatus === RequestStatuses.fulfilled) {
            const tokenDto = result.payload as PostVerificationTokenDto;
            localStorage.setItem(STORAGE.ACCESS_TOKEN, tokenDto.accessToken);
            setStoreName(tokenDto.storeName);
            setEmailVerifyStatus(RequestStatuses.fulfilled);
          } else {
            setEmailVerifyStatus(RequestStatuses.rejected);
          }
          setIsEmailVerifyReady(true);
        }
      );
    }
  }, [dispatch, navigator, emailVerificationToken]);

  const validateEmail = (value: string): InputHelperMessage => {
    const error = !isEmailValid(value);
    const message = error ? 'Incorrect email address' : '';
    return { message: message, error: error };
  };

  const validatePhoneNumber = (value: string): InputHelperMessage => {
    const error = !isPhoneNumberValid(value);
    const message = error ? ERROR.INCORRECT_PHONE_NUMBER : '';
    return { message: message, error: error };
  };

  const resendVerificationLink = async () => {
    if (emailVerificationToken) {
      const result = await dispatch(
        thunkResendEmailVerification({ token: emailVerificationToken })
      );
      if (result.meta.requestStatus === RequestStatuses.fulfilled) {
        setIsVerifyEmailResend(true);
      }
    }
  };

  const toMainPage = () => {
    navigator(MAIN, { replace: true });
  };

  return (
    <AuthLayout>
      <Stack flex={1} justifyContent='space-between' gap={1}>
        <Box
          component='form'
          display='flex'
          flexDirection='column'
          gap={2}
          width='100%'
          onSubmit={handleFormSubmit}
        >
          <Box marginBottom={1}>
            <Typography variant={'h4'}>
              Sign Up for Digital Coupons
              <br />
              {storeName ? ` From ${storeName}` : ''}
            </Typography>
          </Box>
          <CustomInput
            ref={emailInput}
            type='email'
            name='email'
            label='Email'
            placeholder='mail@example.com'
            value={form.email}
            required
            onChange={handleChange}
            validate={validateEmail}
          />
          <CustomInput
            ref={phoneInput}
            type='tel'
            name='phoneNumber'
            label='Phone'
            placeholder={HELPER_TEXT.PHONE_NUMBER}
            value={form.phoneNumber}
            onChange={handleChange}
            validate={validatePhoneNumber}
          />
          <PasswordInput
            ref={passwordInput}
            password={form.password}
            confirmPassword={form.confirmPassword}
            onChange={handleChange}
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTermsAccept(event.target.checked);
                }}
              />
            }
            label={
              <Typography align='justify'>
                I accept the{' '}
                <Link component={ReactRouterLink} to={AUTH_ROUTES.TERMS_AND_CONDITIONS}>
                  Terms & Conditions
                </Link>{' '}
                and{' '}
                <Link component={ReactRouterLink} to={AUTH_ROUTES.PRIVACY_POLICY}>
                  Privacy Policy
                </Link>
              </Typography>
            }
          />
          <Button type='submit' variant='contained' sx={{ height: '48px' }}>
            <IF condition={loading}>
              <CircularProgress />
            </IF>
            <IF condition={!loading}>Create account</IF>
          </Button>
          <Box display='flex' flexDirection='column' width='100%'>
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => navigator({ pathname: AUTH_ROUTES.LOGIN, search: search.toString() })}
            >
              Already have an account? Sign in
            </Link>
          </Box>
        </Box>
        <Typography align='center'>
          By creating an account or signing, you agree to our{' '}
          <Link component={ReactRouterLink} to={AUTH_ROUTES.TERMS_AND_CONDITIONS}>
            Terms & Conditions
          </Link>{' '}
          and{' '}
          <Link component={ReactRouterLink} to={AUTH_ROUTES.PRIVACY_POLICY}>
            Privacy Policy
          </Link>
        </Typography>
      </Stack>
      <Modal isOpen={isVerifyEmailOpen} onClose={handleClose}>
        <Typography variant='h6' component='h2' sx={{ fontWeight: 'bold' }}>
          Thank you for registering!
        </Typography>
        <Typography id='modal-modal-description' variant='body1' sx={{ mt: 2 }}>
          To continue, please verify your identity. To do this, please go to your email{' '}
          <Link color='secondary' href={`mailto:${form.email}`}>
            {form.email}
          </Link>{' '}
          and complete the verification process.
        </Typography>
      </Modal>
      <Modal isOpen={isVerifyEmail} onClose={handleClose}>
        <IF condition={!isEmailVerifyReady}>
          <CircularProgress sx={{ color: '#0A9E4D !important', alignSelf: 'center' }} />
        </IF>
        <IF condition={isEmailVerifyReady}>
          <IF condition={emailVerifyStatus === RequestStatuses.rejected && !isVerifyEmailResend}>
            <Typography variant='h6' component='h2' sx={{ fontWeight: 'bold' }}>
              Your verification link expired
            </Typography>
            <Typography variant='body1' sx={{ mt: 2 }}>
              Looks like the verification link has expired. Not to worry, we can send the link
              again.
            </Typography>
            <Button
              variant='contained'
              sx={{ height: '48px', mt: 2 }}
              onClick={resendVerificationLink}
            >
              <IF condition={loading}>
                <CircularProgress />
              </IF>
              <IF condition={!loading}>Resend verification link</IF>
            </Button>
          </IF>
          <IF condition={emailVerifyStatus === RequestStatuses.fulfilled}>
            <Typography variant='h6' component='h2' sx={{ fontWeight: 'bold' }}>
              Email Verified
            </Typography>
            <Typography variant='body1' sx={{ mt: 2 }}>
              You Signed Up for Digital Coupons {storeName ? `from ${storeName}` : ''}
            </Typography>
            <Button variant='contained' sx={{ height: '48px', mt: 2 }} onClick={toMainPage}>
              Continue
            </Button>
          </IF>
          <IF condition={isVerifyEmailResend}>
            <Typography variant='h6' component='h2' sx={{ fontWeight: 'bold' }}>
              Please verify your email
            </Typography>
            <Typography variant='body1' sx={{ mt: 2 }}>
              To continue, please verify your identity. To do this, please go to your email and
              complete the verification process.
            </Typography>
          </IF>
        </IF>
      </Modal>
    </AuthLayout>
  );
};
export default RegisterPage;

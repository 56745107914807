import React from 'react';
//mui
import { Stack, SvgIcon, Typography } from '@mui/material';

//components
import { ReactComponent as Logo } from 'src/images/logo.svg';

const SideMenuHeader: React.FC = () => {
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
        bgcolor: 'primary.main',
        px: 2,
        py: 1
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1
        }}
      >
        <SvgIcon
          sx={{ fill: 'transparent', width: 48, height: 48 }}
          component={Logo}
          inheritViewBox
        />
        <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '1.5rem' }}>
          GUARDOE
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SideMenuHeader;

import React from 'react';

//mui
import { Dialog, DialogContent, DialogTitle, Divider, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const sx = {
  '& .MuiDialog-container': {
    alignItems: 'flex-end !important'
  }
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const BottomDialog: React.FC<Props> = ({ isOpen, onClose, title, children }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth={true}
      TransitionComponent={Transition}
      sx={sx}
    >
      <DialogTitle>
        <Typography variant='h5'>{title}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default BottomDialog;

import { MapPosition } from 'src/shared/interfaces/mapPoint.interface';
import { getDistance } from './index';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

export function getMerchantDistance(merchant: Merchant, currentGeolocation: MapPosition | null) {
  if (currentGeolocation) {
    return getDistance(
      currentGeolocation.lat,
      currentGeolocation.lng,
      merchant.latitude,
      merchant.longitude
    );
  }
  return null;
}

export function formatDistance(distance: number | null): string {
  if (!distance) return '';
  let units = 'km';
  if (distance < 10) {
    units = 'm';
    distance = distance * 1000;
  }
  return Math.round(distance).toLocaleString('en-US') + units;
}

import { Box, List } from '@mui/material';
import CouponItem from 'src/components/Discounts/coupons/CouponItem';
import InfiniteScroll from 'src/components/InfiniteScroll/InfiniteScroll';
import React from 'react';
import { useAppSelector } from 'src/hooks/hooks';

interface Props {
  isLoading: boolean;
  loadPage(pageNumber: number): void;
}

const CouponList: React.FC<Props> = ({ isLoading, loadPage }) => {
  const coupons = useAppSelector((state) => state.discountsTab.coupons);
  const currentPage = useAppSelector((state) => state.discountsTab.couponsPage.currentPage);
  const isLastPage = useAppSelector((state) => state.discountsTab.couponsPage.isLastPage);
  return (
    <InfiniteScroll
      elementsLength={coupons?.length}
      isLoading={isLoading}
      isLastPage={isLastPage}
      pageNumber={currentPage}
      loadPage={loadPage}
      pageLoader={<Box sx={{ position: 'relative', top: '-0.5rem' }}>load</Box>}
    >
      <List>
        {coupons?.length &&
          coupons.map((item, index) => <CouponItem item={item} key={index}></CouponItem>)}
      </List>
    </InfiniteScroll>
  );
};

export default CouponList;

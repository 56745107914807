import React, { useEffect, useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';

// mui
import { AppBar, Box, Button, Divider, Skeleton, Stack, Toolbar, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { setActiveDiscount } from 'src/store/slices/discountsTabSlice';
import { setActiveItem } from 'src/store/slices/itemsTabSlice';
import { setActiveMerchant } from 'src/store/slices/merchantsTabSlice';
import { thunkDiscount, thunkDiscountItems } from 'src/store/thunks';

// constants
import { ITEMS_ROUTES, MERCHANTS_ROUTES } from 'src/shared/consts/Rout.consts';

//types
//components
import { LocationButton } from 'src/components/LocationButton';
import { ItemsList } from 'src/components/Items';

//scripts
import { getDiscountName, getDiscountProductsAmount } from 'src/utils/scripts/discount';

//constants
import { RequestStatuses } from 'src/utils/enums';
import { setDiscountId, setDiscountItems } from 'src/store/slices/discountItemsSlice';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { Item, ItemInDiscount } from 'src/shared/interfaces/items.interface';

function DiscountItemsPage() {
  const navigate = useNavigate();
  const urlParams: Readonly<Params<'id'>> = useParams();
  const dispatch = useAppDispatch();
  const discount = useAppSelector((state) => state.discountsTab.activeDiscount);
  const discountItems = useAppSelector((state) => state.discountItems.discountItems);
  const discountItemsId = useAppSelector((state) => state.discountItems.discountId);
  const [isDiscountsLoading, setIsDiscountsLoading] = useState(true);
  const [isDiscountsItemsLoading, setIsDiscountsItemsLoading] = useState(true);

  useEffect(() => {
    if (!discount || discount.id.toString() != urlParams.id!) {
      fetchDiscount(Number(urlParams.id!));
    } else setIsDiscountsLoading(false);
    if (!discountItems.length || discountItemsId?.toString() != urlParams.id!) {
      fetchDiscountItems(Number(urlParams.id!));
    } else setIsDiscountsItemsLoading(false);
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const openMerchant = () => {
    if (discount?.merchant) {
      navigate(MERCHANTS_ROUTES.MERCHANT.replace(':id', discount.merchant.id.toString()));
    }
  };

  const fetchDiscount = async (discountId: number) => {
    setIsDiscountsLoading(true);
    const response = await dispatch(thunkDiscount({ id: discountId }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      dispatch(setActiveDiscount(response.payload as Discount));
      dispatch(setActiveMerchant((response.payload as Discount).merchant));
      setIsDiscountsLoading(false);
    }
  };

  const fetchDiscountItems = async (discountId: number) => {
    setIsDiscountsItemsLoading(true);
    const response = await dispatch(thunkDiscountItems({ id: discountId }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      dispatch(setDiscountItems(response.payload as ItemInDiscount[]));
      dispatch(setDiscountId(discountId));
      setIsDiscountsItemsLoading(false);
    }
  };

  const itemClickHandler = (item: Item) => {
    dispatch(setActiveItem(item));
    navigate(ITEMS_ROUTES.ITEM.replace(':id', item.id.toString()));
  };

  return (
    <Stack
      width='100%'
      height='100%'
      sx={{
        bgcolor: (theme) => theme.palette.light.main
      }}
    >
      <AppBar
        position='static'
        sx={{ boxShadow: 'none', bgcolor: (theme) => theme.palette.light.main }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            py: 0.4,
            gap: 2
          }}
        >
          <Button
            variant='outlined'
            color={'dark'}
            startIcon={<ArrowBackIosNewIcon sx={{ color: (theme) => theme.palette.dark.main }} />}
            onClick={() => goBack()}
            sx={{
              width: 36,
              height: 36,
              borderRadius: '8px',
              minWidth: 0,
              '& .MuiButton-startIcon': { margin: 0 }
            }}
          />
          {discount ? (
            <Typography noWrap variant='h4' color='text.primary'>
              {getDiscountName(discount)}
            </Typography>
          ) : (
            <Skeleton width={'35%'} height={'2.5rem'} />
          )}
          <LocationButton onClick={() => openMerchant()} />
        </Toolbar>
        <Divider></Divider>
        <Stack
          className='shadowed'
          justifyContent='space-between'
          direction='row'
          gap={1}
          bgcolor='white'
          p={2}
        >
          {discount ? (
            <>
              <Typography variant='body1' textAlign='left' fontWeight='bold' color='text.primary'>
                Total positions
              </Typography>
              <Typography variant='body1' textAlign='left' fontWeight='bold' color='text.primary'>
                {getDiscountProductsAmount(discount)}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton width={'35%'} />
              <Skeleton width={'20%'} />
            </>
          )}
        </Stack>
      </AppBar>
      <Box
        sx={{
          overflowY: isDiscountsLoading ? 'hidden' : 'scroll'
        }}
      >
        {!isDiscountsLoading && !isDiscountsItemsLoading ? (
          <>
            {discountItems.length > 0 && (
              <ItemsList items={discountItems} onItemClick={itemClickHandler} />
            )}
          </>
        ) : (
          <ItemsList
            items={[null, null, null, null, null, null, null, null, null, null, null, null, null]}
          />
        )}
      </Box>
    </Stack>
  );
}

export default DiscountItemsPage;

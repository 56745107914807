import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'src/store/axiosConfig';
import { ITEMS_PATH } from 'src/shared/consts/Api.consts';
import { RequestError } from 'src/utils/types';
import { Item } from 'src/shared/interfaces/items.interface';
import { Page } from 'src/shared/interfaces/page.interface';
import { PageRequest } from 'src/shared/interfaces/pageRequest.interface';

export const thunkItem = createAsyncThunk<Item, { id: string }>(
  ITEMS_PATH.ITEM(),
  async ({ id }, thunkAPI) => {
    try {
      const response: AxiosResponse<Item> = await axiosInstance.get(ITEMS_PATH.ITEM(id));

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkItems = createAsyncThunk<Page<Item>, PageRequest>(
  ITEMS_PATH.ITEMS,
  async (pagination, thunkAPI) => {
    try {
      const config = { params: { ...pagination } };
      const response = await axiosInstance.get(ITEMS_PATH.ITEMS, config);

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
    }
  }
);

export const thunkItemsPurchased = createAsyncThunk<Page<Item>, PageRequest>(
  ITEMS_PATH.ITEMS_PURCHASED,
  async (pagination, thunkAPI) => {
    try {
      const config = { params: { ...pagination } };
      const response = await axiosInstance.get(ITEMS_PATH.ITEMS_PURCHASED, config);

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
    }
  }
);

export const thunkItemsByProductCode = createAsyncThunk<
  Page<Item>,
  {
    page?: PageRequest;
    value: { productCode: string | null };
  }
>(ITEMS_PATH.ITEMS, async (data, thunkAPI) => {
  try {
    const config = {
      params: { ...data.page, ...data.value }
    };

    const response = await axiosInstance.get(ITEMS_PATH.ITEMS, config);

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

export const thunkSaveItemCustomName = createAsyncThunk<null, { id: number; customName: string }>(
  ITEMS_PATH.ITEM_CUSTOM_NAME(),
  async ({ id, customName }, thunkAPI) => {
    try {
      const body = { name: customName };

      const response: AxiosResponse<null> = await axiosInstance.post(
        ITEMS_PATH.ITEM_CUSTOM_NAME(id.toString()),
        body
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

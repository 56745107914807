import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/hooks/hooks';
import { setPromptInstall, setPwaInstalled } from 'src/store/slices/promptInstallSlice';
import { Snackbar } from '@mui/material';

const AppInstall = () => {
  const dispatch = useAppDispatch();
  const [appInstalledNotificationOpen, setAppInstalledNotificationOpen] = useState(false);

  const beforeInstallHandler = (event: Event) => {
    event.preventDefault();
    // @ts-ignore
    dispatch(setPromptInstall(event));
  };

  const appInstalledHandler = (event: Event) => {
    dispatch(setPwaInstalled(true));
    setAppInstalledNotificationOpen(true);
  };

  const fetchInstalledApps = async () => {
    if ('getInstalledRelatedApps' in navigator) {
      // @ts-ignore
      const result = await navigator.getInstalledRelatedApps();
      if (result.length > 0) dispatch(setPwaInstalled(true));
    }
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', beforeInstallHandler);
    window.addEventListener('appinstalled', appInstalledHandler);
    fetchInstalledApps();
    return () => {
      window.removeEventListener('transitionend', beforeInstallHandler);
      window.removeEventListener('transitionend', appInstalledHandler);
    };
  }, []);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={appInstalledNotificationOpen}
      autoHideDuration={5000}
      onClose={() => setAppInstalledNotificationOpen(false)}
      message='Guardoe app installed successfully'
    />
  );
};

export default AppInstall;

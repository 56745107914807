import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

// types

interface merchantsTabState {
  merchants: Merchant[];
  activeMerchant: Merchant | null;
  currentPage: number;
  isLastPage: boolean;
}

const initialState: merchantsTabState = {
  merchants: [],
  activeMerchant: null,
  currentPage: 0,
  isLastPage: false
};

export const merchantsTabSlice = createSlice({
  name: 'merchantsTab',
  initialState,

  reducers: {
    setMerchants(state, action: PayloadAction<merchantsTabState['merchants']>) {
      state.merchants = action.payload;
    },
    setActiveMerchant(state, action: PayloadAction<merchantsTabState['activeMerchant']>) {
      state.activeMerchant = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<merchantsTabState['currentPage']>) {
      state.currentPage = action.payload;
    },
    setIsLastPage(state, action: PayloadAction<merchantsTabState['isLastPage']>) {
      state.isLastPage = action.payload;
    }
  }
});

export const { setMerchants, setActiveMerchant, setCurrentPage, setIsLastPage } =
  merchantsTabSlice.actions;

export default merchantsTabSlice.reducer;

import React from 'react';

//mui
import { Box, Stack, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';

const AppInstallInstructionAndroidOpera = () => {
  return (
    <Stack gap={1}>
      <Typography>Install the app on your device to easily access it anytime.</Typography>
      <Box sx={{ display: 'flex' }}>
        <Typography>1. Tap on </Typography>
        <MoreVertIcon sx={{ px: 0.5 }} />
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography>2. Select </Typography>
        <AddCircleOutlineIcon sx={{ pl: 0.6, pr: 1 }} />
        <Typography fontWeight='bold'>Add to... </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography>3. Select </Typography>
        <AddToHomeScreenIcon sx={{ pl: 0.5, pr: 1 }} />
        <Typography fontWeight='bold'>Home screen </Typography>
      </Box>
    </Stack>
  );
};

export default AppInstallInstructionAndroidOpera;

import React, { useEffect, useRef, useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';

// mui
import { Box, Skeleton, Stack, Typography } from '@mui/material';

//components
import SearchBar from 'src/components/SearchBar/SearchBar';
import BottomDrawer from 'src/components/BottomDrawer/BottomDrawer';
import MerchantListItem from 'src/components/Merchants/MerchantListItem';
import { getGeolocation } from 'src/utils/scripts/geolocation';
import { getMerchantDistance } from 'src/utils/scripts/merchants';
import { getDiscountAmount } from 'src/utils/scripts/discount';
import MapAWS from 'src/components/MapAWS/MapAWS';

// constants
import { DISCOUNTS_ROUTES } from 'src/shared/consts/Rout.consts';

// redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { thunkDiscount } from 'src/store/thunks';
import { setActiveDiscount } from 'src/store/slices/discountsTabSlice';
import { setActiveMerchant } from 'src/store/slices/merchantsTabSlice';
import { setGeolocation, setIsGeolocationEnable } from 'src/store/slices/geolocationSlice';

//constants
import { RequestStatuses } from 'src/utils/enums';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { Merchant } from 'src/shared/interfaces/merchant.interface';
import { MapPoint, MapPosition } from 'src/shared/interfaces/mapPoint.interface';

function DiscountStoresPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const discount = useAppSelector((state) => state.discountsTab.activeDiscount);
  const currentGeolocation = useAppSelector((state) => state.geolocation.current);
  const urlParams: Readonly<Params<'id'>> = useParams();
  const container = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [mapPoints, setMapPoints] = useState<MapPoint[]>([]);
  const [currentMarkerLocation, setCurrentMarkerLocation] = useState<MapPosition>();
  const [isDiscountsLoading, setIsDiscountsLoading] = useState(false);
  const [isGeolocationLoading, setIsGeolocationLoading] = useState(true);
  const [drawerInitialHeight, setDrawerInitialHeight] = useState(0);

  useEffect(() => {
    setCurrentGeolocation();
  }, []);

  useEffect(() => {
    if (!discount || discount.id.toString() != urlParams.id) {
      fetchDiscount(Number(urlParams.id!));
    }
  }, []);

  useEffect(() => {
    if (discount?.merchant) setMerchantMapPoint(discount?.merchant);
  }, [discount?.merchant]);

  useEffect(() => {
    if (discount?.merchant && currentGeolocation && !isGeolocationLoading) {
      const discountCopy: Discount = Object.assign({}, discount);
      const merchant: Merchant = Object.assign({}, discount.merchant);
      merchant.distance = getMerchantDistance(discount.merchant, currentGeolocation);
      discountCopy.merchant = merchant;
      if (discount.merchant.distance != merchant.distance)
        dispatch(setActiveDiscount(discountCopy));
    }
  }, [discount?.merchant, currentGeolocation, dispatch]);

  function setCurrentGeolocation() {
    getGeolocation(
      (geolocation) => {
        setIsGeolocationLoading(false);
        dispatch(setGeolocation(geolocation));
        dispatch(setIsGeolocationEnable(true));
      },
      () => {
        setIsGeolocationLoading(false);
        dispatch(setIsGeolocationEnable(false));
      }
    );
  }

  const fetchDiscount = async (discountId: number) => {
    setIsDiscountsLoading(true);
    const response = await dispatch(thunkDiscount({ id: discountId }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      dispatch(setActiveDiscount(response.payload as Discount));
      dispatch(setActiveMerchant((response.payload as Discount).merchant));
      setIsDiscountsLoading(false);
    }
  };

  function setMerchantMapPoint(merchant: Merchant) {
    const position: MapPosition = {
      lat: merchant.latitude,
      lng: merchant.longitude
    };
    setMapPoints([
      {
        id: merchant.id,
        name: merchant.name,
        position: position
      }
    ]);
    setCurrentMarkerLocation(position);
  }

  function setCurrentMerchantLocation(merchant: Merchant) {
    const position: MapPosition = {
      lat: merchant.latitude,
      lng: merchant.longitude
    };
    setCurrentMarkerLocation(position);
  }

  const openDiscount = () => {
    if (discount) {
      dispatch(setActiveDiscount(discount));
      navigate(DISCOUNTS_ROUTES.DISCOUNT.replace(':id', discount.id.toString()));
    }
  };

  return (
    <Stack width='100%' height='100%'>
      <SearchBar />
      <Stack
        width='100%'
        height='100%'
        sx={{
          boxShadow: { xs: 0, sm: 6 },
          position: 'relative',
          justifyContent: 'space-between'
        }}
        ref={container}
      >
        <Box sx={{ display: 'flex', position: 'relative', flex: 1 }}>
          {!!currentMarkerLocation && (
            <MapAWS points={mapPoints} currentPosition={currentMarkerLocation} geolocate={false} />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            height: drawerInitialHeight - 40
          }}
        />
        <BottomDrawer
          header={
            <Stack px={2} pb={2} gap={1}>
              {isDiscountsLoading ? (
                <>
                  <Skeleton width='25%' sx={{ fontSize: '14px', my: 0.3 }} />
                  <Skeleton width='70%' sx={{ fontSize: '1.15rem' }} />
                </>
              ) : (
                <>
                  {!!discount && (
                    <>
                      <Stack direction='row' gap={1} alignItems='center'>
                        <Typography
                          ref={headerRef}
                          variant='body1'
                          align='left'
                          sx={{ fontWeight: '600', userSelect: 'none' }}
                        >
                          Disocunt
                        </Typography>
                        <Box
                          width='fit-content'
                          boxSizing='border-box'
                          maxWidth='90%'
                          bgcolor='primary.main'
                          py={0.2}
                          px={1.4}
                          borderRadius={10}
                          zIndex={10}
                          sx={{ alignSelf: 'center', userSelect: 'none' }}
                        >
                          <Typography fontSize='0.9rem' fontWeight='500' color={'white'}>
                            {getDiscountAmount(discount)}
                          </Typography>
                        </Box>
                      </Stack>
                      <Typography
                        ref={headerRef}
                        variant='h5'
                        align='left'
                        color='text.primary'
                        sx={{ fontWeight: 'bold', userSelect: 'none', overflowWrap: 'break-word' }}
                      >
                        {discount?.name}
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Stack>
          }
          contentMinHeight={90}
          maxHeight={(container.current ? container.current.clientHeight : 0) * 0.9}
          onSetInitialHeight={setDrawerInitialHeight}
        >
          {isDiscountsLoading ? (
            <MerchantListItem component='div' merchant={null} />
          ) : (
            <>
              {!!discount && (
                <MerchantListItem
                  component='div'
                  merchant={discount!.merchant}
                  divider
                  onClick={openDiscount}
                  onSecondaryClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    setCurrentMerchantLocation(discount.merchant);
                  }}
                />
              )}
            </>
          )}
        </BottomDrawer>
      </Stack>
    </Stack>
  );
}

export default DiscountStoresPage;

import React, { FC } from 'react';
import { Divider, ListItemButton, Skeleton, Stack, Typography } from '@mui/material';
import { formatDistance } from 'src/utils/scripts/merchants';
import { LocationButton } from 'src/components/LocationButton';
import { formatItemPrice } from 'src/utils/scripts/items';
import { MerchantItem } from 'src/shared/interfaces/items.interface';

interface Props {
  item: MerchantItem | null;
  onClick?: (item: MerchantItem) => void | null;
  onSecondaryClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const MerchantItemCard: FC<Props> = ({ item, onClick, onSecondaryClick = () => {} }) => {
  return (
    <>
      {!!item ? (
        <ListItemButton
          onClick={() => {
            if (onClick) onClick(item!);
          }}
          className='shadowed'
          sx={{
            boxSizing: 'border-box',
            flexDirection: 'column',
            p: 2,
            pb: 1,
            bgcolor: 'white',
            borderRadius: '8px',
            textAlign: 'left',
            alignItems: 'stretch',
            pointerEvents: onClick ? 'auto' : 'none'
          }}
        >
          <Typography variant='body1' fontWeight='bold' sx={{ overflowWrap: 'break-word' }}>
            {item.merchant.name}
          </Typography>
          <Typography variant='body2' color='text.secondary' sx={{ overflowWrap: 'break-word' }}>
            {item.merchant.formattedAddress}
          </Typography>
          <Stack
            sx={{
              my: 1.5,
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Typography
              noWrap
              textAlign='right'
              sx={{
                flex: 1,
                flexShrink: 0,
                textAlign: 'end',
                userSelect: 'none'
              }}
              color={'secondary'}
            >
              {formatDistance(item.merchant.distance)}
            </Typography>
            <LocationButton
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                onSecondaryClick(event);
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              onTouchStart={(event) => {
                event.stopPropagation();
              }}
            />
          </Stack>
          <Divider />
          <Stack direction='row' justifyContent='space-between' gap={2} pt={1}>
            <Typography variant='body1' color='text.secondary'>
              Price
            </Typography>
            <Typography variant='h5' fontWeight={600} textAlign='right'>
              {formatItemPrice(item.price)}
            </Typography>
          </Stack>
        </ListItemButton>
      ) : (
        <Skeleton variant='rounded' width='auto' height={'4rem'} sx={{ mx: 2, my: 1 }} />
      )}
    </>
  );
};

export default MerchantItemCard;

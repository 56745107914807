import React, { FC } from 'react';

//components
import {
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import OrdersState from 'src/components/OrderState';

//types
import { Order } from 'src/shared/interfaces/order.interface';

//scripts
import { getTotalFormatted } from 'src/utils/scripts/orders';

interface Props {
  order: Order;
  onClick?(): void;
}

const OrdersListItem: FC<Props> = ({ order, onClick = () => {} }) => {
  return (
    <ListItem
      className='item'
      onClick={onClick}
      sx={{
        width: 'auto',
        height: 'fit-content',
        borderRadius: '8px',
        p: 0
      }}
    >
      <ListItemButton
        dense
        sx={{
          px: 2,
          py: 1.25,
          borderRadius: '8px',
          bgcolor: 'white',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'space-between'
        }}
      >
        <Stack flexDirection='row' justifyContent='space-between' gap={2}>
          <ListItemText
            sx={{ flex: 1, overflowWrap: 'break-word' }}
            primary={<Typography sx={{ fontWeight: 'bold' }}>ID: {order.externalId}</Typography>}
            secondary={order.merchant.name}
          />
          <Stack alignItems='center'>
            <Typography
              variant={'body2'}
              color={'text.secondary'}
              sx={{ fontSize: '10px', lineHeight: '14px' }}
              noWrap
            >
              Total
            </Typography>
            <Typography
              variant={'h4'}
              sx={{ fontSize: '18px', lineHeight: '25.2px', overflowWrap: 'break-word' }}
              noWrap
            >
              {getTotalFormatted(order)}
            </Typography>
          </Stack>
        </Stack>
        <Box width='100%' pb={0.5} boxSizing='border-box'>
          <Divider sx={{ mb: 1 }} />
          <OrdersState orderState={order.state} />
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default OrdersListItem;

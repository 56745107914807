import { isPricePerUnit } from 'src/utils/scripts/cart';
import { QUANTITY_PER_UNIT } from 'src/shared/consts/Orders.consts';
import { Order, OrderCreationDto } from 'src/shared/interfaces/order.interface';
import { thunkCreateOrder } from 'src/store/thunks';
import { RequestStatuses } from 'src/utils/enums';
import { setOrders } from 'src/store/slices/ordersSlice';
import { setCartItems } from 'src/store/slices/cartSlice';
import { Box, Button, CircularProgress, List, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { ITEMS_ROUTES, ORDERS_ROUTES } from 'src/shared/consts/Rout.consts';
import { AxiosResponse } from 'axios';
import { axiosInstance } from 'src/store/axiosConfig';
import { ORDER_PATH } from 'src/shared/consts/Api.consts';
import { formatItemPrice } from 'src/utils/scripts/items';
import MerchantItemsListItem from 'src/components/Items/MerchantItemsListItem';
import { Item } from 'src/shared/interfaces/items.interface';
import { setActiveItem } from 'src/store/slices/itemsTabSlice';

const defaultState: TotalOrderData = {
  subtotal: 0,
  tax: 0,
  discount: 0,
  total: 0
};

interface TotalOrderData {
  subtotal: number;
  discount: number;
  tax: number;
  total: number;
}

function CheckoutComponent() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [checkoutState, setCheckoutState] = useState<TotalOrderData>(defaultState);
  const cartItems = useAppSelector((state) => state.cart.items);
  const [isLoading, setIsLoading] = useState(false);
  const orders = useAppSelector((state) => state.orders.orders);
  const items = cartItems.map((it) => ({
    itemId: it.item.id,
    quantity: isPricePerUnit(it.item.priceType)
      ? Math.round(it.quantity * QUANTITY_PER_UNIT)
      : it.quantity
  }));

  const clickHandler = (item: Item) => {
    dispatch(setActiveItem(item));
    navigate(ITEMS_ROUTES.ITEM.replace(':id', item.id.toString()));
  };

  const getDiscounts = (): number => {
    return cartItems.reduce((sum, cartItem) => sum + (cartItem.item.discountAmount || 0), 0);
  };

  const fetchCreateOrder = async () => {
    setIsLoading(true);
    const merchantId = cartItems[0]?.item.merchantId;

    const request: OrderCreationDto = { merchantId, items };
    const response = await dispatch(thunkCreateOrder(request));

    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      dispatch(setOrders([response.payload as Order, ...orders]));
      dispatch(setCartItems([]));
    }
    setIsLoading(false);
  };

  const handelCreateOrder = () => {
    fetchCreateOrder().then(() => {
      navigate(ORDERS_ROUTES.ORDERS);
    });
  };
  useEffect(() => {
    const body = {
      items,
      merchantId: cartItems[0]?.item.merchantId
    };
    const fetchTotalData = async () => {
      try {
        const response: AxiosResponse<TotalOrderData> = await axiosInstance.post(
          `${ORDER_PATH.ORDERS_TAXES}`,
          body
        );
        const data = response.data;
        setCheckoutState(data);
      } catch (e) {}
    };
    fetchTotalData().then();
  }, []);
  return (
    <>
      <Stack flex={1} overflow='auto'>
        <List sx={{ width: '100%' }} disablePadding>
          {cartItems.map((c) => (
            <MerchantItemsListItem
              key={c.item.id}
              item={c.item}
              onClick={() => clickHandler(c.item)}
            />
          ))}
        </List>
      </Stack>
      <Paper elevation={4} sx={{ p: 2, borderRadius: 0, zIndex: 100 }}>
        <Box gap={1} display='flex' flexDirection='column' pb={2}>
          <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
            <Typography variant='subtitle1'>Discount </Typography>{' '}
            <Typography variant='subtitle1' sx={{ overflowWrap: 'break-word' }} noWrap>
              -{formatItemPrice(getDiscounts())}
            </Typography>
          </Stack>
          <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='body1'>SubTotal:</Typography>
            <Typography variant='body1' fontWeight={'700'}>
              {formatItemPrice(checkoutState.subtotal)}
            </Typography>
          </Stack>

          <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='body1'>Tax:</Typography>
            <Typography variant='body1' fontWeight={'700'}>
              {formatItemPrice(checkoutState.tax)}
            </Typography>
          </Stack>
          <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>Total:</Typography>
            <Typography variant='h5' fontWeight={'700'}>
              {formatItemPrice(checkoutState.total)}
            </Typography>
          </Stack>
        </Box>
        <Button
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={29} /> : null}
          fullWidth
          variant='contained'
          onClick={handelCreateOrder}
        >
          {!isLoading && 'Create Order'}
        </Button>
      </Paper>
    </>
  );
}

export default CheckoutComponent;

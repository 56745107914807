import React, { ReactElement } from 'react';
//mui
import { Stack } from '@mui/material';

//types
import { ISideMenuItemsListItem } from 'src/components/SideMenu/SideMenuItemsListItem';

interface Props {
  children?: ReactElement<ISideMenuItemsListItem> | Array<ReactElement<ISideMenuItemsListItem>>;
}

const SideMenuItemsContainer: React.FC<Props> = ({ children }) => {
  return (
    <Stack sx={{ bgcolor: 'white', borderRadius: '8px', overflow: 'hidden' }}>{children}</Stack>
  );
};

export default SideMenuItemsContainer;

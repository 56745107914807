import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface AppInstallState {
  promptInstall: Event | null;
  pwaInstalled: boolean;
}

const initialState: AppInstallState = {
  promptInstall: null,
  pwaInstalled: false
};

export const promptInstallSlice = createSlice({
  name: 'promptInstall',
  initialState,

  reducers: {
    setPromptInstall(state, action: PayloadAction<AppInstallState['promptInstall']>) {
      state.promptInstall = action.payload;
    },
    setPwaInstalled(state, action: PayloadAction<AppInstallState['pwaInstalled']>) {
      state.pwaInstalled = action.payload;
    }
  }
});

export const { setPromptInstall, setPwaInstalled } = promptInstallSlice.actions;

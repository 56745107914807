import React, { useEffect, useState } from 'react';

//mui
import { Box } from '@mui/material';

//components
import { MerchantItemsList } from 'src/components/Items';
import InfiniteScroll from 'src/components/InfiniteScroll/InfiniteScroll';

//types
import { Item } from 'src/shared/interfaces/items.interface';
import { Page } from 'src/shared/interfaces/page.interface';

interface Props {
  search(pageNumber: number): Promise<Page<Item> | null>;

  onItemClick(item: Item): void;
}

const ItemSearchResult: React.FC<Props> = ({ search, onItemClick }) => {
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    loadPage(currentPage);
  }, []);

  const fetchItemsByProductCode = async (pageNumber: number) => {
    setIsLoading(true);
    const result = await search(pageNumber);
    if (result) {
      setItemsResponse(result, pageNumber);
    }
    setIsLoading(false);
  };

  function setItemsResponse(payload: Page<Item>, pageNumber: number) {
    const { content } = payload;
    setIsLastPage(payload.last);
    if (payload.totalElements == 1) {
      onItemClick(content[0]);
    } else {
      if (pageNumber == 0) {
        setItems(content);
      } else {
        setItems([...items, ...content]);
      }
    }
  }

  const loadPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchItemsByProductCode(pageNumber);
  };

  return (
    <Box sx={{ height: '100%', display: 'flex' }}>
      <InfiniteScroll
        elementsLength={items.length}
        isLoading={isLoading}
        isLastPage={isLastPage}
        pageNumber={currentPage}
        notFoundMessage='No products found'
        loadPage={loadPage}
        pageLoader={
          <Box sx={{ position: 'relative', top: '-0.5rem' }}>
            <MerchantItemsList items={[null]} />
          </Box>
        }
      >
        <MerchantItemsList items={items} onItemClick={onItemClick} />
      </InfiniteScroll>
    </Box>
  );
};

export default ItemSearchResult;

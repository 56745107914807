import React from 'react';
import { Box, IconButton, SvgIcon, Typography } from '@mui/material';
import { AuthLayout } from 'src/Layouts/AuthLayout/authLayout';
import { useNavigate } from 'react-router-dom';
import { AUTH_ROUTES, USER_ROUTES } from 'src/shared/consts/Rout.consts';
import { getAccessToken } from 'src/utils/scripts';

//images
import { ReactComponent as ArrowLeft } from 'src/images/arrow_left_icon.svg';

export const PasswordResetCheckEmailPage = () => {
  const navigator = useNavigate();
  const accessToken = getAccessToken();

  return (
    <AuthLayout>
      <IconButton
        sx={{ width: '32px', height: '32px' }}
        onClick={() => {
          if (accessToken) {
            navigator(USER_ROUTES.PROFILE);
          } else {
            navigator(AUTH_ROUTES.LOGIN);
          }
        }}
      >
        <SvgIcon
          sx={{ fill: 'transparent', width: 32, height: 32 }}
          component={ArrowLeft}
          inheritViewBox
        />
      </IconButton>
      <Box
        display='flex'
        flexDirection='column'
        marginTop={2}
        marginBottom={4}
        gap={2}
        textAlign='start'
      >
        <Typography fontSize={32} sx={{ fontWeight: 'bold', lineHeight: '42px' }}>
          Please check your email
        </Typography>
        <Typography variant='body2'>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          If there's a Guardoe account connected to this email address, we'll email you password
          reset instructions.
        </Typography>
      </Box>
    </AuthLayout>
  );
};

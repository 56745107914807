import React, { useState } from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

//mui
import { Box, Button, Link, Stack, Typography } from '@mui/material';

//components
import QuantityInput, { MIN_QUANTITY } from 'src/components/QuantityInput/QuantityInput';
import Modal from 'src/components/Modal/Modal';

//redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';

//types
import { CartItem } from 'src/shared/interfaces/cartItem.interface';

//utils
import { getCartItemsWithNewQuantity } from 'src/utils/scripts/cart';
import { getAccessToken } from 'src/utils/scripts';
import { AUTH_ROUTES } from 'src/shared/consts/Rout.consts';
import { Item } from 'src/shared/interfaces/items.interface';
import { fetchUpdateCartItem } from 'src/utils/scripts/fetchCart';

interface Props {
  item: Item;
}

const AddToCartButton: React.FC<Props> = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cartItems = useAppSelector((state) => state.cart.items);
  const [quantity, setQuantity] = useState(MIN_QUANTITY);
  const [isLoginToContinueOpen, setIsLoginToContinueOpen] = useState(false);
  const accessToken = getAccessToken();

  function addToCart() {
    if (!!accessToken) {
      const cartItem: CartItem = {
        item: item,
        quantity: quantity
      };
      const index = cartItems.findIndex((it) => it.item.id === item.id);
      let newCartItems;

      if (index !== -1) {
        const newQuantity = cartItems[index].quantity + quantity;
        newCartItems = getCartItemsWithNewQuantity(cartItems, cartItem, newQuantity);
      } else {
        newCartItems = [...cartItems, cartItem];
      }

      fetchUpdateCartItem(newCartItems, dispatch).then();
    } else {
      setIsLoginToContinueOpen(true);
    }
  }

  return (
    <Stack
      sx={{ p: 2, flexDirection: 'row', alignItems: 'center', gap: 1, backgroundColor: 'white' }}
    >
      <QuantityInput quantity={quantity} item={item} setQuantity={setQuantity} />
      <Button variant='contained' sx={{ minWidth: 'fit-content' }} onClick={addToCart}>
        Add to Cart
      </Button>
      <Modal
        isOpen={isLoginToContinueOpen}
        onClose={() => {
          setIsLoginToContinueOpen(false);
        }}
        sx={{ gap: 2 }}
      >
        <Stack sx={{ height: '100%', gap: 2, justifyContent: 'center' }}>
          <Typography variant='h4'>Please log in to place an order</Typography>
          <Button
            type='submit'
            variant='contained'
            sx={{ height: '48px' }}
            onClick={() => navigate(AUTH_ROUTES.LOGIN)}
          >
            Log In
          </Button>
          <Box display='flex' flexDirection='column' width='100%'>
            <Link
              style={{ cursor: 'pointer' }}
              component={ReactRouterLink}
              to={AUTH_ROUTES.REGISTER}
            >
              {"Don't have an account? Sign up"}
            </Link>
          </Box>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default AddToCartButton;

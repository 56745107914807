import React from 'react';

//mui
import SideMenu from 'src/components/SideMenu/SideMenu';

//constants

const MenuPage: React.FC = () => {
  return <SideMenu />;
};

export default MenuPage;

import React from 'react';
import { Popover, PopoverPosition, PopoverProps, PopoverState, Rect } from 'react-tiny-popover';
import { CustomArrowContainer } from 'src/components/CustomPovower/CustomArrowContainer';

export default function CustomPopover(props: PopoverProps) {
  const getTransform = (popoverState: PopoverState) => {
    const top = getTopTransform(
      popoverState.position,
      popoverState.childRect,
      popoverState.popoverRect
    );
    const left = getLeftTransform(
      popoverState.position,
      popoverState.childRect,
      popoverState.popoverRect
    );
    return {
      top: popoverState.align == 'end' ? top : -top,
      left: left
    };
  };

  return (
    <Popover
      {...props}
      isOpen={props.isOpen}
      containerStyle={{ zIndex: '1000' }}
      content={({ position, childRect, popoverRect }) => (
        <CustomArrowContainer position={position} childRect={childRect} popoverRect={popoverRect}>
          {props.content as JSX.Element}
        </CustomArrowContainer>
      )}
      transformMode={'relative'}
      transform={getTransform}
    >
      {props.children}
    </Popover>
  );
}

const minPadding = 30;

export function getLeftTransform(
  position: PopoverPosition | undefined,
  childRect: Rect,
  popoverRect: Rect
): number {
  let leftTransform = 0;
  if (isPositionVertical(position)) {
    if (
      0 <= Math.ceil(childRect.left - popoverRect.left) &&
      childRect.left - popoverRect.left < minPadding
    ) {
      leftTransform = -minPadding + childRect.left - popoverRect.left;
    }
    if (
      0 < Math.ceil(popoverRect.right - childRect.right) &&
      popoverRect.right - childRect.right < minPadding
    ) {
      leftTransform = minPadding - (popoverRect.right - childRect.right);
    }
  }
  return leftTransform;
}

export function getTopTransform(
  position: string | undefined,
  childRect: Rect,
  popoverRect: Rect
): number {
  let topTransform = 0;
  if (isPositionHorizontal(position)) {
    if (
      0 <= -minPadding + childRect.top - popoverRect.top &&
      childRect.top - popoverRect.top < minPadding
    ) {
      topTransform = -minPadding + childRect.top - popoverRect.top;
    }
    if (
      0 <= minPadding - (popoverRect.bottom - childRect.bottom) &&
      popoverRect.bottom - childRect.bottom < minPadding
    ) {
      topTransform = minPadding - (popoverRect.bottom - childRect.bottom);
    }
  }
  return topTransform;
}

export function isPositionVertical(position: string | undefined) {
  return position == 'top' || position == 'bottom';
}

export function isPositionHorizontal(position: string | undefined) {
  return position == 'left' || position == 'right';
}

import React from 'react';

// mui
import { Box, Stack, Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
  header: string;
}

const PrivacyPolicyComponent: React.FC<Props> = ({ children, header }) => {
  return (
    <Stack
      sx={{
        height: '100vh',
        alignItems: 'center',
        px: 5,
        py: 3,
        gap: 3
      }}
    >
      <Typography variant='h2'>{header}</Typography>
      <Box
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          textAlign: 'justify',
          overflowY: 'scroll'
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};

export default PrivacyPolicyComponent;

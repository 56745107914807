export const profileTheme = {
  '& label': {
    fontSize: 18,
    top: -16,
    left: -12
  },
  '& legend': {
    width: '0'
  },
  '& .MuiInputBase-root': {
    pr: '0 !important'
  },
  '& .MuiInputBase-root:not(.Mui-focused)': {
    background: '#F7F6F9',
    borderRadius: '8px',
    paddingRight: '14px'
  },
  '& .MuiInputBase-input:not(.Mui-focused .MuiInputBase-input)': {
    pr: '0 !important'
  },
  '& .MuiOutlinedInput-notchedOutline:not(.Mui-focused .MuiOutlinedInput-notchedOutline)': {
    display: 'none'
  },
  '& .Mui-focused .MuiInputAdornment-root': {
    display: 'none'
  },
  fontSize: '1rem',
  marginTop: 2.6
};

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Link, Typography } from '@mui/material';
import { AuthLayout } from 'src/Layouts/AuthLayout/authLayout';
import { CustomInput } from 'src/components/CustomInput/customInput';
import IF from 'src/components/IF/IF';
import { InputHelperMessage } from 'src/shared/interfaces/inputHelperMessage.interface';
import { isEmailValid } from 'src/features/inputValidation';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { thunkPasswordResetEmail } from 'src/store/thunks/auth';
import { AUTH_ROUTES, USER_ROUTES } from 'src/shared/consts/Rout.consts';
import { RequestStatuses } from 'src/utils/enums';
import { NotificationContainer } from 'src/components/Notifications/NotificationContainer';
import { PasswordResetStatus } from 'src/shared/enums/PasswordReset';
import { NOTIFICATION_TYPE } from 'src/shared/consts/NotificationType.consts';
import { getAccessToken } from 'src/utils/scripts';
import { thunkUsersCurrent } from 'src/store/thunks';
import { UserProfile } from 'src/store/interfaces/users/userProfile';
import { setUser } from 'src/store/slices/userSlice';

const ForgotPasswordPage = () => {
  const [search] = useSearchParams();
  const token = getAccessToken();
  const user = useAppSelector((state) => state.user.current);
  const status =
    search.get('status') !== null ? (search.get('status') as PasswordResetStatus) : null;
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(user ? user.email : '');
  const handleChange = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setEmail(value);
  }, []);

  useEffect(() => {
    if (!user && token) {
      fetchUser();
    }
  }, [user, token]);

  const fetchUser = async () => {
    const response = await dispatch(thunkUsersCurrent({ token: token! }));
    const content = response.payload as UserProfile;
    setEmail(content.email);
    dispatch(setUser(content));
  };

  const validateEmail = (value: string): InputHelperMessage => {
    const error = !isEmailValid(value);
    const message = error ? 'Incorrect email address' : '';
    return { message: message, error: error };
  };

  async function requestPasswordResetEmail() {
    return dispatch(
      thunkPasswordResetEmail({
        email: email
      })
    );
  }

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isEmailValid(email)) {
      return;
    }
    setIsLoading(true);
    const result = await requestPasswordResetEmail();
    if (result.meta.requestStatus === RequestStatuses.fulfilled) {
      navigator(AUTH_ROUTES.FORGOT_PASSWORD_CHECK_EMAIL);
    }
    setIsLoading(false);
  };

  return (
    <AuthLayout
      sx={{
        height: { xs: '100%', sm: 'auto' },
        boxSizing: 'border-box',
        overflowY: { xs: 'scroll', sm: 'auto' }
      }}
    >
      <Box display='flex' flexDirection='column' marginBottom={4} gap={2} textAlign='justify'>
        <Typography variant='h2' sx={{ fontWeight: 'bold', lineHeight: '42px' }}>
          Forgot password?
        </Typography>
        <Typography>
          Don’t worry! It happens. Please enter the email associated with your account.
        </Typography>
      </Box>
      <Box
        component='form'
        display='flex'
        flexDirection='column'
        gap='30px'
        width='100%'
        onSubmit={handleFormSubmit}
      >
        <CustomInput
          type='email'
          name='email'
          label='Your email'
          placeholder='mail@example.com'
          value={email}
          required
          onChange={handleChange}
          validate={validateEmail}
          readOnly={!!user}
        />
        <IF condition={status == PasswordResetStatus.FAILED && (!email || !!token)}>
          <NotificationContainer
            title={
              'Sorry, your password reset link is not valid. Enter your email, and we’ll re-send you instructions on how to reset your password.'
            }
            type={NOTIFICATION_TYPE.ERROR}
          />
        </IF>
        <Button type='submit' variant='contained' sx={{ height: '48px' }}>
          <IF condition={isLoading}>
            <CircularProgress />
          </IF>
          <IF condition={!isLoading}>Send code</IF>
        </Button>
      </Box>
      <Box sx={{ width: '100%', marginTop: { xs: 'auto', sm: '20px' } }}>
        <Typography>
          {!!token ? (
            <>
              Remember password?{' '}
              <Link href='#' onClick={() => navigator(USER_ROUTES.PROFILE)} variant='button'>
                Back to profile
              </Link>
            </>
          ) : (
            <>
              {' '}
              Remember password?{' '}
              <Link href='#' onClick={() => navigator(AUTH_ROUTES.LOGIN)} variant='button'>
                Log in
              </Link>
            </>
          )}
        </Typography>
      </Box>
    </AuthLayout>
  );
};
export default ForgotPasswordPage;

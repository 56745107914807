import React from 'react';
import { ListItem, ListItemButton } from '@mui/material';
import { AutocompleteInterface } from 'src/store/interfaces/searches/autocomplete.interface';
import AutocompleteTopography from 'src/components/SearchBar/Autocomplete/AutocompleteTopography';

interface Props {
  input: string;
  autocomplete: AutocompleteInterface;

  onClick(): void;
}

const AutocompleteListItem: React.FC<Props> = ({ input, autocomplete, onClick }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        dense
        sx={{
          pt: 0,
          pb: 0,
          ':hover': {
            color: 'grey'
          }
        }}
        onClick={() => onClick()}
      >
        <AutocompleteTopography input={input} autocomplete={autocomplete.value} />
      </ListItemButton>
    </ListItem>
  );
};

export default AutocompleteListItem;

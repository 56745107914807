import React from 'react';

// mui
import { Button, Stack, Typography } from '@mui/material';

//consts
import { MERCHANTS_ROUTES } from 'src/shared/consts/Rout.consts';

const NotFoundPage = () => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        px: 2
      }}
    >
      <Typography
        color='#D0DBE7'
        variant='h1'
        sx={{ whiteSpace: 'nowrap', fontSize: 'calc(100vw / .625 / 5)' }}
      >
        404
      </Typography>
      <Typography color='text.primary' variant='h3' mt={3}>
        Ooops! Page not found.
      </Typography>

      <Typography color='text.secondary' variant='h5' mt={2}>
        Wee can{"'"}t find the page you{"'"}re looking for.
      </Typography>
      <Button
        variant='contained'
        color='primary'
        href={MERCHANTS_ROUTES.MERCHANTS}
        sx={{ mt: 4, color: 'white' }}
      >
        Go back home
      </Button>
    </Stack>
  );
};

export default NotFoundPage;

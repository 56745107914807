import React, { useEffect, useState } from 'react';

//components
import SideMenuItemsContainer from 'src/components/SideMenu/SideMenuItemsContainer';
import SideMenuItemsListItem from 'src/components/SideMenu/SideMenuItemsListItem';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import IF from 'src/components/IF';
import { useAppSelector } from 'src/hooks/hooks';

const AppInstallButtonPrompt = () => {
  const [supportsPWA, setSupportsPwa] = useState(false);
  const pwaInstalled = useAppSelector((state) => state.pwaInstall.pwaInstalled);
  const promptInstall = useAppSelector((state) => state.pwaInstall.promptInstall);

  useEffect(() => {
    setSupportsPwa(!!promptInstall);
  }, [promptInstall]);

  const installApp = () => {
    if (!promptInstall) {
      return;
    }
    // @ts-ignore
    promptInstall.prompt();
  };

  return (
    <>
      <IF condition={supportsPWA && !pwaInstalled}>
        <SideMenuItemsContainer>
          <SideMenuItemsListItem
            icon={InstallMobileIcon}
            title='Install app'
            onClick={() => installApp()}
            disableDivider
          />
        </SideMenuItemsContainer>
      </IF>
    </>
  );
};

export default AppInstallButtonPrompt;

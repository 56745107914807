import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

// mui
import { Box, Typography } from '@mui/material';

// components
import BottomDrawer from 'src/components/BottomDrawer/BottomDrawer';
import MerchantList from 'src/components/Merchants/MerchantList';
import InfiniteScroll from 'src/components/InfiniteScroll/InfiniteScroll';

// hooks
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';

// redux
import { thunkUsersMerchants } from 'src/store/thunks';
import {
  setActiveMerchant,
  setCurrentPage,
  setIsLastPage,
  setMerchants
} from 'src/store/slices/merchantsTabSlice';

// constants
import { MERCHANTS_ROUTES } from 'src/shared/consts/Rout.consts';

// types
import { Page } from 'src/shared/interfaces/page.interface';
import { MapPoint, MapPosition } from 'src/shared/interfaces/mapPoint.interface';
import IF from 'src/components/IF';
import { getDistance } from 'src/utils/scripts';
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { Merchant } from 'src/shared/interfaces/merchant.interface';
import { Discount } from 'src/shared/interfaces/discount.interface';

interface OutletContextProperty<T> {
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
}

interface OutletContextProps {
  mapPoints: OutletContextProperty<MapPoint[]>;
  currentMarkerLocation: OutletContextProperty<MapPosition>;
  drawerInitialHeight: OutletContextProperty<number>;
  headerRef: OutletContextProperty<RefObject<HTMLDivElement>>;
  container: RefObject<HTMLDivElement>;
  showAllMarkers: () => void;
  toPosition: (position: MapPosition, zoom?: boolean) => void;
}

const MerchantsDrawerPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const headerRef = useRef<HTMLDivElement>(null);
  const merchants = useAppSelector((state) => state.merchantsTab.merchants);
  const listCloseEvent = {
    apply: () => {}
  };
  const currentPage = useAppSelector((state) => state.merchantsTab.currentPage);
  const isLastPage = useAppSelector((state) => state.merchantsTab.isLastPage);
  const currentGeolocation = useAppSelector((state) => state.geolocation.current);
  const context = useOutletContext<OutletContextProps>();
  const subdomain = getSubDomain();

  useEffect(() => {
    if (!merchants.length) {
      fetchMerchants(currentPage);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isLoading) return;
    context.mapPoints.setValue(convertMerchantsToPoints(merchants));
  }, [merchants]);

  useEffect(() => {
    if (!isLoading) {
      setMerchantsGeolocation(merchants);
    }
  }, [currentGeolocation, isLoading]);

  useEffect(() => {
    if (context && headerRef) context.headerRef.setValue(headerRef);
  }, [context, headerRef]);

  const fetchMerchants = async (pageNumber: number) => {
    setIsLoading(true);

    const response = await dispatch(thunkUsersMerchants({ page: pageNumber }));
    const { content } = response.payload as Page<Merchant>;
    const { last } = response.payload as Page<Discount>;
    dispatch(setIsLastPage(last));
    if (pageNumber == 0) {
      dispatch(setMerchants(content));
    } else {
      dispatch(setMerchants([...merchants, ...content]));
    }
    setIsLoading(false);
  };

  function setMerchantsGeolocation(merchants: Merchant[]) {
    const content: Merchant[] = [];
    merchants.forEach((val) => content.push(Object.assign({}, val)));
    content.forEach(
      (merchant) => (merchant.distance = getMerchantDistance(merchant, currentGeolocation))
    );
    content.sort((a, b) => (a.distance ?? 0) - (b.distance ?? 0));
    dispatch(setMerchants(content));
  }

  function getMerchantDistance(merchant: Merchant, currentGeolocation: MapPosition | null) {
    if (currentGeolocation) {
      return getDistance(
        currentGeolocation.lat,
        currentGeolocation.lng,
        merchant.latitude,
        merchant.longitude
      );
    }
    return null;
  }

  const convertMerchantsToPoints = (merchants: Merchant[]): MapPoint[] =>
    merchants
      .filter((merchant) => merchant.latitude && merchant.longitude)
      .map((merchant) => ({
        id: merchant.id,
        name: merchant.name,
        position: { lat: merchant.latitude!, lng: merchant.longitude! },
        onClick: () => {
          navigateToMerchantView(merchant);
        }
      }));

  const navigationClickHandler = (merchant: Merchant) => {
    listCloseEvent.apply();
    context.currentMarkerLocation.setValue({
      lat: merchant.latitude,
      lng: merchant.longitude
    });
  };

  const navigateToMerchantView = (merchant: Merchant) => {
    navigationClickHandler(merchant);
    navigate(MERCHANTS_ROUTES.MERCHANT.replace(':id', merchant.id.toString()));
    dispatch(setActiveMerchant(merchant));
  };

  const loadPage = (pageNumber: number) => {
    dispatch(setCurrentPage(pageNumber));
    fetchMerchants(pageNumber);
  };

  return (
    <>
      <BottomDrawer
        header={
          <Typography
            ref={headerRef}
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
              userSelect: 'none',
              p: 2,
              pt: 0,
              width: 'fit-content',
              align: 'left',
              cursor: 'pointer'
            }}
            onClick={() => context.showAllMarkers()}
          >
            {!subdomain ? 'Your stores' : 'Stores'}
          </Typography>
        }
        contentMinHeight={90}
        maxHeight={(context.container.current ? context.container.current.clientHeight : 0) * 0.9}
        closeEvent={listCloseEvent}
        onSetInitialHeight={context.drawerInitialHeight.setValue}
      >
        <IF condition={isLoading && !merchants.length}>
          <MerchantList
            merchants={[null]}
            onMerchantClick={navigateToMerchantView}
            onMerchantLocationClick={navigationClickHandler}
          />
        </IF>
        <IF condition={merchants.length > 0}>
          <InfiniteScroll
            elementsLength={merchants.length}
            isLoading={isLoading}
            isLastPage={isLastPage}
            pageNumber={currentPage}
            bgcolor='white'
            loadPage={loadPage}
            pageLoader={
              <MerchantList
                merchants={[null]}
                onMerchantClick={navigateToMerchantView}
                onMerchantLocationClick={navigationClickHandler}
              />
            }
          >
            <MerchantList
              merchants={merchants}
              onMerchantClick={navigateToMerchantView}
              onMerchantLocationClick={navigationClickHandler}
            />
          </InfiniteScroll>
        </IF>
        <IF condition={!isLoading && merchants.length == 0}>
          <Box
            bgcolor={'#F2F2F2'}
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='96px'
          >
            <Typography color={'text.secondary'}>No stores yet</Typography>
          </Box>
        </IF>
      </BottomDrawer>
    </>
  );
};

export default MerchantsDrawerPage;

import React, { FC } from 'react';

//components
import { List } from '@mui/material';
import OrdersListItem from 'src/components/Orders/OrdersListItem';

//types
import { Order } from 'src/shared/interfaces/order.interface';

interface Props {
  orders: Order[];
  onClick(order: Order): void;
}

const OrdersList: FC<Props> = ({ orders, onClick }) => {
  return (
    <List sx={{ width: '100%' }} disablePadding>
      {orders.map((order) => (
        <OrdersListItem key={order.id} order={order} onClick={() => onClick(order)} />
      ))}
    </List>
  );
};

export default OrdersList;

import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import './styles.css';
import DropdownMenu from 'src/components/DropdownMenu/DropdownMenu';
import { useDeviceSelectors } from 'react-device-detect';
import { Html5Qrcode } from 'html5-qrcode';

interface Props {
  onResult: (result: string) => void;
}

const BarcodeScanner: React.FC<Props> = ({ onResult }) => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [result, setResult] = useState('');
  const [html5QrCode, setHtml5QrCode] = useState<Html5Qrcode | null>(null);
  const [deviceSelectors] = useDeviceSelectors(window.navigator.userAgent);
  const [permission, setPermission] = useState<boolean>(true);
  const [showSelect, setShowSelect] = useState<boolean>(true);

  useEffect(() => {
    const currentHtml5QrCode = new Html5Qrcode('reader');
    setHtml5QrCode(currentHtml5QrCode);
    getDevices().then();
    startScanner(currentHtml5QrCode);
  }, []);

  useEffect(() => {
    return () => {
      stopScanner(html5QrCode);
    };
  }, [html5QrCode]);

  useEffect(() => {
    setShowSelect(!(permission && devices.length > 0));
  }, [permission, devices]);

  useEffect(() => {
    if (selectedDeviceId != null) {
      selectDevice(selectedDeviceId);
    }
  }, [selectedDeviceId]);

  const getDevices = async () => {
    try {
      if (deviceSelectors.isIOS) {
        await navigator.mediaDevices.getUserMedia({ video: true });
      }
      const availableDevices = await navigator.mediaDevices.enumerateDevices();
      const availableVideoDevices = availableDevices.filter(
        (device) => device.kind === 'videoinput'
      );
      if (availableVideoDevices.length === 0) {
      } else {
        setDevices(availableVideoDevices);
      }
    } catch (exception) {
      console.log('Failed to find cameras.');
    }
  };

  const startScanner = (currentHtml5QrCode: Html5Qrcode | null, deviceId: string | null = null) => {
    const config = {
      fps: 10,
      qrbox: { width: 300, height: 200 },
      experimentalFeatures: {
        useBarCodeDetectorIfSupported: true
      }
    };
    if (!currentHtml5QrCode) {
      currentHtml5QrCode = new Html5Qrcode('reader');
      setHtml5QrCode(currentHtml5QrCode);
    }
    currentHtml5QrCode
      .start(
        deviceId ?? { facingMode: 'environment' },
        config,
        (decodedText) => {
          stopScanner(currentHtml5QrCode);
          setResult(decodedText);
          onResult(decodedText);
        },
        () => {}
      )
      .catch((error) => {
        setPermission(error.includes('Permission denied'));
        console.error(error);
      });
  };

  const selectDevice = (deviceId: string | null = null) => {
    if (!!html5QrCode) {
      if (html5QrCode.getState().valueOf() > 1) {
        html5QrCode.stop().then((ignore) => {
          startScanner(null, deviceId);
        });
      } else {
        startScanner(null, deviceId);
      }
    }
  };

  const stopScanner = (html5QrCode: Html5Qrcode | null): void => {
    if (!!html5QrCode && html5QrCode.getState().valueOf() > 1) {
      html5QrCode.stop().then(() => {
        html5QrCode.clear();
      });
    }
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <Box
        sx={{
          position: 'relative',
          bgcolor: 'black',
          width: '100%',
          height: '100%'
        }}
      >
        <div id='reader' style={{ position: 'relative', width: '100%', height: '100%' }} />
      </Box>
      <Typography sx={{ position: 'absolute', color: 'white', left: 1 }}>{result}</Typography>
      {showSelect && (
        <Box sx={{ position: 'absolute', right: 0 }}>
          <DropdownMenu<string>
            items={devices.map((device) => {
              return { value: device.deviceId, label: device.label };
            })}
            onSelect={(deviceId: string) => {
              setSelectedDeviceId(deviceId);
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default BarcodeScanner;

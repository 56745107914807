import React, { useState } from 'react';

//mui
import InstallMobileIcon from '@mui/icons-material/InstallMobile';

//components
import SideMenuItemsContainer from 'src/components/SideMenu/SideMenuItemsContainer';
import BottomDialog from 'src/components/BottomDialog/BottomDialog';

//types
import SideMenuItemsListItem from 'src/components/SideMenu/SideMenuItemsListItem';

interface Props {
  instruction: React.ReactNode;
}

const AppInstallInstructionButton: React.FC<Props> = ({ instruction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <>
      <SideMenuItemsContainer>
        <SideMenuItemsListItem
          icon={InstallMobileIcon}
          title='Install app'
          onClick={open}
          disableDivider
        />
      </SideMenuItemsContainer>
      <BottomDialog isOpen={isOpen} onClose={close} title={'Install Guardoe'}>
        {instruction}
      </BottomDialog>
    </>
  );
};

export default AppInstallInstructionButton;

import { AppBar, Stack, Typography } from '@mui/material';
import React from 'react';
import CheckoutComponent from 'src/components/Cart/CheckoutComponent';

function CheckoutPage() {
  return (
    <Stack
      width='100%'
      height='100%'
      justifyContent='space-between'
      sx={{
        bgcolor: (theme) => theme.palette.light.main
      }}
    >
      <AppBar position='static' sx={{ height: 'fit-content', zIndex: 1000 }}>
        <Typography variant='h4' color='white' my={1}>
          Checkout
        </Typography>
      </AppBar>
      <Stack width='100%' height='100%' overflow='auto'>
        <CheckoutComponent />
      </Stack>
    </Stack>
  );
}

export default CheckoutPage;

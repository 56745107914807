import React from 'react';
import { useSearchParams } from 'react-router-dom';
import IF from 'src/components/IF/IF';
import { AuthLayout } from 'src/Layouts/AuthLayout/authLayout';
import { Success } from 'src/pages/Login/steps/success';
import { EmailStep } from 'src/pages/Login/steps/emailStep';

export enum LoginSteps {
  LOGIN = 'LOGIN',
  SUCCESS = 'SUCCESS'
}

const LoginPage: React.FC = () => {
  const [search] = useSearchParams();
  const token = search.get('token');
  const step = token ? LoginSteps.SUCCESS : LoginSteps.LOGIN;

  return (
    <AuthLayout>
      <IF condition={step === LoginSteps.SUCCESS}>
        <Success token={token} />
      </IF>
      <IF condition={step === LoginSteps.LOGIN}>
        <EmailStep />
      </IF>
    </AuthLayout>
  );
};
export default LoginPage;

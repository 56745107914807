import React from 'react';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function BackButton(props: ButtonProps) {
  return (
    <Button
      variant='outlined'
      color={'dark'}
      startIcon={<ArrowBackIosNewIcon sx={{ color: (theme) => theme.palette.dark.main }} />}
      {...props}
      sx={{
        ...props.sx,
        width: 36,
        height: 36,
        borderRadius: '8px',
        minWidth: 0,
        '& .MuiButton-startIcon': { margin: 0 }
      }}
    />
  );
}

BackButton.muiName = 'Button';

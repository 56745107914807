import React, { FC } from 'react';

//mui
import { Button, ButtonGroup, InputAdornment, Stack } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

//components
import { NumberFormatValues } from 'react-number-format';
import QuantityTextField from 'src/components/QuantityInput/QuantityTextField';

//types
import { Item } from 'src/shared/interfaces/items.interface';

//utils
import { isPricePerUnit } from 'src/utils/scripts/cart';

const MAX_QUANTITY = 100;
export const MIN_QUANTITY = 1;
const MIN_QUANTITY_PER_UNIT = 0.001;

interface Props {
  quantity: number;
  item: Item;
  setQuantity(value: number): void;
  isLoading?: boolean;
}

const QuantityInput: FC<Props> = ({ quantity, item, setQuantity, isLoading }) => {
  const { priceType, unitName } = item;

  function incrementQuantity() {
    setValidQuantity(quantity + 1);
  }

  function decrementQuantity() {
    setValidQuantity(quantity - 1);
  }

  function setValidQuantity(value: number) {
    let validValue = Math.max(value, getMinQuantity());
    validValue = Math.min(validValue, MAX_QUANTITY);
    setQuantity(validValue);
  }

  function getMinQuantity(): number {
    return isPricePerUnit(priceType) ? MIN_QUANTITY_PER_UNIT : MIN_QUANTITY;
  }

  const handleStopPropagation = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
  };

  return (
    <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
      <ButtonGroup
        onClick={handleStopPropagation}
        onMouseDown={handleStopPropagation}
        onTouchStart={handleStopPropagation}
        variant='contained'
      >
        <Button
          color='light'
          sx={{ border: 'none !important' }}
          disabled={quantity == getMinQuantity() || isLoading}
          onClick={(event) => {
            event.stopPropagation();
            decrementQuantity();
          }}
        >
          <RemoveIcon fontSize='small' />
        </Button>

        <QuantityTextField
          value={quantity}
          onValueChange={(values: NumberFormatValues) => {
            setValidQuantity(values.floatValue ?? MIN_QUANTITY);
          }}
          decimalScale={isPricePerUnit(priceType) ? 3 : 0}
          InputProps={{
            endAdornment: isPricePerUnit(priceType) && !!unitName && (
              <InputAdornment position='end' sx={{ mb: 0.1 }}>
                {unitName}
              </InputAdornment>
            )
          }}
          onClick={handleStopPropagation}
          onMouseDown={handleStopPropagation}
          onTouchStart={handleStopPropagation}
        ></QuantityTextField>

        <Button
          color='light'
          sx={{ border: 'none' }}
          disabled={quantity == MAX_QUANTITY || isLoading}
          onClick={(event) => {
            event.stopPropagation();
            incrementQuantity();
          }}
        >
          <AddIcon fontSize='small' />
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default QuantityInput;

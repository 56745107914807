import React from 'react';

//components
import PrivacyPolicyComponent from 'src/components/PrivacyPolicyComponent/PrivacyPolicyComponent';
import TermsAndConditionsContent from 'src/pages/TermsAndConditions/TermsAndConditionsContent';

const TermsAndConditionsPage = () => {
  return (
    <PrivacyPolicyComponent header='Terms and Conditions'>
      <TermsAndConditionsContent />
    </PrivacyPolicyComponent>
  );
};

export default TermsAndConditionsPage;

import React, { FormEvent, useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  InputBase,
  Stack,
  SvgIcon,
  Toolbar,
  Typography
} from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { ReactComponent as NothingFoundIcon } from 'src/images/nothing_found_icon.svg';
import { ReactComponent as SearchIcon } from 'src/images/search_icon.svg';
import ClearIcon from '@mui/icons-material/Clear';
import { useAppDispatch } from 'src/hooks/hooks';
import { Page } from 'src/shared/interfaces/page.interface';
import { thunkSearchAutocomplete, thunkSearches, thunkSearchSave } from 'src/store/thunks/search';
import { AutocompleteInterface } from 'src/store/interfaces/searches/autocomplete.interface';
import Autocomplete from 'src/components/SearchBar/Autocomplete/Autocomplete';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  ISearchResultTypePath,
  SearchInterface,
  SearchResultType
} from 'src/store/interfaces/searches/search.interface';
import RecentSearches from './RecentSearches/RecentSearches';
import IF from 'src/components/IF';
import BarcodeScanner from 'src/components/SearchBar/BarcodeScanner/BarcodeScanner';
import { thunkItemsByProductCode } from 'src/store/thunks';
import { Item } from 'src/shared/interfaces/items.interface';
import ItemSearchResult from 'src/components/SearchBar/ItemSearchResutl/ItemSearchResult';
import { RequestStatuses } from 'src/utils/enums';
import { getAccessToken } from 'src/utils/scripts';
import { setActiveItem } from 'src/store/slices/itemsTabSlice';

const PRODUCT_CODE_SEARCH_PARAMETER = 'productCode';

interface Props {
  isOpenByDefault?: boolean;
  onClose?(): void;
}

const SearchBar: React.FC<Props> = ({ isOpenByDefault = false, onClose = () => {} }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const productCode = searchParams.get(PRODUCT_CODE_SEARCH_PARAMETER);
  const navigator = useNavigate();
  const [inputValue, setInputValue] = useState('');
  const [autocomplete, setAutocomplete] = useState<AutocompleteInterface[]>([]);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();
  const [isSearchOpen, setIsSearchOpen] = useState(isOpenByDefault);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRecentSearchesLoading, setIsRecentSearchesLoading] = useState(false);
  const [isReloadSearches, setIsReloadSearches] = useState(true);
  const [isNothingFound, setIsNothingFound] = useState(false);
  const [isBarcodeScannerOpen, setIsBarcodeScannerOpen] = useState(false);
  const [isItemSearchResultOpen, setIsItemSearchResultOpen] = useState(false);
  const [recentSearches, setRecentSearches] = useState<SearchInterface[]>([]);
  const [barcodeScannerResult, setBarcodeScannerResult] = useState<string | null>(null);
  const [inProgress, setInProgress] = useState(false);
  const isOpenFirst = useRef(false);
  const currentValue = useRef('');
  const accessToken = getAccessToken();

  useEffect(() => {
    if (isSearchOpen && isReloadSearches && accessToken) {
      fetchSearches();
      setIsReloadSearches(false);
    }
  }, [isSearchOpen, isReloadSearches]);

  useEffect(() => {
    if (productCode != null) {
      setIsSearchOpen(true);
      showSearchResult(productCode);
    }
  }, [productCode]);

  const fetchSearches = async () => {
    setIsRecentSearchesLoading(true);
    const response = await dispatch(thunkSearches());
    const content = response.payload as SearchInterface[];
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      setRecentSearches(content);
    }
    setIsRecentSearchesLoading(false);
  };

  const inputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInProgress(true);
    setInputValue(event.target.value);
    currentValue.current = event.target.value;
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      fetchAutocomplete(event.target.value);
    }, 250);
    setTimer(newTimer);
  };

  const fetchAutocomplete = async (input: string) => {
    if (input != currentValue.current) return;
    const response = await dispatch(
      thunkSearchAutocomplete({ page: { size: 5 }, value: { search: input } })
    );
    if (input != currentValue.current) return;
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      const { content } = response.payload as Page<AutocompleteInterface>;
      setAutocomplete(content);
      if (isOpenFirst.current && content.length == 0) {
        isOpenFirst.current = false;
        openFirst(false, content);
      }
    }
    isOpenFirst.current = false;
    setInProgress(false);
  };

  const saveUserSearch = async (item: AutocompleteInterface) => {
    if (accessToken) {
      await dispatch(thunkSearchSave({ resultId: item.id, resultType: item.type }));
    }
  };

  const openFirst = (inProgress: boolean, autocomplete: AutocompleteInterface[]) => {
    if (inProgress) {
      isOpenFirst.current = true;
    } else {
      // @ts-ignore
      document.activeElement.blur();
      if (autocomplete.length) {
        openAutocomplete(autocomplete[0]);
      } else {
        setIsNothingFound(true);
      }
    }
  };

  const openAutocomplete = (item: AutocompleteInterface) => {
    setIsSearchOpen(false);
    setIsReloadSearches(true);
    setInputValue('');
    saveUserSearch(item);
    navigator(ISearchResultTypePath[item.type].replace(':id', item.id.toString()));
  };

  const openBarcodeScanner = () => {
    setIsBarcodeScannerOpen(true);
    setIsSearchOpen(true);
  };

  const closeBarcodeScanner = () => {
    setIsBarcodeScannerOpen(false);
    setIsItemSearchResultOpen(false);
    setSearchParams();
  };

  const onBarcodeScannerResult = (result: string) => {
    setSearchParams(`?${new URLSearchParams({ productCode: result })}`);
    showSearchResult(result);
  };

  function showSearchResult(result: string) {
    setBarcodeScannerResult(result);
    setIsItemSearchResultOpen(true);
    setIsBarcodeScannerOpen(false);
  }

  function openItem(item: Item) {
    dispatch(setActiveItem(item));
    const search = { id: item.id, value: item.name, type: SearchResultType.ITEM };
    openAutocomplete(search);
  }

  const searchItems = async (pageNumber: number) => {
    const response = await dispatch(
      thunkItemsByProductCode({
        page: { page: pageNumber },
        value: { productCode: barcodeScannerResult }
      })
    );
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      const payload = response.payload as Page<Item>;
      if (payload.totalElements < 2) setSearchParams();
      return payload;
    } else return null;
  };

  const close = () => {
    onClose();
    setInputValue('');
    setIsSearchOpen(false);
    closeBarcodeScanner();
  };

  return (
    <>
      <AppBar
        position='static'
        sx={{ height: isSearchOpen ? '100%' : 'fit-content', zIndex: 1000 }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', gap: 1, py: 0.9 }}>
          <Stack
            component='form'
            width='100%'
            height='fit-content'
            direction='row'
            sx={{
              p: 0.7,
              bgcolor: 'white',
              borderRadius: '8px',
              pr: isSearchOpen ? 0.2 : 1,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
            onSubmit={(event: FormEvent) => {
              event.preventDefault();
              openFirst(inProgress, autocomplete);
            }}
          >
            <IconButton
              type='submit'
              onClick={() => setIsBarcodeScannerOpen(false)}
              sx={{ color: 'text.disabled', flexShrink: 0 }}
              aria-label='menu'
            >
              <SvgIcon
                sx={{
                  fill: (theme) =>
                    isSearchOpen && !isBarcodeScannerOpen
                      ? theme.palette.primary.main
                      : theme.palette.lightGrey.main
                }}
                component={SearchIcon}
                inheritViewBox
              />
            </IconButton>
            <IconButton
              onClick={openBarcodeScanner}
              sx={{
                color: (theme) =>
                  isBarcodeScannerOpen ? theme.palette.primary.main : theme.palette.lightGrey.main
              }}
            >
              <QrCodeScannerIcon />
            </IconButton>
            <InputBase
              sx={{ flex: 1, pl: 0.7 }}
              value={inputValue}
              placeholder='Product, Location, Store Name'
              onChange={inputChanged}
              onClick={() => {
                setIsNothingFound(false);
                setIsSearchOpen(true);
                closeBarcodeScanner();
              }}
            />
            {isSearchOpen && inputValue.length && (
              <Button
                sx={{ color: 'text.disabled' }}
                onClick={() => {
                  setIsNothingFound(false);
                  setAutocomplete([]);
                  setInputValue('');
                  setInProgress(false);
                }}
              >
                <ClearIcon />
              </Button>
            )}
          </Stack>
          {isSearchOpen && (
            <Button
              variant='contained'
              disableElevation
              sx={{ minWidth: '44px', px: '8.5px', fontSize: '14px' }}
              onClick={close}
            >
              Close
            </Button>
          )}
        </Toolbar>
        <IF condition={isSearchOpen}>
          <Stack bgcolor='white' height='100%' sx={{ overflowY: 'auto' }}>
            {isBarcodeScannerOpen ? (
              <BarcodeScanner onResult={onBarcodeScannerResult} />
            ) : (
              <>
                {isNothingFound ? (
                  <Stack width='70%' alignItems='center' alignSelf='center' sx={{ py: 4 }}>
                    <Typography
                      color='text.primary'
                      variant='h4'
                      sx={{ fontWeight: 'bold', lineHeight: '28px', mb: 2.5 }}
                    >
                      There are no results for {'"'}
                      {inputValue}
                      {'"'}.
                    </Typography>
                    <Typography variant='body2'>
                      You may have entered an incorrect query. Check the spelling.
                    </Typography>
                    <Typography variant='body2' sx={{ mb: 6.5 }}>
                      Try to use only keywords.
                    </Typography>
                    <NothingFoundIcon />
                  </Stack>
                ) : (
                  <>
                    {isItemSearchResultOpen ? (
                      <ItemSearchResult search={searchItems} onItemClick={openItem} />
                    ) : (
                      <>
                        <IF condition={inProgress || autocomplete.length > 0}>
                          <Autocomplete
                            input={inputValue}
                            autocomplete={autocomplete}
                            onClick={openAutocomplete}
                            isLoading={inProgress}
                          />
                        </IF>
                        <Divider />
                        {!isRecentSearchesLoading ? (
                          <RecentSearches searches={recentSearches} onClick={openAutocomplete} />
                        ) : (
                          <RecentSearches searches={[null]} onClick={openAutocomplete} />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </IF>
      </AppBar>
    </>
  );
};
export default SearchBar;

import React, { useEffect, useState } from 'react';
import { Box, SvgIcon } from '@mui/material';
import { ReactComponent as PopoverArrow } from 'src/images/popover_arrow.svg';
import { PopoverPosition, Rect } from 'react-tiny-popover';
import 'src/components/CustomPovower/styles.css';
import {
  getLeftTransform,
  getTopTransform,
  isPositionHorizontal,
  isPositionVertical
} from 'src/components/CustomPovower/CustomPopover';

interface Props {
  children: JSX.Element;
  childRect: Rect;
  popoverRect: Rect;
  position?: PopoverPosition;
}

export const CustomArrowContainer: React.FC<Props> = ({
  children,
  childRect,
  popoverRect,
  position
}) => {
  const [left, setLeft] = useState<string | number>(0);
  const [top, setTop] = useState<string | number>(0);
  const [transform, setTransform] = useState('');
  const arrowHeight = 13;
  const arrowWidth = 34;
  const arrowFullWidth = 47;

  useEffect(() => {
    setLeft(getLeft());
  }, [position, childRect, popoverRect]);

  useEffect(() => {
    setTop(getTop());
  }, [position, childRect, popoverRect]);

  useEffect(() => {
    setTransform(getTransform());
  }, [position]);

  const isLoaded = (): boolean => {
    return !(
      childRect.top == 0 &&
      childRect.left == 0 &&
      childRect.right == 0 &&
      childRect.bottom == 0 &&
      childRect.width == 0 &&
      childRect.height == 0 &&
      popoverRect.top == 0 &&
      popoverRect.left == 0 &&
      popoverRect.right == 0 &&
      popoverRect.bottom == 0 &&
      popoverRect.width == 0 &&
      popoverRect.height == 0
    );
  };

  const getLeft = (): number | string => {
    if (isPositionVertical(position)) {
      const leftTransform = getLeftTransform(position, childRect, popoverRect);
      return Math.max(
        Math.min(
          childRect.left -
            popoverRect.left -
            leftTransform +
            Math.ceil((childRect.width - arrowFullWidth) / 2),
          popoverRect.width - arrowHeight - arrowFullWidth
        ),
        arrowHeight
      );
    } else {
      return position == 'right' ? 0 : 'auto';
    }
  };

  const getTop = (): number | string => {
    if (isPositionHorizontal(position)) {
      const topTransform = getTopTransform(position, childRect, popoverRect);
      return Math.max(
        Math.min(
          childRect.top -
            popoverRect.top -
            topTransform +
            Math.ceil((childRect.height - arrowHeight) / 2),
          popoverRect.height - arrowWidth
        ),
        arrowWidth
      );
    } else {
      return position == 'bottom' ? 0 : 'auto';
    }
  };

  const getTransform = (): string => {
    switch (position) {
      case 'top':
        return 'rotate(180deg)';
      case 'bottom':
        return 'rotate(0deg)';
      case 'left':
        return 'rotate(90deg)  translate(0, -17px)';
      case 'right':
        return 'rotate(270deg) translate(0, -17px)';
      default:
        return 'rotate(0deg)';
    }
  };

  return (
    <Box>
      {isLoaded() && (
        <SvgIcon
          component={PopoverArrow}
          inheritViewBox
          sx={{
            color: 'white',
            position: 'absolute',
            left: left,
            right: position == 'left' ? 0 : 'auto',
            bottom: position == 'top' ? 0 : 'auto',
            top: top,
            width: `${arrowFullWidth}px`,
            height: `${arrowHeight}px`,
            transform: transform,
            filter: 'drop-shadow( 0px -5px 2.5px rgba(0, 0, 0, .08))'
          }}
        />
      )}
      <Box sx={{ p: arrowHeight + 'px' }}>
        <Box
          className='shadowed'
          sx={{ borderRadius: '8px' }}
          onClick={(element) => element.stopPropagation()}
          onMouseDown={(element) => element.stopPropagation()}
          onTouchStart={(element) => element.stopPropagation()}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';

// constants
import { MERCHANTS_PATH, USERS_MERCHANTS_PATH } from 'src/shared/consts/Api.consts';

// types
import { AxiosResponse } from 'axios';
import { RequestError } from 'src/utils/types';
import { Page } from 'src/shared/interfaces/page.interface';
import { PageRequest } from 'src/shared/interfaces/pageRequest.interface';
import { axiosInstance } from 'src/store/axiosConfig';
import { Merchant } from 'src/shared/interfaces/merchant.interface';
import { MerchantItem } from 'src/shared/interfaces/items.interface';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { Coupon } from 'src/shared/interfaces/coupon.interface';

export const thunkMerchant = createAsyncThunk<Merchant, { id: string }>(
  MERCHANTS_PATH.MERCHANT(),
  async ({ id }, thunkAPI) => {
    try {
      const response: AxiosResponse<Merchant> = await axiosInstance.get(
        MERCHANTS_PATH.MERCHANT(id)
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkMerchants = createAsyncThunk<Page<Merchant>, PageRequest>(
  MERCHANTS_PATH.MERCHANTS,
  async (data, thunkAPI) => {
    try {
      const response: AxiosResponse<Page<Merchant>> = await axiosInstance.get(
        MERCHANTS_PATH.MERCHANTS
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkMerchantsItems = createAsyncThunk<
  Page<MerchantItem>,
  { pagination?: PageRequest; merchantId: string }
>(MERCHANTS_PATH.MERCHANT_ITEMS(), async ({ pagination, merchantId }, thunkAPI) => {
  try {
    const config = {
      params: { ...pagination }
    };
    const response: AxiosResponse<Page<MerchantItem>> = await axiosInstance.get(
      MERCHANTS_PATH.MERCHANT_ITEMS(merchantId),
      config
    );
    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
  }
});

export const thunkMerchantsItemsPurchased = createAsyncThunk<
  Page<MerchantItem>,
  { pagination?: PageRequest; merchantId: string }
>(MERCHANTS_PATH.MERCHANT_ITEMS_PURCHASED(), async ({ pagination, merchantId }, thunkAPI) => {
  try {
    const config = {
      params: { ...pagination }
    };
    const response: AxiosResponse<Page<MerchantItem>> = await axiosInstance.get(
      MERCHANTS_PATH.MERCHANT_ITEMS_PURCHASED(merchantId),
      config
    );
    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
  }
});

export const thunkMerchantsDiscounts = createAsyncThunk<
  Page<Discount>,
  { pagination?: PageRequest; merchantId: string }
>(MERCHANTS_PATH.MERCHANT_DISCOUNTS(), async ({ pagination, merchantId }, thunkAPI) => {
  try {
    const config = {
      params: { ...pagination }
    };
    const response: AxiosResponse<Page<Discount>> = await axiosInstance.get(
      MERCHANTS_PATH.MERCHANT_DISCOUNTS(merchantId),
      config
    );

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
  }
});
export const thunkMerchantsCoupons = createAsyncThunk<
  Page<Coupon>,
  { pagination?: PageRequest; merchantId: string }
>(MERCHANTS_PATH.MERCHANT_COUPONS(), async ({ pagination, merchantId }, thunkAPI) => {
  try {
    const config = {
      params: { ...pagination }
    };
    const response: AxiosResponse<Page<Coupon>> = await axiosInstance.get(
      MERCHANTS_PATH.MERCHANT_COUPONS(merchantId),
      config
    );

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
  }
});

export const thunkAddMerchantByToken = createAsyncThunk<
  Merchant,
  {
    accessToken: string;
    merchantToken: string;
  }
>(USERS_MERCHANTS_PATH.USERS_MERCHANTS, async (data, thunkAPI) => {
  try {
    const config = {
      params: { token: data.merchantToken }
    };
    const response: AxiosResponse<Merchant> = await axiosInstance.post(
      `${USERS_MERCHANTS_PATH.USERS_MERCHANTS}`,
      null,
      config
    );

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    const error = e as RequestError;
    return thunkAPI.rejectWithValue({
      response: { data: { error: error.response.data.error }, status: error.response.status }
    });
  }
});

import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  createTheme,
  Paper,
  SvgIcon,
  ThemeProvider,
  Typography
} from '@mui/material';
import IF from 'src/components/IF/IF';
import { CancelOutlined } from '@mui/icons-material';
import { useAppDispatch } from 'src/hooks/hooks';
import { thunkVerifyByEmail } from 'src/store/thunks/auth';
import { RequestStatuses } from 'src/utils/enums';
import { useNavigate } from 'react-router-dom';
import { STORAGE } from 'src/shared/consts/Storage.consts';
import { MAIN } from 'src/shared/consts/Rout.consts';
import { PostVerificationTokenDto } from 'src/store/interfaces/auth';

interface Props {
  token: string | null;
}

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          border: '2px solid green',
          padding: '20px'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '80px',
          height: '80px',
          fill: 'green',
          stroke: 'white'
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          margin: '0 auto'
        }
      }
    }
  }
});

export const Success: React.FC<Props> = ({ token }) => {
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const [isReady, setReady] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (token) {
      Promise.all([dispatch(thunkVerifyByEmail({ token }))]).then(([result]) => {
        if (result.meta.requestStatus === RequestStatuses.fulfilled) {
          setStatus(RequestStatuses.fulfilled);
          const tokenDto = result.payload as PostVerificationTokenDto;
          localStorage.setItem(STORAGE.ACCESS_TOKEN, tokenDto.accessToken);
          navigator(MAIN, { replace: true });
        } else {
          setStatus(RequestStatuses.rejected);
        }
        setReady(true);
      });
    }
  }, [dispatch, navigator, token]);

  return (
    <ThemeProvider theme={theme}>
      <IF condition={!isReady}>
        <CircularProgress />
      </IF>
      <IF condition={isReady}>
        <IF condition={status === RequestStatuses.rejected}>
          <Paper variant='outlined' sx={{ borderColor: 'red !important' }}>
            <SvgIcon sx={{ fill: 'red !important' }} component={CancelOutlined} />
            <Typography color='red' component='p' fontSize='24px'>
              Your verification token expired
            </Typography>
          </Paper>
        </IF>
        {/*<IF condition={status === RequestStatuses.fulfilled}>*/}
        {/*  <Paper variant='outlined'>*/}
        {/*    <Typography*/}
        {/*      // id='modal-modal-title'*/}
        {/*      variant='h6'*/}
        {/*      component='h2'*/}
        {/*      sx={{ fontWeight: 'bold' }}*/}
        {/*    >*/}
        {/*      Email Verified*/}
        {/*    </Typography>*/}
        {/*    <Typography variant='body1' sx={{ mt: 2 }}>*/}
        {/*      You Signed Up for Digital Coupons*/}
        {/*    </Typography>*/}
        {/*  </Paper>*/}
        {/*</IF>*/}
      </IF>
    </ThemeProvider>
  );
};

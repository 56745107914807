import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

export interface NotificationType {
  color: string;
  icon: any;
}

const NOTIFICATION_TYPE = {
  SUCCESS: {
    color: 'success.main',
    icon: <CheckCircleIcon color='success' fontSize='large' />
  } as NotificationType,
  ERROR: {
    color: 'error.main',
    icon: <ErrorIcon color='error' fontSize='large' />
  } as NotificationType
};

export { NOTIFICATION_TYPE };

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  SvgIcon
} from '@mui/material';
import { InputHelperMessage } from 'src/shared/interfaces/inputHelperMessage.interface';
import { ReactComponent as Visibility } from 'src/images/visibility.svg';
import { ReactComponent as VisibilityOff } from 'src/images/visibility_off.svg';
import { handlePhoneChange } from 'src/components/ProfileEditInput/ProfileEditInput';

interface Props {
  type: string;
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  value?: string | number;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validate?: (value: string) => InputHelperMessage;
  customValidation?: boolean;
  readOnly?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

export interface ValueValidatable {
  validateValue(value: string): boolean;
}

export const CustomInput = forwardRef<ValueValidatable, Props>(
  (
    {
      type,
      value = '',
      name,
      label,
      placeholder,
      helperText,
      error,
      required,
      onChange,
      validate,
      customValidation = false,
      readOnly = false,
      color = 'primary'
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      validateValue(value: string) {
        if (validate) {
          const validationResult = validate(value);
          setMessage(validationResult);
          return !validationResult.error;
        } else return true;
      }
    }));

    const [message, setMessage] = useState<InputHelperMessage>({
      message: helperText,
      error: false
    });
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleValidation = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (validate) setMessage(validate(event.target.value));
    };

    const handleClickShowPassword = () => {
      const elements = document.getElementsByName(name);
      if (elements.length) elements[0].blur();
      setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const sx = {
      '& label': {
        fontSize: 18,
        top: -16,
        left: -12
      },
      '& legend': {
        width: '0'
      },
      fontSize: 16,
      marginTop: label ? 2.6 : 0
    };

    return (
      <FormControl sx={sx} variant='outlined'>
        {!!label && (
          <InputLabel
            htmlFor='outlined-adornment-password'
            error={customValidation ? error : message.error}
            shrink
          >
            {label}
          </InputLabel>
        )}
        <OutlinedInput
          type={showPassword ? 'text' : type}
          name={name}
          placeholder={placeholder}
          value={value}
          fullWidth
          sx={{ border: '0px' }}
          inputProps={{
            style: { padding: '18px 16px' },
            readOnly: readOnly,
            disabled: readOnly
          }}
          required={required}
          endAdornment={
            type == 'password' ? (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {showPassword ? (
                    <SvgIcon component={VisibilityOff} viewBox='-0.8 -0.8 20 20' />
                  ) : (
                    <SvgIcon component={Visibility} viewBox='-0.8 -0.8 20 20' />
                  )}
                </IconButton>
              </InputAdornment>
            ) : null
          }
          error={customValidation ? error : message.error}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (type == 'tel') {
              handlePhoneChange(event, value.toString(), onChange);
            } else {
              onChange(event);
            }
            handleValidation(event);
          }}
          color={color}
        />
        {(message.message || customValidation) && (
          <FormHelperText error={customValidation ? error : message.error}>
            {customValidation ? helperText : message.message}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

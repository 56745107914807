import React, { useEffect } from 'react';

// mui
import { Stack } from '@mui/material';

//components
import ProfileComponent from './ProfileComponent';
import LoginToContinue from 'src/components/LoginToContinue';

// utils
import { getAccessToken } from 'src/utils/scripts';

// redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { thunkUsersCurrent } from 'src/store/thunks';
import { setUser } from 'src/store/slices/userSlice';

//types
import { UserProfile } from 'src/store/interfaces/users/userProfile';

const UserPage = () => {
  const dispatch = useAppDispatch();
  const token = getAccessToken();
  const user = useAppSelector((state) => state.user.current);

  useEffect(() => {
    if (!user) {
      fetchUser().then();
    }
  }, []);

  const fetchUser = async () => {
    if (token) {
      const response = await dispatch(thunkUsersCurrent({ token: token! }));
      const content = response.payload as UserProfile;
      dispatch(setUser(content));
    }
  };

  return (
    <Stack
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        gap: 2,
        p: 2,
        overflowY: 'auto'
      }}
    >
      {!!token ? (
        <ProfileComponent user={user} />
      ) : (
        <LoginToContinue header={'You are not logged in'} />
      )}
    </Stack>
  );
};

export default UserPage;

import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Link, Stack, Typography } from '@mui/material';
import IF from 'src/components/IF/IF';
import { CustomInput } from 'src/components/CustomInput/customInput';
import { thunkLogin, thunkSendEmailVerification } from 'src/store/thunks/auth';
import { RequestStatuses } from 'src/utils/enums';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { STORAGE } from 'src/shared/consts/Storage.consts';
import { AUTH_ROUTES, MAIN } from 'src/shared/consts/Rout.consts';
import logo from 'src/images/logo.svg';
import { RequestError } from 'src/utils/types';
import Modal from 'src/components/Modal/Modal';
import { Link as ReactRouterLink } from 'react-router-dom';

interface SignInFormAttributes {
  email: string;
  password: string;
}

export const EmailStep: React.FC = () => {
  const [search] = useSearchParams();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.main);
  const navigator = useNavigate();
  const attributes: SignInFormAttributes = { email: '', password: '' };
  const [form, setForm] = useState(attributes);
  const handleChange = useCallback(({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setForm((state) => ({ ...state, [name]: value }));
  }, []);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [isVerifyEmailSend, setIsVerifyEmailSend] = useState(false);
  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const result = await dispatch(thunkLogin(form));
    if (result.meta.requestStatus === RequestStatuses.fulfilled) {
      localStorage.setItem(STORAGE.ACCESS_TOKEN, result.payload as string);
      navigator({ pathname: MAIN, search: search.toString() }, { replace: true });
    } else {
      const { response } = result.payload as RequestError;
      if (response.status == 403) {
        setIsVerifyEmail(true);
      }
    }
  };

  const resendVerificationLink = async () => {
    if (form.email) {
      const result = await dispatch(thunkSendEmailVerification(form));
      if (result.meta.requestStatus === RequestStatuses.fulfilled) {
        setIsVerifyEmailSend(true);
      }
    }
  };

  const handleClose = () => {
    setIsVerifyEmail(false);
    setIsVerifyEmailSend(false);
  };

  return (
    <Stack flex={1} justifyContent='space-between'>
      <Box>
        <Stack alignItems='center' gap='3vh' marginBottom='3vh'>
          <Box
            component='img'
            sx={{
              width: '100%',
              minHeight: '100px',
              height: '25vh',
              maxWidth: '237px',
              '@media (max-width: 420px) and (max-height: 790px)': {
                height: '17vh'
              }
            }}
            src={logo}
          />
          <Typography
            fontSize='clamp(16px, 8.5vh, 1.5rem)'
            variant={'h2'}
            sx={{ fontWeight: 'bold', lineHeight: '28px' }}
          >
            Log In
          </Typography>
        </Stack>
        <Stack component='form' gap={2} width='100%' onSubmit={handleFormSubmit}>
          <CustomInput
            type='email'
            name='email'
            label='Your email'
            placeholder='mail@example.com'
            value={form.email}
            required
            onChange={handleChange}
          />
          <CustomInput
            type='password'
            name='password'
            label='Password'
            value={form.password}
            required
            onChange={handleChange}
          />
          <Box display='flex' justifyContent='right' width='100%'>
            <Link component={ReactRouterLink} to={AUTH_ROUTES.FORGOT_PASSWORD}>
              {'Forgot password?'}
            </Link>
          </Box>
          <Button type='submit' variant='contained' sx={{ height: '48px' }}>
            <IF condition={loading}>
              <CircularProgress />
            </IF>
            <IF condition={!loading}>Log In</IF>
          </Button>
        </Stack>
        <Box display='flex' flexDirection='column' width='100%' marginTop='20px'>
          <Link
            onClick={() => navigator({ pathname: AUTH_ROUTES.REGISTER, search: search.toString() })}
          >
            {"Don't have an account? Sign up"}
          </Link>
        </Box>
      </Box>
      <Typography marginTop={0} align='center' mx='auto'>
        By creating an account or signing, you agree to our{' '}
        <Link component={ReactRouterLink} to={AUTH_ROUTES.TERMS_AND_CONDITIONS}>
          Terms & Conditions
        </Link>{' '}
        and{' '}
        <Link component={ReactRouterLink} to={AUTH_ROUTES.PRIVACY_POLICY}>
          Privacy Policy
        </Link>
      </Typography>
      <Modal isOpen={isVerifyEmail} onClose={handleClose}>
        <>
          {isVerifyEmailSend ? (
            <>
              <Typography variant='h6' component='h2' sx={{ fontWeight: 'bold' }}>
                Please verify your email
              </Typography>
              <Typography variant='body1' sx={{ mt: 2 }}>
                To continue, please verify your identity. To do this, please go to your email and
                complete the verification process.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant='h6' component='h2' sx={{ fontWeight: 'bold' }}>
                Your email is not verified
              </Typography>
              <Typography variant='body1' sx={{ mt: 2 }}>
                To login, please verify your identity. To do this, please go to your email and
                complete the verification process.
              </Typography>
              <Button
                variant='contained'
                sx={{ height: '48px', mt: 2 }}
                onClick={resendVerificationLink}
              >
                <IF condition={loading}>
                  <CircularProgress />
                </IF>
                <IF condition={!loading}>Resend verification link</IF>
              </Button>
            </>
          )}
        </>
      </Modal>
    </Stack>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestError } from 'src/utils/types';
import { SEARCHES_PATH } from 'src/shared/consts/Api.consts';
import { axiosInstance } from 'src/store/axiosConfig';
import { AutocompleteInterface } from 'src/store/interfaces/searches/autocomplete.interface';
import { PageRequest } from 'src/shared/interfaces/pageRequest.interface';
import { Page } from 'src/shared/interfaces/page.interface';
import { SearchCreateInterface } from 'src/store/interfaces/searches/searchCreate.interface';
import { SearchInterface } from 'src/store/interfaces/searches/search.interface';

export const thunkSearchSave = createAsyncThunk<SearchInterface, SearchCreateInterface>(
  SEARCHES_PATH.SEARCHES,
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post(SEARCHES_PATH.SEARCHES, data);

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      const error = e as RequestError;

      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const thunkSearches = createAsyncThunk<SearchInterface[]>(
  SEARCHES_PATH.SEARCHES,
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.get(SEARCHES_PATH.SEARCHES);

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      const error = e as RequestError;

      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const thunkSearchAutocomplete = createAsyncThunk<
  Page<AutocompleteInterface>,
  {
    page: PageRequest;
    value: { search: string };
  }
>(SEARCHES_PATH.AUTOCOMPLETE, async (data, thunkAPI) => {
  try {
    const config = {
      params: { ...data.page, ...data.value }
    };

    const response = await axiosInstance.get(SEARCHES_PATH.AUTOCOMPLETE, config);

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

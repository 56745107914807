import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// mui
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';

// components
import { MerchantItemsList } from 'src/components/Items';
import SearchBar from 'src/components/SearchBar/SearchBar';
import MerchantFilterSearchBar from 'src/components/MerchantFilterSearchBar/MerchantFilterSearchBar';
import InfiniteScroll from 'src/components/InfiniteScroll/InfiniteScroll';
import IF from 'src/components/IF';

// redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import {
  thunkItems,
  thunkItemsPurchased,
  thunkMerchant,
  thunkMerchantsItems,
  thunkMerchantsItemsPurchased
} from 'src/store/thunks';
import {
  setActiveItem,
  setActiveMerchant,
  setCurrentPage,
  setCurrentTab,
  setIsLastPage,
  setItems
} from 'src/store/slices/itemsTabSlice';

// constants
import { ITEMS_ROUTES } from 'src/shared/consts/Rout.consts';

// types
import { ItemTab } from 'src/shared/enums';
import { Page } from 'src/shared/interfaces/page.interface';
import { RequestStatuses } from 'src/utils/enums';

//scripts
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { getAccessToken } from 'src/utils/scripts';
import { Item } from 'src/shared/interfaces/items.interface';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

function ItemsPage() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const merchantId = searchParams.get('merchantId');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const currentPage = useAppSelector((state) => state.itemsTab.currentPage);
  const isLastPage = useAppSelector((state) => state.itemsTab.isLastPage);
  const currentMerchant = useAppSelector((state) => state.itemsTab.activeMerchant);
  const items = useAppSelector((state) => state.itemsTab.items);
  const subdomain = getSubDomain();
  const accessToken = getAccessToken();
  const currentTab = useAppSelector((state) => state.itemsTab.currentTab);

  const fetchItems = async (pageNumber: number, currentTab: ItemTab) => {
    setIsLoading(true);
    const response = await dispatch(
      currentTab == ItemTab.ALL
        ? thunkItems({ page: pageNumber, sort: 'item.name' })
        : thunkItemsPurchased({ page: pageNumber, sort: 'item.name' })
    );
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      setItemsResponse(response.payload as Page<Item>, pageNumber);
      setIsLoading(false);
    }
  };

  const fetchItemsByMerchantId = async (
    merchantId: number,
    pageNumber: number,
    currentTab: ItemTab
  ) => {
    setIsLoading(true);

    const thunkMI = thunkMerchantsItems({
      pagination: { page: pageNumber, sort: 'item.name' },
      merchantId: merchantId.toString()
    });

    const thunkMIP = thunkMerchantsItemsPurchased({
      pagination: { page: pageNumber, sort: 'item.name' },
      merchantId: merchantId.toString()
    });
    const response = await dispatch(currentTab == ItemTab.ALL ? thunkMI : thunkMIP);

    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      setItemsResponse(response.payload as Page<Item>, pageNumber);
      setIsLoading(false);
    }
  };

  const fetchMerchant = async (merchantId: string) => {
    const response = await dispatch(thunkMerchant({ id: merchantId }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      dispatch(setActiveMerchant(response.payload as Merchant));
    }
  };

  function setItemsResponse(payload: Page<Item>, pageNumber: number) {
    const { content } = payload;
    dispatch(setIsLastPage(payload.last));
    if (pageNumber == 0) {
      dispatch(setItems(content));
    } else {
      dispatch(setItems([...items, ...content]));
    }
  }

  const clickHandler = (item: Item) => {
    dispatch(setActiveItem(item));
    navigate(ITEMS_ROUTES.ITEM.replace(':id', item.id.toString()));
  };

  useEffect(() => {
    if (!merchantId && !currentMerchant && !items.length) {
      fetchItems(currentPage, currentTab);
    } else {
      if (merchantId != null && merchantId != currentMerchant?.id.toString()) {
        dispatch(setActiveMerchant(null));
        dispatch(setCurrentPage(0));
        dispatch(setItems([]));
        fetchMerchant(merchantId);
        fetchItemsByMerchantId(+merchantId, 0, currentTab);
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  const filterItemsByMerchant = (merchant: Merchant | null) => {
    dispatch(setActiveMerchant(merchant));
    if (merchant?.id != currentMerchant?.id) {
      dispatch(setItems([]));
      dispatch(setCurrentPage(0));
      if (merchant?.id) {
        setSearchParams(`?${new URLSearchParams({ merchantId: merchant.id.toString() })}`);
        fetchItemsByMerchantId(merchant.id, 0, currentTab);
      } else {
        setSearchParams();
        fetchItems(0, currentTab);
      }
    }
  };

  const loadPage = (pageNumber: number) => {
    dispatch(setCurrentPage(pageNumber));
    if (currentMerchant) {
      fetchItemsByMerchantId(currentMerchant.id, pageNumber, currentTab);
    } else {
      fetchItems(pageNumber, currentTab);
    }
  };

  function getNotFoundMessage(): string {
    if (currentTab == ItemTab.ALL) {
      return 'There are no products';
    } else {
      const text = !!currentMerchant ? 'from ' + currentMerchant.name : '';
      return `There are no products you purchased ${text}`;
    }
  }

  function handleTabChange(value: ItemTab) {
    dispatch(setItems([]));
    dispatch(setCurrentPage(0));
    dispatch(setCurrentTab(value));
    if (currentMerchant) {
      fetchItemsByMerchantId(currentMerchant.id, 0, value);
    } else {
      fetchItems(0, value);
    }
  }

  return (
    <Stack height='100%'>
      {isSearchOpen ? (
        <SearchBar onClose={() => setIsSearchOpen(false)} isOpenByDefault />
      ) : (
        <MerchantFilterSearchBar
          header='Products'
          currentMerchant={currentMerchant}
          onMerchantSelect={filterItemsByMerchant}
          onSearchSelected={() => setIsSearchOpen(true)}
        />
      )}
      <Box boxShadow={3} zIndex={100}>
        <IF condition={!!subdomain && !!accessToken}>
          <Tabs
            value={currentTab}
            variant='fullWidth'
            onChange={(event, value: ItemTab) => {
              handleTabChange(value);
            }}
            centered
          >
            {Object.keys(ItemTab).map((tab) => (
              <Tab key={tab} value={tab} label={tab} />
            ))}
          </Tabs>
        </IF>
      </Box>
      <InfiniteScroll
        elementsLength={items.length}
        isLoading={isLoading}
        isLastPage={isLastPage}
        pageNumber={currentPage}
        overflow='auto'
        notFoundMessage={getNotFoundMessage()}
        loadPage={loadPage}
        pageLoader={
          <Box sx={{ position: 'relative', top: '-0.5rem' }}>
            <MerchantItemsList items={[null]} />
          </Box>
        }
      >
        <IF condition={currentTab == ItemTab.PURCHASED && !subdomain}>
          <Typography variant='h6' color='grey' sx={{ p: 1 }}>
            Products you purchased
          </Typography>
        </IF>
        <MerchantItemsList items={items} onItemClick={clickHandler} />
      </InfiniteScroll>
    </Stack>
  );
}

export default ItemsPage;

import { createTheme, responsiveFontSizes } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    dark: Palette['primary'];
    light: Palette['primary'];
    lightGrey: Palette['primary'];
    whiteButton: Palette['primary'];
  }

  interface PaletteOptions {
    dark?: PaletteOptions['primary'];
    light?: PaletteOptions['primary'];
    lightGrey?: PaletteOptions['primary'];
    whiteButton?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dark: true;
    light: true;
    lightGrey: true;
    whiteButton: true;
  }

  interface OutlinedInputPropsColorOverrides {
    dark: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    whiteButton: true;
  }
}

const materialConfig = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0A9E4D'
    },
    secondary: {
      main: '#007AFF'
    },
    success: {
      main: '#007AFF'
    },
    error: {
      main: '#D60936'
    },
    text: {
      primary: '#052B33'
    },
    dark: {
      main: '#052B33',
      light: '#153B43',
      dark: '#052020',
      contrastText: '#FFF'
    },
    light: {
      main: '#F2F2F2'
    },
    lightGrey: {
      main: '#8A8A8E'
    },
    whiteButton: {
      main: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: ['Golos Text', 'sans-serif'].join(','),
    fontSize: 12,
    body1: {
      fontSize: 14
    },
    h2: {
      fontSize: 48
    },
    h4: {
      fontSize: 28,
      lineHeight: 1.788,
      fontWeight: 'bold'
    },
    caption: {
      fontSize: 12
    },
    button: {
      fontSize: 16,
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: 10
  }
});
export default responsiveFontSizes(materialConfig);

import React from 'react';

import './content-style.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const htmlModule = require('raw-loader!../../resources/guardoe_EULA.html');

const TermsAndConditionsContent = () => {
  return <div id='terms_conditions' dangerouslySetInnerHTML={{ __html: htmlModule.default }} />;
};

export default TermsAndConditionsContent;

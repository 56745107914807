import { ITEMS_ROUTES, MERCHANTS_ROUTES } from 'src/shared/consts/Rout.consts';

export interface SearchInterface {
  id: number;
  resultType: SearchResultType;
  resultId: number;
  lastModified: Date;
  primaryValue: string;
  secondaryValue: string;
}

export enum SearchResultType {
  MERCHANT = 'MERCHANT',
  LOCATION = 'LOCATION',
  ITEM = 'ITEM'
}

export const ISearchResultTypePath: { [key in SearchResultType]: string } = {
  [SearchResultType.MERCHANT]: MERCHANTS_ROUTES.MERCHANT,
  [SearchResultType.LOCATION]: MERCHANTS_ROUTES.MERCHANT,
  [SearchResultType.ITEM]: ITEMS_ROUTES.ITEM
};

import React from 'react';

import './content-style.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const htmlModule = require('raw-loader!src/resources/guardoe_privacy_policy.html');

const PrivacyPolicyContent = () => {
  return <div id='privacy_policy' dangerouslySetInnerHTML={{ __html: htmlModule.default }} />;
};

export default PrivacyPolicyContent;

import React from 'react';
import { Box, Typography } from '@mui/material';
import 'src/components/Notifications/notification.css';
import { NOTIFICATION_TYPE, NotificationType } from 'src/shared/consts/NotificationType.consts';

interface Props {
  title: string;
  message?: string;
  type?: NotificationType;
}

export const NotificationContainer: React.FC<Props> = ({
  title,
  message,
  type = NOTIFICATION_TYPE.SUCCESS
}) => {
  return (
    <Box className='notification'>
      <Box display='flex' flexDirection='column' textAlign='left'>
        <Typography variant='h6' color={type.color} sx={{ fontWeight: 400 }}>
          {title}
        </Typography>
        <Typography variant='body2'>{message}</Typography>
      </Box>
      {type.icon}
    </Box>
  );
};

import React from 'react';

interface TabContentProps<T = string | number> {
  children?: React.ReactNode;
  index: T;
  value: T;
  className?: string;
}

function TabContent<T>(props: TabContentProps<T>) {
  const { children, value, index, className, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default TabContent;

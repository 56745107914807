import axios, { InternalAxiosRequestConfig } from 'axios';
import { getAccessToken, removeAccessToken } from 'src/utils/scripts';
import { AUTH_ROUTES, NOT_FOUND } from 'src/shared/consts/Rout.consts';
import {
  API_V1,
  AUTH_PATH,
  E_STORES_PATH,
  USERS_MERCHANTS_PATH,
  USERS_PATH
} from 'src/shared/consts/Api.consts';
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { API_URL } from 'src/config';

export const axiosInstance = axios.create();
const notEStorePaths = [
  ...Object.values(AUTH_PATH),
  ...Object.values(USERS_PATH),
  ...Object.values(E_STORES_PATH)
];

axiosInstance.defaults.baseURL = API_URL;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.config.url != USERS_MERCHANTS_PATH.USERS_MERCHANTS) {
      removeAccessToken();
      if (getSubDomain()) {
        if (window.location.pathname != AUTH_ROUTES.LOGIN && window.location.pathname != NOT_FOUND)
          window.location.replace(NOT_FOUND);
      } else {
        if (window.location.pathname != AUTH_ROUTES.LOGIN) window.location.reload();
      }
    }
    if (error.response.status === 404) {
      if (window.location.pathname != NOT_FOUND) window.location.replace(NOT_FOUND);
    }
    throw error;
  }
);

axiosInstance.interceptors.request.use(
  (request) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }

    const eStoreSubdomain = getSubDomain();
    if (eStoreSubdomain) {
      request.headers['X-SUBDOMAIN'] = eStoreSubdomain;
    }

    if (eStoreSubdomain && isEStore(request) && !accessToken) {
      request.url = request.url?.replace(API_V1, API_V1 + '/e-stores');
    }

    return request;
  },
  (error) => error
);

function isEStore(request: InternalAxiosRequestConfig): boolean {
  return notEStorePaths.indexOf(request.url ?? '') === -1;
}

import React from 'react';
import { Box, List, Skeleton } from '@mui/material';
import { AutocompleteInterface } from 'src/store/interfaces/searches/autocomplete.interface';
import AutocompleteListItem from 'src/components/SearchBar/Autocomplete/AutocompleteListItem';

interface Props {
  input: string;
  autocomplete: AutocompleteInterface[];
  isLoading: boolean;
  onClick(item: AutocompleteInterface): void;
}

const Autocomplete: React.FC<Props> = ({ input, autocomplete, isLoading, onClick }) => {
  function filterAutocompleteByInput(): AutocompleteInterface[] {
    return autocomplete.filter(
      (item) => item.value.toLowerCase().indexOf(input.toLowerCase()) != -1
    );
  }

  return (
    <Box sx={{ py: 1.5 }}>
      {input.trim() != '' && filterAutocompleteByInput().length > 0 && (
        <List disablePadding sx={{ py: 0 }}>
          {filterAutocompleteByInput().map((item, index) => (
            <AutocompleteListItem
              key={index}
              input={input}
              autocomplete={item}
              onClick={() => {
                onClick(item);
              }}
            />
          ))}
        </List>
      )}
      {isLoading && (
        <Box px={1.5}>
          <Skeleton variant='text'></Skeleton>
        </Box>
      )}
    </Box>
  );
};

export default Autocomplete;

import React from 'react';
import { AppBar, Box, Divider, Toolbar } from '@mui/material';

interface Props {
  toolbar: React.ReactNode;
  children?: React.ReactNode;
  toolBarGap?: number;
}

const CustomAppBar: React.FC<Props> = ({ toolbar, children, toolBarGap = 2 }) => {
  return (
    <AppBar
      position='static'
      className='shadowed'
      elevation={0}
      sx={{ bgcolor: (theme) => theme.palette.light.main, zIndex: 1000 }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          py: 0.4,
          gap: toolBarGap
        }}
      >
        {toolbar}
      </Toolbar>
      <Divider></Divider>
      <Box bgcolor='white' color='text.primary'>
        {children}
      </Box>
    </AppBar>
  );
};

export default CustomAppBar;

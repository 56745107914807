const API_V1 = 'api/v1';

const AUTH_PATH = {
  REGISTRATION: `${API_V1}/auth/registration`,
  REGISTRATION_BY_MERCHANT: `${API_V1}/auth/registration/by-merchant`,
  LOGIN: `${API_V1}/auth/login`,
  SEND_VERIFICATION: `${API_V1}/auth/send-verification`,
  RESEND_VERIFICATION: `${API_V1}/auth/resend-verification`,
  VERIFY_BY_EMAIL: `${API_V1}/auth/verify/by-email`,
  GET_REGISTRATION_INFO: `${API_V1}/auth/registration/info`,
  PASSWORD_RESET_EMAIL: `${API_V1}/auth/password-reset-email`,
  RESET_PASSWORD: `${API_V1}/auth/reset-password`
};

const USERS_PATH = {
  USERS: `${API_V1}/users`,
  USERS_CURRENT: `${API_V1}/users/current`,
  VALIDATE_PASSWORD: `${API_V1}/users/validate-password`,
  PASSWORD: `${API_V1}/users/password`
};

const USERS_MERCHANTS_PATH = {
  USERS_MERCHANTS: `${API_V1}/users/merchants`
};

const MERCHANTS_PATH = {
  MERCHANTS: `${API_V1}/merchants`,
  MERCHANT: (id = ':id') => `${API_V1}/merchants/${id}`,
  MERCHANT_ITEMS: (id = ':id') => `${API_V1}/merchants/${id}/items`,
  MERCHANT_ITEMS_PURCHASED: (id = ':id') => `${API_V1}/merchants/${id}/items/purchased`,
  MERCHANT_DISCOUNTS: (id = ':id') => `${API_V1}/merchants/${id}/discounts`,
  MERCHANT_COUPONS: (id = ':id') => `${API_V1}/merchants/${id}/coupon-programs`
};

const DISCOUNTS_PATH = {
  DISCOUNTS_SYNCHRONISE: `${API_V1}/discounts/synchronise`,
  DISCOUNTS: `${API_V1}/discounts`,
  DISCOUNTS_EVENTS: `${API_V1}/discounts/events`,
  DISCOUNT: (id = ':id') => `${API_V1}/discounts/${id}`,
  DISCOUNT_EVENTS: (id = ':id') => `${API_V1}/discounts/${id}/events`,
  DISCOUNT_ITEMS: (id = ':id') => `${API_V1}/discounts/${id}/items`,
  DISCOUNTS_BY_LOCATION: `${API_V1}/discounts/by-location`,
  DISCOUNTS_BY_ITEM_NAME: `${API_V1}/discounts/by-item-name`,
  DISCOUNTS_BY_MERCHANT_NAME: `${API_V1}/discounts/by-merchant-name`
};

const COUPONS_PATH = {
  COUPONS: `${API_V1}/coupon-programs`,
  COUPON: (id = ':id') => `${API_V1}/coupon-programs/${id}`
};

const ITEMS_PATH = {
  ITEMS: `${API_V1}/items`,
  ITEMS_PURCHASED: `${API_V1}/items/purchased`,
  ITEM: (id = ':id') => `${API_V1}/items/${id}`,
  ITEM_CUSTOM_NAME: (id = ':id') => `${API_V1}/items/${id}/custom-names`,
  ITEM_DISCOUNTS: (id = ':id') => `${API_V1}/items/${id}/discounts`
};

const OMNI_ITEMS_PATH = {
  ITEMS: (id = ':id') => `${API_V1}/omni-items/${id}/items`
};

const SEARCHES_PATH = {
  SEARCHES: `${API_V1}/searches`,
  AUTOCOMPLETE: `${API_V1}/searches/autocomplete`
};

const ORDER_PATH = {
  ORDERS: `${API_V1}/orders`,
  ORDERS_TAXES: `${API_V1}/orders/taxes`,
  ORDERS_DISCOUNT: `${API_V1}/orders/discounts`,
  ORDER: (id = ':id') => `${API_V1}/orders/${id}`,
  CANCEL_ORDER: (id = ':id') => `${API_V1}/orders/${id}/cancel`,
  ORDERS_EVENTS: `${API_V1}/orders/events`,
  ORDER_EVENTS: (id = ':id') => `${API_V1}/orders/${id}/events`
};

const E_STORES_PATH = {
  IS_ACTIVE: `${API_V1}/e-stores/is-active`
};

export {
  API_V1,
  AUTH_PATH,
  USERS_PATH,
  USERS_MERCHANTS_PATH,
  MERCHANTS_PATH,
  DISCOUNTS_PATH,
  COUPONS_PATH,
  ITEMS_PATH,
  OMNI_ITEMS_PATH,
  SEARCHES_PATH,
  ORDER_PATH,
  E_STORES_PATH
};

import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import InfiniteScroll from 'src/components/InfiniteScroll/InfiniteScroll';
import DiscountList from 'src/components/Discounts/discount/DiscountList';
import { Box } from '@mui/material';
import { setActiveDiscount } from 'src/store/slices/discountsTabSlice';
import { DISCOUNTS_ROUTES } from 'src/shared/consts/Rout.consts';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { useNavigate } from 'react-router-dom';

interface Props {
  isLoading: boolean;
  loadPage(pageNumber: number): void;
}

const Discounts: React.FC<Props> = ({ isLoading, loadPage }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const discounts = useAppSelector((state) => state.discountsTab.discounts);
  const currentPage = useAppSelector((state) => state.discountsTab.discountPage.currentPage);
  const isLastPage = useAppSelector((state) => state.discountsTab.discountPage.isLastPage);

  const clickHandler = (discount: Discount) => {
    dispatch(setActiveDiscount(discount));
    navigate(DISCOUNTS_ROUTES.DISCOUNT_STORES.replace(':id', discount.id.toString()));
  };

  return (
    <InfiniteScroll
      elementsLength={discounts.length}
      isLoading={isLoading}
      isLastPage={isLastPage}
      pageNumber={currentPage}
      loadPage={loadPage}
      pageLoader={
        <Box
          sx={{
            position: 'relative',
            top: '-0.5rem'
          }}
        >
          <DiscountList discounts={[null]} />
        </Box>
      }
    >
      <DiscountList
        discounts={discounts}
        onDiscountClick={clickHandler}
        showProductAmount={false}
        showMerchantName
      />
    </InfiniteScroll>
  );
};

export default Discounts;

import React, { ChangeEvent, useCallback, useState } from 'react';

//mui
import { Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

//components
import { CustomInput } from 'src/components/CustomInput/customInput';
import Modal from 'src/components/Modal/Modal';
import LoadingCircularProgress from 'src/components/LoadingCircularProgress';
import IF from 'src/components/IF';

//types
import { getItemName } from 'src/shared/enums';
import { RequestStatuses } from 'src/utils/enums';

//redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { thunkSaveItemCustomName } from 'src/store/thunks';
import { setActiveItem, setItems } from 'src/store/slices/itemsTabSlice';
import { setDiscountItems } from 'src/store/slices/discountItemsSlice';

//scripts
import { compare } from 'src/utils/scripts/common';
import { InputHelperMessage } from 'src/shared/interfaces/inputHelperMessage.interface';
import { getAccessToken } from 'src/utils/scripts';
import { Item, ItemInDiscount } from 'src/shared/interfaces/items.interface';

interface Props {
  item: Item | null;
}

const ItemName: React.FC<Props> = ({ item }) => {
  const dispatch = useAppDispatch();
  const [isNameEditOpen, setIsNameEditOpen] = useState(false);
  const [isNameEditInProgress, setIsNameEditInProgress] = useState(false);
  const [customName, setCustomName] = useState(getItemName(item));
  const items = useAppSelector((state) => state.itemsTab.items);
  const discountItems = useAppSelector((state) => state.discountItems.discountItems);
  const accessToken = getAccessToken();

  const saveItemCustomName = async (customName: string) => {
    setIsNameEditInProgress(true);
    const response = await dispatch(
      thunkSaveItemCustomName({ id: item!.id, customName: customName })
    );
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      updateItemCustomName(customName);
    }
    setIsNameEditInProgress(false);
    setIsNameEditOpen(false);
  };

  const setCustomNameCallback = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setCustomName(value);
    },
    []
  );

  function isChangesValid() {
    return customName != getItemName(item) && !validateCustomName(customName).error;
  }

  const validateCustomName = (value: string): InputHelperMessage => {
    let message = '';
    let error = false;
    if (1 > value.length || value.length > 50) {
      message = 'Must be between 1 and 50 characters long.';
      error = true;
    }
    return { message: message, error: error };
  };

  function updateItemCustomName(customName: string) {
    updateItemCustomNameForActiveItem(customName).then();
    updateItemCustomNameForItems(customName).then();
    updateItemCustomNameForItems(customName).then();
    updateItemCustomNameForDiscountItems(customName).then();
  }

  async function updateItemCustomNameForActiveItem(customName: string) {
    const updatedItem = Object.assign({}, item);
    updatedItem.customName = customName;
    dispatch(setActiveItem(updatedItem));
  }

  async function updateItemCustomNameForItems(customName: string) {
    const nweItems: Item[] = [];
    items.forEach((it) => nweItems.push(Object.assign({}, it)));
    nweItems.filter((it) => it.id === item?.id).forEach((it) => (it.customName = customName));
    nweItems.sort((item1, item2) => compare(getItemName(item1), getItemName(item2)));
    dispatch(setItems(nweItems));
  }

  async function updateItemCustomNameForDiscountItems(customName: string) {
    const nweDiscountItems: ItemInDiscount[] = [];
    discountItems.forEach((it) => nweDiscountItems.push(Object.assign({}, it)));
    nweDiscountItems
      .filter((it) => it.id === item?.id)
      .forEach((it) => (it.customName = customName));
    nweDiscountItems.sort((item1, item2) => compare(getItemName(item1), getItemName(item2)));
    dispatch(setDiscountItems(nweDiscountItems));
  }

  return (
    <>
      {item ? (
        <Stack
          className={'test'}
          sx={{
            maxWidth: '80%',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            pr: 2
          }}
        >
          <Typography
            variant='h4'
            color='text.primary'
            sx={{
              maxWidth: '80%',
              overflowWrap: 'break-word',
              pr: !accessToken ? 3 : 0,
              fontSize: Math.max(14, 22 - (item.name.length - 14))
            }}
          >
            {getItemName(item)}
          </Typography>
          <IF condition={!!accessToken}>
            <IconButton
              sx={{ color: 'text.disabled', alignSelf: 'start' }}
              onClick={() => {
                setCustomName(getItemName(item));
                setIsNameEditOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </IF>
        </Stack>
      ) : (
        <>
          <Skeleton width={'55%'} height={'2.5rem'} />
          <Box width='2rem' />
        </>
      )}
      <Modal
        isOpen={isNameEditOpen}
        onClose={() => {
          setIsNameEditOpen(false);
        }}
        sx={{ gap: 2 }}
      >
        <CustomInput
          type='text'
          name='password'
          color='secondary'
          value={customName}
          onChange={setCustomNameCallback}
          placeholder={getItemName(item)}
          validate={validateCustomName}
        />
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', gap: 1, px: '14.5px' }}>
          <Button
            variant='outlined'
            color='dark'
            sx={{ flex: 1 }}
            onClick={() => setIsNameEditOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='dark'
            sx={{ flex: 1 }}
            onClick={() => saveItemCustomName(customName)}
            disabled={!isChangesValid()}
          >
            <LoadingCircularProgress
              isLoading={isNameEditInProgress}
              color='white'
              sx={{ p: 0.5, boxSizing: 'border-box' }}
              fullElement
            >
              Save
            </LoadingCircularProgress>
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default ItemName;

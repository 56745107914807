import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'src/routers/PrivateRoute';
import {
  DiscountItemsPage,
  DiscountPage,
  DiscountsPage,
  DiscountStoresPage,
  EmailVerification,
  ForgotPasswordPage,
  ItemPage,
  ItemsPage,
  LoginPage,
  MainPage,
  MenuPage,
  MerchantDrawerPage,
  MerchantsDrawerPage,
  MerchantsPage,
  NotFoundPage,
  OnboardingPage,
  OrderPage,
  OrdersPage,
  PasswordResetCheckEmailPage,
  PasswordResetPage,
  PrivacyPolicy,
  RegisterPage,
  TermsAndConditionsPage,
  UserPage
} from 'src/pages';
import {
  AUTH_ROUTES,
  CART_ROUTES,
  DISCOUNTS_ROUTES,
  ITEMS_ROUTES,
  MAIN,
  MERCHANTS_ROUTES,
  ONBOARDING,
  ORDERS_ROUTES,
  USER_ROUTES
} from 'src/shared/consts/Rout.consts';
import React from 'react';
import CartPage from 'src/pages/Cart/CartPage';
import CheckoutPage from 'src/pages/Cart/CheckoutPage';

function AppRouters() {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path='*' element={<NotFoundPage />} />
        <Route path={MAIN} element={<MainPage />}>
          <Route path={ONBOARDING} element={<OnboardingPage />} />
          <Route path={MERCHANTS_ROUTES.MERCHANTS} element={<MerchantsPage />}>
            <Route path={MERCHANTS_ROUTES.MERCHANTS} element={<MerchantsDrawerPage />} />
            <Route path={MERCHANTS_ROUTES.MERCHANT} element={<MerchantDrawerPage />} />
          </Route>
          by MerchantDrawer
          <Route path={ITEMS_ROUTES.ITEMS} element={<ItemsPage />} />
          <Route path={ITEMS_ROUTES.ITEM} element={<ItemPage />} />
          <Route path={DISCOUNTS_ROUTES.DISCOUNTS} element={<DiscountsPage />} />
          <Route path={DISCOUNTS_ROUTES.DISCOUNT} element={<DiscountPage />} />
          <Route path={DISCOUNTS_ROUTES.DISCOUNT_ITEMS} element={<DiscountItemsPage />} />
          <Route path={DISCOUNTS_ROUTES.DISCOUNT_STORES} element={<DiscountStoresPage />} />
          <Route path={ORDERS_ROUTES.ORDERS} element={<OrdersPage />} />
          <Route path={ORDERS_ROUTES.ORDER} element={<OrderPage />} />
          <Route path={CART_ROUTES.CART} element={<CartPage />} />
          <Route path={CART_ROUTES.CHECKOUT} element={<CheckoutPage />} />
          <Route path={USER_ROUTES.PROFILE} element={<UserPage />} />
          <Route path={USER_ROUTES.MENU} element={<MenuPage />} />
        </Route>
      </Route>

      <Route path={AUTH_ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={AUTH_ROUTES.REGISTER} element={<RegisterPage />} />
      <Route path={AUTH_ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={AUTH_ROUTES.PASSWORD_RESET} element={<PasswordResetPage />} />
      <Route
        path={AUTH_ROUTES.FORGOT_PASSWORD_CHECK_EMAIL}
        element={<PasswordResetCheckEmailPage />}
      />
      <Route path={AUTH_ROUTES.EMAIL_VERIFICATION} element={<EmailVerification />} />
      <Route path={AUTH_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={AUTH_ROUTES.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
    </Routes>
  );
}

export default AppRouters;

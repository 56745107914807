import { createSlice } from '@reduxjs/toolkit';
import {
  thunkLogin,
  thunkPasswordResetEmail,
  thunkRegister,
  thunkRegisterByMerchant,
  thunkResendEmailVerification,
  thunkSendEmailVerification,
  thunkVerifyByEmail
} from 'src/store/thunks/auth';
import { v4 as uuidv4 } from 'uuid';
import { STORAGE } from 'src/shared/consts/Storage.consts';
import { RequestError } from 'src/utils/types';
import {
  thunkCancelOrder,
  thunkCreateOrder,
  thunkSaveItemCustomName,
  thunkUserPasswordUpdate,
  thunkUserUpdate
} from 'src/store/thunks';

export interface IAlert {
  uuid: string;
  text: string;
  type: 'error' | 'warning' | 'info' | 'success';
}

interface State {
  overlayLoading: boolean;
  loading: boolean;
  auth: boolean;
  user: null | { name: string };
  alerts: IAlert[];
}

const initialState: State = {
  overlayLoading: false,
  loading: false,
  auth: false,
  user: null,
  alerts: []
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    addAlert: (state, { payload }) => ({
      ...state,
      alerts: [payload, ...state.alerts]
    }),
    closeAlert: (state, action) => ({
      ...state,
      alerts: state.alerts.filter((alert) => alert.uuid !== action.payload)
    }),
    clearAlerts: (state) => ({ ...state, error: [] })
  },
  extraReducers: (builder) => {
    builder.addCase(thunkRegister.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(thunkRegister.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(thunkRegister.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
    builder.addCase(thunkRegisterByMerchant.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
    builder.addCase(thunkVerifyByEmail.pending, (state) => {
      state.overlayLoading = true;
    });
    builder.addCase(thunkVerifyByEmail.fulfilled, (state, { payload }) => {
      state.overlayLoading = false;
      state.auth = true;
      localStorage.setItem(STORAGE.ACCESS_TOKEN, payload.accessToken);
    });
    builder.addCase(thunkVerifyByEmail.rejected, (state) => {
      state.overlayLoading = false;
    });
    builder.addCase(thunkLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(thunkLogin.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(thunkLogin.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({
        uuid: uuidv4(),
        text: (payload as RequestError).response.data.error,
        type: 'error'
      });
    });
    builder.addCase(thunkResendEmailVerification.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
    builder.addCase(thunkSendEmailVerification.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
    builder.addCase(thunkUserUpdate.fulfilled, (state) => {
      state.loading = false;
      state.alerts.push({
        uuid: uuidv4(),
        text: 'You profile has been update successfully!',
        type: 'success'
      });
    });
    builder.addCase(thunkUserUpdate.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
    builder.addCase(thunkUserPasswordUpdate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({
        uuid: uuidv4(),
        text: 'You password has been update successfully!',
        type: 'success'
      });
    });
    builder.addCase(thunkUserPasswordUpdate.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
    builder.addCase(thunkPasswordResetEmail.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
    builder.addCase(thunkSaveItemCustomName.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({
        uuid: uuidv4(),
        text: 'Item name has been update successfully!',
        type: 'success'
      });
    });
    builder.addCase(thunkSaveItemCustomName.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
    builder.addCase(thunkCreateOrder.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({
        uuid: uuidv4(),
        text: 'The order has been create successfully!',
        type: 'success'
      });
    });
    builder.addCase(thunkCreateOrder.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
    builder.addCase(thunkCancelOrder.fulfilled, (state) => {
      state.loading = false;
      state.alerts.push({
        uuid: uuidv4(),
        text: 'The order has been canceled.',
        type: 'success'
      });
    });
    builder.addCase(thunkCancelOrder.rejected, (state, { payload }) => {
      state.loading = false;
      state.alerts.push({ uuid: uuidv4(), text: payload as string, type: 'error' });
    });
  }
});

export const { closeAlert, clearAlerts } = mainSlice.actions;

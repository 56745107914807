import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'src/store/axiosConfig';

// constants
import { COUPONS_PATH } from 'src/shared/consts/Api.consts';

// types
import { Page } from 'src/shared/interfaces/page.interface';
import { RequestError } from 'src/utils/types';
import { PageRequest } from 'src/shared/interfaces/pageRequest.interface';
import { Coupon } from 'src/shared/interfaces/coupon.interface';

export const thunkCoupons = createAsyncThunk<Page<Coupon>, PageRequest>(
  COUPONS_PATH.COUPONS,
  async (pagination, thunkAPI) => {
    try {
      const config = {
        params: { ...pagination }
      };

      const response = await axiosInstance.get(COUPONS_PATH.COUPONS, config);

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
    }
  }
);

export const thunkCoupon = createAsyncThunk<Coupon, { id: number }>(
  COUPONS_PATH.COUPON(),
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.get(COUPONS_PATH.COUPON(String(data.id)));

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
    }
  }
);

import { formatItemPrice } from './items';
import { ItemPriceType } from 'src/shared/enums';
import { QUANTITY_PER_UNIT } from 'src/shared/consts/Orders.consts';
import { Order, OrderItem } from 'src/shared/interfaces/order.interface';

export function getTotalFormatted(order: Order): string {
  const subtotal = getSubtotal(order);
  const discounts = getDiscounts(order);
  return formatItemPrice(subtotal + order.tax - discounts);
}

function getSubtotal(order: Order): number {
  return order.items.reduce((sum, cartItem) => sum + getItemTotalPrice(cartItem), 0);
}
export function getDiscounts(order: Order): number {
  return order.items.reduce((sum, cartItem) => sum + (cartItem.discount || 0), 0);
}

function getItemTotalPrice(item: OrderItem): number {
  const quantity =
    item.priceType == ItemPriceType.PER_UNIT ? item.quantity / QUANTITY_PER_UNIT : item.quantity;
  return quantity * item.price;
}

export function getSubtotalFormatted(order: Order): string {
  const subtotal = getSubtotal(order);
  return formatItemPrice(subtotal);
}

export function getItemTotalPriceFormatted(item: OrderItem): string {
  const quantity =
    item.priceType == ItemPriceType.PER_UNIT ? item.quantity / QUANTITY_PER_UNIT : item.quantity;
  return formatItemPrice(quantity * item.price);
}

import React from 'react';
import { NotificationContainer } from 'src/components/Notifications/NotificationContainer';
import { Notification } from 'src/shared/interfaces/notification.interface';
import { List, ListItem } from '@mui/material';

interface Props {
  notifications: Notification[];
}

export const NotificationList: React.FC<Props> = ({ notifications }) => {
  return (
    <List sx={{ width: '100%' }}>
      {notifications.map((notification, index) => (
        <ListItem key={index} disableGutters>
          <NotificationContainer
            title={notification.title}
            message={notification.message}
            type={notification.type}
          />
        </ListItem>
      ))}
    </List>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';

//mui
import { Button, Stack } from '@mui/material';

//components
import SideMenuItemsContainer from 'src/components/SideMenu/SideMenuItemsContainer';
import SideMenuHeader from 'src/components/SideMenu/SideMenuHeader';
import SideMenuItemsListItem from 'src/components/SideMenu/SideMenuItemsListItem';
import LogOutButton from 'src/components/LogOutButton/LogOutButton';
import AppInstallButton from 'src/components/AppInstallButton';

//icons
import { ReactComponent as ProfileIcon } from 'src/images/menu/profile_icon.svg';
import { ReactComponent as StoreIcon } from 'src/images/menu/store_icon.svg';
import { ReactComponent as DiscountIcon } from 'src/images/menu/discount_icon.svg';
import { ReactComponent as ProductIcon } from 'src/images/menu/product_icon.svg';
import { ReactComponent as ShieldIcon } from 'src/images/menu/shield_icon.svg';
import { ReactComponent as ClipboardIcon } from 'src/images/menu/clipboard_icon.svg';
import { ReactComponent as LogOutIcon } from 'src/images/menu/log_out_icon.svg';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

//utils
import { getAccessToken } from 'src/utils/scripts';

//constants
import {
  AUTH_ROUTES,
  DISCOUNTS_ROUTES,
  ITEMS_ROUTES,
  MERCHANTS_ROUTES,
  ORDERS_ROUTES,
  USER_ROUTES
} from 'src/shared/consts/Rout.consts';
import IF from 'src/components/IF';
import { getSubDomain } from 'src/utils/scripts/subdomain';

const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const token = getAccessToken();
  const subDomain = getSubDomain();
  const navigateTo = (path: string) => {
    navigate(path);
  };

  return (
    <Stack sx={{ width: '100%', height: '100%' }}>
      <SideMenuHeader />
      <Stack
        sx={{
          flex: 1,
          justifyContent: 'space-between',
          bgcolor: (theme) => theme.palette.light.main,
          p: 2,
          gap: 2
        }}
      >
        <Stack sx={{ flex: 1, gap: 1 }}>
          <SideMenuItemsContainer>
            <SideMenuItemsListItem
              icon={ProfileIcon}
              title='Profile'
              onClick={() => navigateTo(USER_ROUTES.PROFILE)}
            />
            <SideMenuItemsListItem
              icon={StoreIcon}
              title='Stores'
              onClick={() => navigateTo(MERCHANTS_ROUTES.MERCHANTS)}
            />
            <SideMenuItemsListItem
              icon={DiscountIcon}
              title='Discounts'
              onClick={() => navigateTo(DISCOUNTS_ROUTES.DISCOUNTS)}
            />
            <IF condition={!!subDomain}>
              <SideMenuItemsListItem
                icon={ReceiptLongIcon}
                title='Orders'
                onClick={() => navigateTo(ORDERS_ROUTES.ORDERS)}
              />
            </IF>
            <SideMenuItemsListItem
              icon={ProductIcon}
              title='Products'
              onClick={() => navigateTo(ITEMS_ROUTES.ITEMS)}
              disableDivider
            />
          </SideMenuItemsContainer>
          <SideMenuItemsContainer>
            <SideMenuItemsListItem
              icon={ShieldIcon}
              title='Privacy Policy'
              onClick={() => navigateTo(AUTH_ROUTES.PRIVACY_POLICY)}
            />
            <SideMenuItemsListItem
              icon={ClipboardIcon}
              title='Terms and Conditions'
              onClick={() => navigateTo(AUTH_ROUTES.TERMS_AND_CONDITIONS)}
              disableDivider
            />
          </SideMenuItemsContainer>
        </Stack>
        <AppInstallButton />
        {!token ? (
          <Button
            type='submit'
            variant='contained'
            sx={{ height: '48px' }}
            onClick={() => navigate(AUTH_ROUTES.LOGIN)}
            disableElevation
          >
            Log In
          </Button>
        ) : (
          <LogOutButton icon={LogOutIcon} color='error' />
        )}
      </Stack>
    </Stack>
  );
};

export default SideMenu;

import React, { FC } from 'react';

// mui
import {
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';

// components
import { LocationButton } from 'src/components/LocationButton';
import { ReactComponent as StoreIcon } from 'src/images/store_icon.svg';
import { formatDistance } from 'src/utils/scripts/merchants';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

interface Props {
  component?: React.ElementType;
  merchant: Merchant | null;
  onClick?: () => void | null;
  onSecondaryClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  divider?: boolean;
}

const MerchantListItem: FC<Props> = ({
  component = 'li',
  merchant,
  onClick = null,
  onSecondaryClick = () => {},
  divider = true
}) => {
  return (
    <Box key={merchant?.id} component={component}>
      <ListItem component='div' alignItems='flex-start' sx={{ p: 0 }}>
        <ListItemButton
          onClick={() => {
            if (onClick) onClick();
          }}
          sx={{
            p: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            px: 2,
            py: 1.8,
            gap: 1.25,
            pointerEvents: onClick ? 'auto' : 'none'
          }}
        >
          <ListItemAvatar sx={{ minWidth: 'fit-content', m: 0, alignSelf: 'start' }}>
            {merchant ? <StoreIcon /> : <Skeleton variant='rectangular' width={44} height={44} />}
          </ListItemAvatar>
          {merchant ? (
            <ListItemText
              sx={{
                flex: 1,
                mt: 0,
                mb: 0,
                alignSelf: 'start',
                userSelect: { xs: 'auto', md: 'none' },
                overflowWrap: 'break-word'
              }}
              primary={<Typography sx={{ fontWeight: 'bold' }}>{merchant.name}</Typography>}
              secondary={merchant.formattedAddress}
            />
          ) : (
            <Box sx={{ width: '80%', py: 0.35 }}>
              <Skeleton width='60%' />
              <Skeleton />
            </Box>
          )}
          <Tooltip title={formatDistance(merchant?.distance ?? null)} enterTouchDelay={0}>
            <Typography
              noWrap
              sx={{
                maxWidth: 64,
                flexShrink: 0,
                textAlign: 'end',
                userSelect: 'none'
              }}
              color={'secondary'}
            >
              {merchant ? formatDistance(merchant.distance) : ''}
            </Typography>
          </Tooltip>
          {merchant ? (
            <LocationButton
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                onSecondaryClick(event);
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              onTouchStart={(event) => {
                event.stopPropagation();
              }}
            />
          ) : (
            <Skeleton variant='rectangular' width={36} height={36} />
          )}
        </ListItemButton>
      </ListItem>
      {divider && <Divider variant='middle' />}
    </Box>
  );
};

export default MerchantListItem;

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Order } from 'src/shared/interfaces/order.interface';

interface ordersTabState {
  orders: Order[];
  currentPage: number;
  isLastPage: boolean;
  activeOrder: Order | undefined;
}

const initialState: ordersTabState = {
  orders: [],
  currentPage: 0,
  isLastPage: false,
  activeOrder: undefined
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialState,

  reducers: {
    setOrders(state, action: PayloadAction<ordersTabState['orders']>) {
      state.orders = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<ordersTabState['currentPage']>) {
      state.currentPage = action.payload;
    },
    setIsLastPage(state, action: PayloadAction<ordersTabState['isLastPage']>) {
      state.isLastPage = action.payload;
    },
    setActiveOrder(state, action: PayloadAction<ordersTabState['activeOrder']>) {
      state.activeOrder = action.payload;
    }
  }
});

export const { setOrders, setCurrentPage, setIsLastPage, setActiveOrder } = ordersSlice.actions;

export default ordersSlice.reducer;

import React, { FC } from 'react';

//mui
import { Box, Button, Divider, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

//components
import QuantityInput from 'src/components/QuantityInput';

//types
import { CartItem } from 'src/shared/interfaces/cartItem.interface';

//utils
import { formatItemPrice } from 'src/utils/scripts/items';
import IF from 'src/components/IF';
import styles from './checkout.module.css';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

interface Props {
  cartItem: CartItem;
  isLoading?: boolean;
  onClick(): void;
  setQuantity(value: number): void;
  onDelete(): void;
}

const handleStopPropagation = (e: React.MouseEvent | React.TouchEvent) => {
  e.stopPropagation();
};

const CartItemsListItem: FC<Props> = ({ cartItem, onClick, setQuantity, onDelete, isLoading }) => {
  return (
    <ListItem
      sx={{
        width: 'auto',
        height: 'fit-content',
        m: '8px 16px',
        p: 0,
        '.MuiListItemButton-root.Mui-focusVisible': {
          backgroundColor: 'rgba(0, 0, 0, 0)'
        }
      }}
      dense
    >
      <ListItemButton
        className='shadowed'
        dense
        sx={{ py: 1.5, bgcolor: 'white', gap: 3, borderRadius: '6px' }}
        onClick={onClick}
      >
        <Stack width='100%' flex={1} gap={2}>
          <Stack width='100%' gap={2} flexDirection='row' justifyContent='space-between'>
            <Box
              height={60}
              width={60}
              className={`productImg ${
                !cartItem.item.defaultImage?.url ? `productImgEmpty` : null
              }`}
            >
              {cartItem.item.defaultImage?.url ? (
                <img src={cartItem.item.defaultImage?.url} alt={cartItem.item.name} />
              ) : (
                <ImageNotSupportedOutlinedIcon
                  sx={{ fontSize: '50px !important' }}
                  className='productImgIcon'
                />
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%'
              }}
            >
              <Typography variant='h5' sx={{ flex: 1 }}>
                {cartItem.item.name}
              </Typography>
              <Button
                variant='outlined'
                color='dark'
                disabled={isLoading}
                className={styles.btn_delete}
                onClick={(event) => {
                  handleStopPropagation(event);
                  onDelete();
                }}
                onMouseDown={handleStopPropagation}
                onTouchStart={handleStopPropagation}
              >
                <DeleteOutlinedIcon fontSize='small' />
              </Button>
            </Box>
          </Stack>
          <Stack gap={2} flexDirection='row' justifyContent='space-between' alignItems='center'>
            <Box flex={1}>
              <QuantityInput
                isLoading={isLoading}
                quantity={cartItem.quantity}
                item={cartItem.item}
                setQuantity={setQuantity}
              />
            </Box>
            <Typography variant='h5'>{formatItemPrice(cartItem.item.price)}</Typography>
          </Stack>
          <IF condition={!!cartItem.item?.discountAmount}>
            <Divider />
            <Stack gap={2} flexDirection='row' justifyContent='space-between' alignItems='center'>
              <Typography color={'primary'} variant='body1'>
                Discount
              </Typography>
              <Typography color={'primary'} variant='body1' fontWeight={'700'}>
                -{formatItemPrice(cartItem.item!.discountAmount!)}
              </Typography>
            </Stack>
          </IF>
        </Stack>
      </ListItemButton>
    </ListItem>
  );
};

export default CartItemsListItem;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { AuthLayout } from 'src/Layouts/AuthLayout/authLayout';

//TODO implement verification email resend
export const EmailVerification: React.FC = () => {
  return (
    <AuthLayout>
      <Box marginBottom='40px'>
        <Typography variant='h5' color='primary'>
          Please verify your email address
        </Typography>
      </Box>
      <Typography variant='body1'>A verification link has been sent to your email</Typography>
    </AuthLayout>
  );
};

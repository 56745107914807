import React from 'react';
import { Box, Divider, List, Typography } from '@mui/material';
import { AutocompleteInterface } from 'src/store/interfaces/searches/autocomplete.interface';
import { SearchInterface } from 'src/store/interfaces/searches/search.interface';
import RecentSearchesListItem from 'src/components/SearchBar/RecentSearches/RecentSearchesListItem';
import IF from 'src/components/IF';

interface Props {
  searches: SearchInterface[] | null[];

  onClick(item: AutocompleteInterface): void;
}

const RecentSearches: React.FC<Props> = ({ searches, onClick }) => {
  return (
    <Box>
      <Typography align='justify' variant='body1' sx={{ fontWeight: 'bold', px: 2, py: 1.5 }}>
        Recent Searches
      </Typography>
      <List disablePadding sx={{ pb: 1.5 }}>
        {searches.map((search, index) =>
          search ? (
            <RecentSearchesListItem
              key={index}
              search={search}
              onClick={() => {
                onClick({
                  id: search.resultId,
                  type: search.resultType,
                  value: search.primaryValue
                });
              }}
            />
          ) : (
            <RecentSearchesListItem key={index} search={search} onClick={() => {}} />
          )
        )}
      </List>
      <IF condition={searches.length == 0}>
        <Typography variant='body2' sx={{ px: 2, pb: 4 }}>
          Nothing yet
        </Typography>
      </IF>
      <Divider />
    </Box>
  );
};

export default RecentSearches;

import { Box, CircularProgress, SxProps } from '@mui/material';
import React from 'react';
import 'src/components/LoadingCircularProgress/loadingSircularProgress.css';
import { Theme } from '@mui/material/styles';

interface Props {
  isLoading: boolean;
  fullElement?: boolean;
  children?: React.ReactNode;
  color?: string;
  sx?: SxProps<Theme>;
}

const LoadingCircularProgress: React.FC<Props> = ({
  isLoading,
  fullElement = true,
  children,
  color = 'primary',
  sx
}) => {
  return (
    <>
      {isLoading ? (
        <Box className={fullElement ? 'fullElement' : 'notFullElement'}>
          <CircularProgress sx={{ color: color, ...sx }} />
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingCircularProgress;

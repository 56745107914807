import { formatItemPrice } from './items';
import { CartItem } from 'src/shared/interfaces/cartItem.interface';
import { ItemPriceType } from 'src/shared/enums';

export function getCartSummery(cartItems: CartItem[]): string {
  const sum = cartItems.reduce((sum, cartItem) => sum + getItemTotalPrice(cartItem), 0);
  return formatItemPrice(sum) + ' + tax';
}

function getItemTotalPrice(cartItem: CartItem): number {
  return cartItem.quantity * cartItem.item.price;
}

export function isPricePerUnit(itemPriceType: ItemPriceType | null): boolean {
  return itemPriceType == ItemPriceType.PER_UNIT;
}

export function getCartItemsWithNewQuantity(
  cartItems: CartItem[],
  cartItem: CartItem,
  quantity: number
): CartItem[] {
  const index = cartItems.findIndex((it) => it.item.id == cartItem.item.id);
  if (index !== -1) {
    const currentCartItem = Object.assign({}, cartItems[index]);
    currentCartItem.quantity = quantity;
    return [...cartItems.slice(0, index), currentCartItem, ...cartItems.slice(index + 1)];
  }
  return cartItems;
}

import React, { useMemo, useState } from 'react';
import { Button, Typography } from '@mui/material';
import Modal from '../Modal/Modal';
import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration';
import { useLocation } from 'react-router-dom';
import { AUTH_ROUTES } from 'src/shared/consts/Rout.consts';

const AppUpdateNotification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [waitingServiceWorker, setWaitingServiceWorker] = useState<ServiceWorker | null>(null);
  const authPages = useMemo(() => [AUTH_ROUTES.LOGIN, AUTH_ROUTES.REGISTER], []);
  const location = useLocation();

  serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      setWaitingServiceWorker(registration.waiting);
      if (authPages.includes(location.pathname)) {
        updateAndReload();
      } else {
        setIsOpen(true);
      }
    }
  });

  const updateAndReload = () => {
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: Event) => {
        const serviceWorker = event.target as ServiceWorker;
        if (serviceWorker.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={updateAndReload}>
      <Typography variant='h6' component='h2' sx={{ fontWeight: 'bold' }}>
        A new version of the app is available.
      </Typography>
      <Typography variant='body1' sx={{ mt: 2 }}>
        The app will be updated and refreshed.
      </Typography>
      <Button variant='contained' sx={{ height: '48px', mt: 2 }} onClick={updateAndReload}>
        Update and refresh
      </Button>
    </Modal>
  );
};

export default AppUpdateNotification;

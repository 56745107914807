import React, { useEffect, useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { fetchEventSource } from '@microsoft/fetch-event-source';

// mui
import { Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { setActiveDiscount, setDiscounts } from 'src/store/slices/discountsTabSlice';

// constants
import { DISCOUNTS_ROUTES, ITEMS_ROUTES, MERCHANTS_ROUTES } from 'src/shared/consts/Rout.consts';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { thunkDiscount } from 'src/store/thunks';
import { LocationButton } from 'src/components/LocationButton';
import { ReactComponent as InfoIcon } from 'src/images/info_icon.svg';
import { setActiveMerchant } from 'src/store/slices/merchantsTabSlice';
import DiscountHeader from 'src/components/DiscountHeader/DiscountHeader';
import CustomAppBar from 'src/components/CustomAppBar/CustomAppBar';
import { RequestStatuses } from 'src/utils/enums';
import { DISCOUNTS_PATH } from 'src/shared/consts/Api.consts';
import { API_URL } from 'src/config';

//scripts
import {
  getDiscountAmount,
  getDiscountProductsAmount,
  getDiscountTermsDescription,
  isAvailable
} from 'src/utils/scripts/discount';
import { getAccessToken } from 'src/utils/scripts';

function DiscountPage() {
  const navigate = useNavigate();
  const urlParams: Readonly<Params<'id'>> = useParams();
  const dispatch = useAppDispatch();
  const discount = useAppSelector((state) => state.discountsTab.activeDiscount);
  const discounts = useAppSelector((state) => state.discountsTab.discounts);
  const [isDiscountAvailable, setIsDiscountAvailable] = useState(true);
  const eventAbortController = new AbortController();
  const token = getAccessToken();

  useEffect(() => {
    if (!discount) {
      fetchDiscount(Number(urlParams.id!)).then();
    } else {
      const dateTo = discount.dateTo == null ? new Date() : new Date(discount.dateTo);
      setIsDiscountAvailable(
        discount.enable && (discount.noEnd || discount.dateTo == null || dateTo > new Date())
      );
    }
    return () => {
      eventAbortController?.abort();
    };
  }, []);

  useEffect(() => {
    if (discount?.coupon && !eventAbortController && token) {
      subscribeForDiscountUpdate(discount);
    }
  }, [discount]);

  const goBack = () => {
    navigate(-1);
  };

  const openMerchant = () => {
    if (discount?.merchant) {
      navigate(MERCHANTS_ROUTES.MERCHANT.replace(':id', discount.merchant.id.toString()));
    }
  };

  const fetchDiscount = async (discountId: number) => {
    const response = await dispatch(thunkDiscount({ id: discountId }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      const result = response.payload as Discount;
      setDiscount(result);
    }
  };

  function setDiscount(discount: Discount) {
    dispatch(setActiveDiscount(discount));
    dispatch(setActiveMerchant(discount.merchant));
    const isDiscountAvailable = isAvailable(discount);
    setIsDiscountAvailable(isDiscountAvailable);
    if (!isDiscountAvailable) {
      const newDiscounts = discounts.filter((it) => it.id != discount.id);
      dispatch(setDiscounts(newDiscounts));
    }
  }

  const navigateToDiscountItems = (discount: Discount) => {
    if ((discount?.itemsAmount ?? 0) > 0) {
      dispatch(setActiveDiscount(discount));
      navigate(DISCOUNTS_ROUTES.DISCOUNT_ITEMS.replace(':id', discount.id.toString()));
    } else {
      navigateToMerchantItems(discount.merchant.id);
    }
  };

  function navigateToMerchantItems(merchantId: number) {
    navigate(ITEMS_ROUTES.MERCHANT_ITEMS.replace(':id', merchantId.toString()));
  }

  function subscribeForDiscountUpdate(discount: Discount) {
    const url = `${API_URL}/${DISCOUNTS_PATH.DISCOUNT_EVENTS(discount.id.toString())}`;
    const headers = { Authorization: 'Bearer ' + token };
    if (eventAbortController) {
      eventAbortController.abort();
    }
    const fetchData = async () => {
      await fetchEventSource(url, {
        method: 'GET',
        headers: headers,
        signal: eventAbortController.signal,
        openWhenHidden: true,
        onmessage(event) {
          if (!event.data) {
            return;
          }
          try {
            const parsedData = JSON.parse(event.data.trim());
            const result = parsedData as Discount;
            setDiscount(result);
          } catch (error) {}
        },
        onclose() {
          eventAbortController.abort();
        }
      });
    };
    fetchData();
  }

  return (
    <Stack
      className={'test'}
      width='100%'
      height='100%'
      sx={{
        bgcolor: (theme) => theme.palette.light.main
      }}
    >
      <CustomAppBar
        toolbar={
          <>
            <Button
              variant='outlined'
              color={'dark'}
              startIcon={<ArrowBackIosNewIcon sx={{ color: (theme) => theme.palette.dark.main }} />}
              onClick={() => goBack()}
              sx={{
                width: 36,
                height: 36,
                borderRadius: '8px',
                minWidth: 0,
                '& .MuiButton-startIcon': { margin: 0 }
              }}
            />
            {discount ? (
              <Typography noWrap variant='h4' color='text.primary'>
                {discount.merchant.name}
              </Typography>
            ) : (
              <Skeleton width={'35%'} height={'2.5rem'} />
            )}
            <LocationButton onClick={() => openMerchant()} />
          </>
        }
      >
        <DiscountHeader discount={discount} isDiscountAvailable={isDiscountAvailable} />
      </CustomAppBar>
      <Stack
        width='100%'
        boxSizing='border-box'
        p={2}
        gap={2}
        alignItems='center'
        sx={{
          flex: 1,
          overflowY: 'scroll'
        }}
        position='relative'
      >
        {discount && (
          <Box
            width='fit-content'
            boxSizing='border-box'
            position='absolute'
            maxWidth='90%'
            bgcolor={isDiscountAvailable ? 'primary.main' : '#8A8A8E'}
            py={0.4}
            px={2.5}
            borderRadius={10}
            zIndex={10}
          >
            <Typography fontSize='1rem' color={'white'}>
              Discount {getDiscountAmount(discount)}
            </Typography>
          </Box>
        )}
        {discount ? (
          <Stack
            width='100%'
            boxSizing='border-box'
            direction={'row'}
            gap={1}
            mt={1.8}
            px={1.2}
            pt={3.5}
            pb={2}
            borderRadius={1}
            bgcolor='white'
          >
            <Box width='32px' height='32px'>
              <InfoIcon style={{ width: '32px', height: '32px' }} />
            </Box>
            <Typography
              fontSize='1rem'
              textAlign='left'
              alignSelf={'center'}
              color={isDiscountAvailable ? 'text.primary' : 'text.disabled'}
              style={{ whiteSpace: 'pre-line' }}
            >
              {getDiscountTermsDescription(discount)}
            </Typography>
          </Stack>
        ) : (
          <Skeleton variant='rounded' width='100%' height='6rem' sx={{ mt: 1.8 }} />
        )}
        {discount ? (
          <Stack
            width='100%'
            boxSizing='border-box'
            position='relative'
            direction={'row'}
            justifyContent='space-between'
            gap={1}
            p={2}
            borderRadius={1}
            bgcolor='white'
          >
            <Typography
              fontWeight='bold'
              textAlign='left'
              color={isDiscountAvailable ? 'text.primary' : 'text.disabled'}
              alignSelf={'center'}
            >
              {getDiscountProductsAmount(discount)}
            </Typography>
            <IconButton
              sx={{
                color: 'text.disabled'
              }}
              onClick={() => {
                navigateToDiscountItems(discount!);
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Stack>
        ) : (
          <Skeleton variant='rounded' width='100%' height='4rem' />
        )}
        {discount ? (
          <>
            {discount?.coupon?.qrCodeData && (
              <Box mt={2}>
                <QRCode
                  value={discount?.coupon?.qrCodeData}
                  bgColor={'#F2F2F2'}
                  fgColor={isDiscountAvailable ? '#052B33' : '#8A8A8E'}
                  viewBox={`0 0 256 256`}
                  size={256}
                />
                <Typography
                  variant='h5'
                  fontWeight='bold'
                  color={isDiscountAvailable ? 'text.primary' : 'text.disabled'}
                  mt={2}
                >
                  Scan code QR to get a discount
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <>
            <Skeleton variant='rectangular' width='256px' height='256px' sx={{ mt: 1.8 }} />
            <Skeleton width={'70%'} />
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default DiscountPage;

import React from 'react';

//components
import PrivacyPolicyContent from './PrivacyPolicyContent';
import PrivacyPolicyComponent from 'src/components/PrivacyPolicyComponent/PrivacyPolicyComponent';

const PrivacyPolicyPage = () => {
  return (
    <PrivacyPolicyComponent header='Privacy Policy'>
      <PrivacyPolicyContent />
    </PrivacyPolicyComponent>
  );
};

export default PrivacyPolicyPage;

import React, { useEffect } from 'react';
import { Box, createTheme, SxProps, Theme, ThemeProvider } from '@mui/material';
import { cssVar } from 'src/utils/scripts';

interface Props {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const AuthLayout: React.FC<Props> = ({ children, sx }) => {
  const vh = window.innerHeight * 0.01;

  useEffect(() => {
    const handleResize = () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme({
          ...theme,
          components: {
            MuiCircularProgress: {
              styleOverrides: {
                root: {
                  color: 'white',
                  width: '30px !important',
                  height: '30px !important'
                }
              }
            }
          }
        })
      }
    >
      <Box
        component='section'
        display='flex'
        width='100vw'
        sx={{
          boxSizing: 'border-box',
          justifyContent: 'center',
          alignItems: { xs: 'start', sm: 'center' },
          py: { xs: 0, sm: 2 },
          '@media (max-width: 480px) and (orientation: portrait)': {
            height: 'calc(var(--vh, 1vh) * 100)'
          },
          '@media (min-width: 481px)': {
            minHeight: '100vh'
          }
        }}
      >
        <Box
          sx={{
            width: '100%',
            boxShadow: { xs: 0, sm: 6 },
            borderRadius: 2,
            boxSizing: 'border-box',
            px: 2,
            py: '3vh',
            ...sx,

            '@media (max-width: 480px)': {
              minHeight: '100%'
            }
          }}
          display='flex'
          flexDirection='column'
          maxWidth='400px'
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export enum IssuesCoupon {
  ALWAYS = 'ALWAYS',
  NUMBER_OF_VISITS = 'NUMBER_OF_VISITS',
  AMOUNT_SPENT = 'AMOUNT_SPENT'
}
export enum IssuesCouponFor {
  ENTIRE_ORDER = 'ENTIRE_ORDER',
  SELECTED_ITEMS = 'SELECTED_ITEMS'
}
export enum CouponExpiration {
  NEVER_EXPIRES = 'NEVER_EXPIRES',
  EXPIRES = 'EXPIRES '
}
export enum CouponExpirationTitle {
  NEVER_EXPIRES = 'Never expires',
  EXPIRES = 'Expires '
}

import { MapPosition } from 'src/shared/interfaces/mapPoint.interface';

export function getGeolocation(
  onGetGeolocation: (geolocation: MapPosition) => void,
  onGetGeolocationFailed?: () => void
) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const geolocation: MapPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        onGetGeolocation(geolocation);
      },
      function (error) {
        console.error('Error Getting geolocation: ' + error.message);
        if (onGetGeolocationFailed) onGetGeolocationFailed();
      }
    );
  } else {
    if (onGetGeolocationFailed) onGetGeolocationFailed();
  }
}

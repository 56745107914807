export const commonStyles = {
  backgroundColor: '#ffffff',
  height: '44px',
  width: '100%',
  borderRadius: '8px',
  borderColor: 'transparent',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiSelect-select, & .MuiAutocomplete-inputRoot': {
    padding: '10px',
    borderColor: 'transparent'
  },
  '& .MuiAutocomplete-inputRoot': {
    padding: '6px !important'
  }
};

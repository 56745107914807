import React, { useRef } from 'react';

// mui
import { Box, Stack, Typography } from '@mui/material';

//components
import LoadingCircularProgress from 'src/components/LoadingCircularProgress';
import { ReactComponent as NothingFoundIcon } from 'src/images/nothing_found_icon.svg';
import { usePaginationObserver } from 'src/hooks/usePaginationObserver';

type scrollType = 'initial' | 'inherit' | 'hidden' | 'auto';

interface Props {
  children: React.ReactNode;
  elementsLength: number;
  isLoading: boolean;
  isLastPage: boolean;
  pageNumber: number;
  notFoundMessage?: string;
  notFoundComponent?: React.ReactNode | null;
  overflow?: scrollType;
  loadPage(pageNumber: number): void;
  pageLoader: React.ReactNode;
  bgcolor?: string;
}

const InfiniteScroll: React.FC<Props> = ({
  children,
  elementsLength,
  isLoading,
  isLastPage,
  pageNumber,
  notFoundMessage = 'Nothing found',
  notFoundComponent = null,
  overflow,
  loadPage,
  pageLoader,
  bgcolor
}) => {
  const lastElement = useRef<HTMLDivElement>(null);

  usePaginationObserver(lastElement, !isLoading && !isLastPage && elementsLength > 0, () => {
    loadPage(pageNumber + 1);
  });

  return (
    <Stack
      sx={{
        flex: 1,
        height: '100%',
        justifyContent: elementsLength == 0 ? 'center' : 'flex-start',
        ...(overflow ? { overflowY: overflow } : {}),
        bgcolor: bgcolor ? bgcolor : (theme) => theme.palette.light.main
      }}
    >
      {elementsLength > 0 && (
        <>
          {children}
          {!isLoading && !isLastPage && (
            <Box ref={lastElement} sx={{ width: '100%', minHeight: '80px' }}></Box>
          )}
        </>
      )}
      {isLoading ? (
        <>
          {elementsLength == 0 && <LoadingCircularProgress isLoading={isLoading} fullElement />}
          {elementsLength > 0 && !isLastPage && <>{pageLoader}</>}
        </>
      ) : (
        <>
          {elementsLength == 0 && (
            <>
              {!!notFoundComponent ? (
                <>{notFoundComponent}</>
              ) : (
                <Stack sx={{ width: '70%', py: 4, alignItems: 'center', alignSelf: 'center' }}>
                  <Typography
                    color='text.primary'
                    variant='h4'
                    sx={{ fontWeight: 'bold', lineHeight: '28px', mb: 2.5 }}
                  >
                    {notFoundMessage}
                  </Typography>
                  <NothingFoundIcon />
                </Stack>
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
};

export default InfiniteScroll;

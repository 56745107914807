import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// types
import { CartItem } from 'src/shared/interfaces/cartItem.interface';

interface cartState {
  items: CartItem[];
}

const initialState: cartState = {
  items: []
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,

  reducers: {
    setCartItems(state, action: PayloadAction<cartState['items']>) {
      state.items = action.payload;
    }
  }
});

export const { setCartItems } = cartSlice.actions;

export default cartSlice.reducer;

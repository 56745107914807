import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Params, useNavigate, useOutletContext, useParams } from 'react-router-dom';

// hooks
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';

// redux
import { thunkMerchant, thunkMerchantsDiscounts } from 'src/store/thunks';
import { setActiveDiscount } from 'src/store/slices/discountsTabSlice';

// mui
import { Box, Skeleton, Typography } from '@mui/material';

// components
import { DiscountList } from 'src/components/Discounts';
import InfiniteScroll from 'src/components/InfiniteScroll/InfiniteScroll';
import DiscountsListItem from 'src/components/Discounts/discount/DiscountsListItem';

// constants
import { DISCOUNTS_ROUTES } from 'src/shared/consts/Rout.consts';

// types
import { Page } from 'src/shared/interfaces/page.interface';
import BottomDrawer from 'src/components/BottomDrawer/BottomDrawer';
import { MapPoint, MapPosition } from 'src/shared/interfaces/mapPoint.interface';
import { setActiveMerchant } from 'src/store/slices/merchantsTabSlice';
import MerchantListItem from 'src/components/Merchants/MerchantListItem';
import { ReactComponent as PhoneNumberIcon } from 'src/images/phone_number_icon.svg';
import { MerchantOpeningHoursContent } from 'src/components/MerchantOpeningHours/MerchantOpeningHoursContent';
import { getMerchantDistance } from 'src/utils/scripts/merchants';
import { RequestStatuses } from 'src/utils/enums';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

//TODO extract type
interface OutletContextProperty<T> {
  value: T;
  setValue: React.Dispatch<React.SetStateAction<T>>;
}

interface OutletContextProps {
  mapPoints: OutletContextProperty<MapPoint[]>;
  currentMarkerLocation: OutletContextProperty<MapPosition>;
  geolocate: OutletContextProperty<boolean>;
  drawerInitialHeight: OutletContextProperty<number>;
  headerRef: OutletContextProperty<RefObject<HTMLDivElement>>;
  container: RefObject<HTMLDivElement>;
  currentGeolocation: MapPosition;
}

function MerchantDrawerPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const merchant = useAppSelector((state) => state.merchantsTab.activeMerchant);
  const urlParams: Readonly<Params<'id'>> = useParams();
  const [isFullLoading, setIsFullLoading] = useState(false);
  const [isMerchantLoading, setIsMerchantLoading] = useState(false);
  const [isDiscountsLoading, setIsDiscountsLoading] = useState(true);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const headerRef = useRef<HTMLDivElement>(null);
  const [discountsPageNumber, setDiscountsPageNumber] = useState(0);
  const [isDiscountsLastPage, setIsDiscountsLastPage] = useState(false);
  const context = useOutletContext<OutletContextProps>();

  useEffect(() => {
    context.geolocate.setValue(false);
    if (!merchant || merchant.id.toString() != urlParams.id!) {
      setIsFullLoading(true);
      fetchMerchant(urlParams.id!);
    }
    if (!discounts.length || discounts[0].merchant.id.toString() != urlParams.id!) {
      fetchDiscounts(urlParams.id!, discountsPageNumber);
    }
  }, [merchant, urlParams.id]);

  useEffect(() => {
    if (merchant) {
      const position: MapPosition = {
        lat: merchant.latitude,
        lng: merchant.longitude
      };
      context.mapPoints.setValue([
        {
          id: merchant!.id,
          name: merchant!.name,
          position: position,
          onClick: () => {
            navigationClickHandler(merchant);
          }
        }
      ]);
      context.currentMarkerLocation.setValue(position);
    }
  }, [merchant]);

  useEffect(() => {
    if (context && headerRef) context.headerRef.setValue(headerRef);
  }, [context, headerRef]);

  useEffect(() => {
    if (merchant) {
      const content: Merchant = Object.assign({}, merchant);
      content.distance = getMerchantDistance(merchant, context.currentGeolocation);
      if (merchant.distance != content.distance) dispatch(setActiveMerchant(content));
    }
  }, [context.currentGeolocation, merchant]);

  const navigationClickHandler = (merchant: Merchant) => {
    listCloseEvent.apply();
    context.currentMarkerLocation.setValue({
      lat: merchant.latitude,
      lng: merchant.longitude
    });
  };

  const fetchMerchant = async (merchantId: string) => {
    setIsMerchantLoading(true);
    const response = await dispatch(thunkMerchant({ id: merchantId }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      dispatch(setActiveMerchant(response.payload as Merchant));
      setIsMerchantLoading(false);
    }
  };

  const fetchDiscounts = async (merchantId: string, pageNumber: number) => {
    setIsDiscountsLoading(true);
    const response = await dispatch(
      thunkMerchantsDiscounts({
        pagination: { page: pageNumber, sort: 'name' },
        merchantId: merchantId.toString()
      })
    );
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      const { content } = response.payload as Page<Discount>;
      const { last } = response.payload as Page<Discount>;
      setIsDiscountsLastPage(last);
      if (pageNumber == 0) {
        setDiscounts(content);
      } else {
        setDiscounts([...discounts, ...content]);
      }
      setIsDiscountsLoading(false);
    }
  };

  const navigateToDiscount = (discount: Discount) => {
    dispatch(setActiveDiscount(discount));
    navigate(DISCOUNTS_ROUTES.DISCOUNT.replace(':id', discount.id.toString()));
  };

  const listCloseEvent = {
    apply: () => {}
  };

  function formatPhoneNumber(phoneNumber: string | null) {
    if (!phoneNumber) return null;
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{2,5})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  const loadDiscountsPage = (pageNumber: number) => {
    if (merchant) {
      setDiscountsPageNumber(pageNumber);
      fetchDiscounts(merchant.id.toString(), pageNumber);
    }
  };

  return (
    <BottomDrawer
      header={
        <Box ref={headerRef}>
          <MerchantListItem
            component='div'
            merchant={isFullLoading && isDiscountsLoading ? null : merchant}
            divider={true}
            onSecondaryClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event.stopPropagation();
              if (merchant) {
                navigationClickHandler(merchant);
              }
            }}
          />
          {merchant && (!isFullLoading || !isDiscountsLoading) ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 2
              }}
            >
              {merchant.openingHours.length > 0 ? (
                <MerchantOpeningHoursContent
                  merchant={merchant}
                  container={context.container.current ?? undefined}
                />
              ) : (
                <Box />
              )}
              {!!merchant?.phoneNumber && merchant.phoneNumber.trim() != '' && (
                <Box sx={{ height: 'fit-content', display: 'fled', alignItems: 'center' }}>
                  <Box
                    sx={{
                      display: 'fled',
                      height: 'fit-content',
                      alignItems: 'center',
                      px: 1,
                      py: 1,
                      pt: 1.8
                    }}
                  >
                    <PhoneNumberIcon />
                  </Box>
                  <Typography variant={'body1'} color={'secondary.main'}>
                    {formatPhoneNumber(merchant?.phoneNumber ?? null)}
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Skeleton sx={{ mx: 2, my: 1 }} />
          )}
        </Box>
      }
      contentMinHeight={90}
      maxHeight={(context.container.current ? context.container.current.clientHeight : 0) * 0.9}
      closeEvent={listCloseEvent}
      contentBackgroundColor='#F2F2F2'
      overflowHidden={isDiscountsLoading && !discounts.length}
      onSetInitialHeight={context.drawerInitialHeight.setValue}
    >
      <Box>
        {(isDiscountsLoading && !discounts.length) || isMerchantLoading ? (
          <DiscountList discounts={[null, null]} onDiscountClick={navigateToDiscount} />
        ) : (
          <>
            <InfiniteScroll
              elementsLength={discounts.length}
              isLoading={isDiscountsLoading}
              isLastPage={isDiscountsLastPage}
              pageNumber={discountsPageNumber}
              notFoundComponent={
                <Box display='flex' alignItems='center' justifyContent='center' height='90px'>
                  <Typography color={'text.secondary'}>No discounts available</Typography>
                </Box>
              }
              loadPage={loadDiscountsPage}
              pageLoader={
                <Box sx={{ position: 'relative', top: '-0.5rem' }}>
                  <DiscountsListItem discount={null} />
                </Box>
              }
            >
              <DiscountList discounts={discounts} onDiscountClick={navigateToDiscount} />
            </InfiniteScroll>
          </>
        )}
      </Box>
    </BottomDrawer>
  );
}

export default MerchantDrawerPage;

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UserProfile } from 'src/store/interfaces/users/userProfile';

interface UserState {
  current: UserProfile | null;
}

const initialState: UserState = {
  current: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setUser(state, action: PayloadAction<UserState['current']>) {
      state.current = action.payload;
    }
  }
});

export const { setUser } = userSlice.actions;

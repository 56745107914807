import React from 'react';

//mui
import { Box, Stack, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AppInstallInstructionAndroidFirefox = () => {
  return (
    <Stack gap={1}>
      <Typography>Install the app on your device to easily access it anytime.</Typography>
      <Box sx={{ display: 'flex' }}>
        <Typography>1. Tap on </Typography>
        <MoreVertIcon sx={{ px: 0.5 }} />
      </Box>
      <Typography>
        2. Select <strong> Install </strong>
      </Typography>
    </Stack>
  );
};

export default AppInstallInstructionAndroidFirefox;

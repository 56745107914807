import { OrderCreationDto, OrderCreationItemDto } from 'src/shared/interfaces/order.interface';
import { isPricePerUnit } from 'src/utils/scripts/cart';
import { QUANTITY_PER_UNIT } from 'src/shared/consts/Orders.consts';
import { thunkUpdateOrder } from 'src/store/thunks';
import { RequestStatuses } from 'src/utils/enums';
import { DiscountOrder } from 'src/shared/interfaces/items.interface';
import { CartItem } from 'src/shared/interfaces/cartItem.interface';
import type { AppDispatch } from 'src/store/store';
import { setCartItems } from 'src/store/slices/cartSlice';

export const fetchUpdateCartItem = async (cartItems: CartItem[], dispatch: AppDispatch) => {
  const merchantId = cartItems[0]?.item.merchantId;
  const items: OrderCreationItemDto[] = cartItems.map((it) => ({
    itemId: it.item.id,
    priceType: it.item.priceType,
    price: it.item.price,
    quantity: isPricePerUnit(it.item.priceType)
      ? Math.round(it.quantity * QUANTITY_PER_UNIT)
      : it.quantity
  }));

  const request: OrderCreationDto = { merchantId, items };
  const response = await dispatch(thunkUpdateOrder(request));

  if (response.meta.requestStatus === RequestStatuses.fulfilled) {
    const discounts = response.payload as DiscountOrder[];

    const discountMap: { [key: string]: number } = discounts.reduce(
      (map: { [key: string]: number }, discount) => {
        map[discount.itemId] = discount.discountAmount;
        return map;
      },
      {}
    );
    const updatedItems: CartItem[] = cartItems.map((i) => {
      let discountAmount: number | undefined = undefined;
      if (discountMap[i.item.id]) {
        discountAmount = discountMap[i.item.id];
      }
      return {
        ...i,
        item: {
          ...i.item,
          discountAmount
        }
      };
    });
    dispatch(setCartItems(updatedItems));
  }
};

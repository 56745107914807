import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
// mui
import { Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const Puller = styled(Box)(({ theme }) => ({
  width: 36,
  height: 5,
  backgroundColor: theme.palette.mode === 'light' ? grey[500] : grey[900],
  borderRadius: 3,
  position: 'relative',
  top: 8,
  left: 'calc(50% - 18px)'
}));

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  contentMinHeight: number;
  maxHeight: number;
  closeEvent?: { apply: () => void };
  contentBackgroundColor?: string;
  overflowHidden?: boolean;
  onSetInitialHeight?: (initialHeight: number) => void;
}

const BottomDrawer: React.FC<Props> = ({
  header,
  children,
  contentMinHeight,
  maxHeight,
  closeEvent,
  contentBackgroundColor,
  overflowHidden = false,
  onSetInitialHeight
}) => {
  const [contentHeight, setContentHeight] = useState(contentMinHeight);
  const [swipeTransition, setSwipeTransition] = useState<boolean>(true);
  const [contentMaxHeight, setContentMaxHeight] = useState<number>(maxHeight);
  const [contentCurrentMaxHeight, setContentCurrentMaxHeight] = useState<number>(maxHeight);
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const minSwipeDistance = 20;
  const maxSwipeTime = 200;

  useEffect(() => {
    if (headerRef.current && contentCurrentMaxHeight > 0) {
      setContentMaxHeight(contentCurrentMaxHeight - headerRef.current.scrollHeight);
    }
  }, [headerRef, contentCurrentMaxHeight]);

  useEffect(() => {
    setContentCurrentMaxHeight(Math.max(contentCurrentMaxHeight, maxHeight));
  }, [maxHeight]);

  useLayoutEffect(() => {
    if (headerRef.current && contentRef.current && onSetInitialHeight && header) {
      const headerHeight = headerRef.current.getBoundingClientRect().height;
      const contentHeight = contentRef.current.getBoundingClientRect().height;
      onSetInitialHeight(headerHeight + contentHeight);
    }
  }, [headerRef.current?.clientHeight]);

  const open = () => {
    swipe(getMaxHeight());
  };

  const close = () => {
    swipe(contentMinHeight);
  };

  if (closeEvent) {
    closeEvent.apply = close;
  }

  function getMaxHeight() {
    return contentRef.current
      ? Math.min(contentMaxHeight, contentRef.current.scrollHeight)
      : contentMaxHeight;
  }

  function getHeightOnMove(startHeight: number, startPosition: number, currentPosition: number) {
    const height = startHeight + startPosition - currentPosition;
    return Math.min(Math.max(height, contentMinHeight), getMaxHeight());
  }

  function handleSwipe(startPosition: number, endPosition: number) {
    const distance = startPosition - endPosition;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    if (isUpSwipe) open();
    if (isDownSwipe) close();
  }

  function swipe(height: number) {
    setSwipeTransition(true);
    setContentHeight(height);
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setSwipeTransition(false);
    const touchStart = Date.now();
    const startHeight = contentHeight;
    const startPosition = event.pageY;
    let currentHeight = startHeight;

    function handleMouseMove(event: MouseEvent) {
      const currentPosition = event.pageY;
      currentHeight = getHeightOnMove(startHeight, startPosition, currentPosition);
      setContentHeight(currentHeight);
    }

    function handleMouseUp(event: MouseEvent) {
      const touchEnd = Date.now();
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      if (touchEnd - touchStart < maxSwipeTime) {
        handleSwipe(startPosition, event.pageY);
        return;
      }
      if (currentHeight >= getMaxHeight() * 0.5) {
        open();
      } else close();
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    setSwipeTransition(false);
    const touchStart = Date.now();
    const startHeight = contentHeight;
    const startPosition = event.targetTouches[0].pageY;
    let currentHeight = startHeight;
    let currentPosition = event.targetTouches[0].pageY;

    function handleTouchMove(event: TouchEvent) {
      currentPosition = event.targetTouches[0].pageY;
      currentHeight = getHeightOnMove(startHeight, startPosition, currentPosition);
      setContentHeight(currentHeight);
    }

    function handleTouchEnd() {
      const touchEnd = Date.now();
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      if (touchEnd - touchStart < maxSwipeTime) {
        handleSwipe(startPosition, currentPosition);
        return;
      }

      currentHeight >= getMaxHeight() * 0.5 ? open() : close();
    }

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  return (
    <Box
      width='100%'
      height='fit-content'
      display='flex'
      flexDirection='column'
      bgcolor={'white'}
      boxShadow={6}
      gap={0}
      sx={{
        position: 'absolute',
        bottom: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        zIndex: 100
      }}
    >
      <Box
        minHeight={36}
        ref={headerRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <Box height={18}>
          <Puller />
        </Box>
        {header}
      </Box>
      <Divider variant='fullWidth' />
      <Box
        width='100%'
        height={contentHeight}
        minHeight={contentMinHeight}
        maxHeight={contentMaxHeight}
        sx={{
          transition: swipeTransition ? 'all 0.5s' : '',
          overflowY: overflowHidden ? 'hidden' : 'scroll'
        }}
        bgcolor={contentBackgroundColor}
        ref={contentRef}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BottomDrawer;

import { Item, MerchantItem } from 'src/shared/interfaces/items.interface';

export enum ItemPriceType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
  PER_UNIT = 'PER_UNIT'
}

export function toItem(merchantItem: MerchantItem): Item {
  return {
    ...merchantItem,
    merchantId: merchantItem.merchant.id,
    merchantName: merchantItem.merchant.name
  };
}

export function getItemName(item: Item | null): string {
  if (item) {
    return item.customName ?? item.name;
  } else {
    return '';
  }
}

export enum ItemTab {
  ALL = 'ALL',
  PURCHASED = 'PURCHASED'
}

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

//mui
import { Button, Typography } from '@mui/material';

//components
import Modal from 'src/components/Modal/Modal';
import LoadingCircularProgress from 'src/components/LoadingCircularProgress';

//redux
import { useAppDispatch } from 'src/hooks/hooks';
import { thunkAddMerchantByToken } from 'src/store/thunks';

//types
import { RequestError } from 'src/utils/types';
import { RequestStatuses } from 'src/utils/enums';

//scripts
import { getAccessToken, removeAccessToken } from 'src/utils/scripts';

//constants
import { MERCHANT_TOKEN } from 'src/shared/consts/SearchParameter.consts';
import { AUTH_ROUTES } from 'src/shared/consts/Rout.consts';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

interface Props {
  onFinished(): void;
}

const AddMerchant: React.FC<Props> = ({ onFinished }) => {
  const [search, setSearch] = useSearchParams();
  const merchantToken = search.get(MERCHANT_TOKEN);
  const accessToken = getAccessToken();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(!!merchantToken);
  const [isMerchantAddLoading, setIsMerchantAddLoading] = useState(true);
  const [isMerchantAdded, setIsMerchantAdded] = useState(false);
  const [newMerchantName, setMerchantName] = useState<string | null>(null);

  useEffect(() => {
    if (isMerchantAddLoading && accessToken && merchantToken) {
      fetchAddMerchantByToken(merchantToken);
    }
  }, []);

  const fetchAddMerchantByToken = async (merchantToken: string) => {
    const response = await dispatch(
      thunkAddMerchantByToken({ accessToken: accessToken!, merchantToken: merchantToken })
    );
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      const newMerchant = response.payload as Merchant;
      setMerchantName(newMerchant.name);
      setIsMerchantAdded(true);
      onFinished();
    } else {
      const requestError = response.payload as RequestError;
      if (requestError.response.status != 401) {
        onFinished();
      } else {
        removeAccessToken();
        window.location.replace(AUTH_ROUTES.REGISTER + window.location.search);
        return;
      }
    }
    setIsMerchantAddLoading(false);
    search.delete(MERCHANT_TOKEN);
    setSearch(search);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        if (!isMerchantAddLoading) setIsModalOpen(false);
      }}
      sx={{ gap: 2 }}
    >
      <LoadingCircularProgress isLoading={isMerchantAddLoading} fullElement>
        {isMerchantAdded ? (
          <>
            <Typography variant='h5' component='h2' sx={{ fontWeight: 'bold' }}>
              {newMerchantName} was added to your account!
            </Typography>
            <Typography variant='h6'>
              You should now have access to your Digital Coupons from {newMerchantName} and more!
            </Typography>
          </>
        ) : (
          <>
            <Typography variant='h5' component='h2' sx={{ fontWeight: 'bold' }}>
              Failed to add a store to your account!
            </Typography>
            <Typography variant='h6'>The QR code is invalid.</Typography>
          </>
        )}
        <Button
          variant='contained'
          color={isMerchantAdded ? 'primary' : 'error'}
          sx={{ flex: 1, color: 'white' }}
          onClick={() => setIsModalOpen(false)}
        >
          Close
        </Button>
      </LoadingCircularProgress>
    </Modal>
  );
};

export default AddMerchant;

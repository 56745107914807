import { Box } from '@mui/material';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { AttributionControl, GeolocateControl, Map, MapRef, Marker } from 'react-map-gl';
import maplibregl, { LngLatLike } from 'maplibre-gl';

import 'maplibre-gl/dist/maplibre-gl.css';
import 'src/components/MapAWS/styles.css';
import { MapPoint, MapPosition } from 'src/shared/interfaces/mapPoint.interface';
import {
  AMAZON_LOCATION_SERVICE_API_KEY,
  AMAZON_LOCATION_SERVICE_MAP_NAME,
  AMAZON_LOCATION_SERVICE_REGION
} from 'src/config';
import { getDistance } from 'src/utils/scripts';
import { MapControl } from 'src/shared/interfaces/mapControl.interface';

interface Props {
  points: MapPoint[];
  currentPosition?: MapPosition;
  geolocate?: boolean;
}

const DEFAULT_ZOOM = 16;
const MAX_DURATION = 6000;

export const MapAWS = forwardRef<MapControl, Props>(
  ({ points, currentPosition, geolocate = true }, ref) => {
    const [isLoad, setIsLoad] = useState(false);
    const map = useRef<MapRef>(null);

    useImperativeHandle(ref, () => ({
      showAllMarkers() {
        return showAll();
      }
    }));

    useEffect(() => {
      if (currentPosition && map.current) {
        map.current.flyTo({
          center: currentPosition,
          zoom: DEFAULT_ZOOM,
          maxDuration: MAX_DURATION
        });
      }
    }, [currentPosition]);
    const geolocateControl = useRef<maplibregl.GeolocateControl>(null);

    const onMapLoad = useCallback(() => {
      setIsLoad(true);
    }, []);

    useEffect(() => {
      if (geolocateControl && isLoad && geolocate) {
        geolocateControl.current?.trigger();
      }
    }, [isLoad, geolocate]);

    function showAll() {
      const currentPoints = [...points];
      if (map.current && currentPoints.length > 1) {
        const { sw, ne } = getBounds(currentPoints);
        map.current.fitBounds([sw, ne], { padding: 20, maxDuration: MAX_DURATION });
      }
    }

    function getBounds(currentPoints: MapPoint[]) {
      const distances = currentPoints
        .flatMap((pointA) =>
          currentPoints
            .filter((pointB) => pointB != pointA)
            .map((pointB) => ({
              pointA: pointA,
              pointB: pointB,
              distance: getDistance(
                pointA.position.lat,
                pointA.position.lng,
                pointB.position.lat,
                pointB.position.lng
              )
            }))
        )
        .sort((a, b) => (b.distance ?? 0) - (a.distance ?? 0));
      const sw = new maplibregl.LngLat(
        distances[0].pointA.position.lng,
        distances[0].pointA.position.lat
      );
      const ne = new maplibregl.LngLat(
        distances[0].pointB.position.lng,
        distances[0].pointB.position.lat
      );
      return { sw, ne };
    }

    function getInitialViewState() {
      if (currentPosition) {
        return {
          latitude: currentPosition?.lat,
          longitude: currentPosition?.lng,
          zoom: DEFAULT_ZOOM
        };
      }
      if (points.length == 0) {
        return {
          latitude: 38,
          longitude: -97,
          zoom: 3
        };
      }
      if (points.length == 1) {
        return {
          latitude: points[0].position.lat,
          longitude: points[0].position.lng,
          zoom: DEFAULT_ZOOM
        };
      }
      if (points.length > 1) {
        const { sw, ne } = getBounds([...points]);
        return { bounds: [sw, ne] as [LngLatLike, LngLatLike], fitBoundsOptions: { padding: 20 } };
      }
    }

    return (
      <Box width='100%' height='100%' bgcolor='white'>
        <Map
          // @ts-ignore
          mapLib={import('maplibre-gl')}
          initialViewState={getInitialViewState()}
          mapStyle={`https://maps.geo.${AMAZON_LOCATION_SERVICE_REGION}.amazonaws.com/maps/v0/maps/${AMAZON_LOCATION_SERVICE_MAP_NAME}/style-descriptor?key=${AMAZON_LOCATION_SERVICE_API_KEY}`}
          minZoom={1}
          // @ts-ignore
          ref={map}
          onLoad={onMapLoad}
          attributionControl={false}
        >
          {points.map((point) => (
            <Marker
              key={point.id}
              latitude={point.position.lat}
              longitude={point.position.lng}
              onClick={point.onClick}
              style={{ cursor: 'pointer' }}
            />
          ))}
          <AttributionControl position={'bottom-left'} />
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            fitBoundsOptions={{ zoom: DEFAULT_ZOOM, maxDuration: MAX_DURATION }}
            showAccuracyCircle={false}
            trackUserLocation={true}
            position={'bottom-right'}
            // @ts-ignore
            ref={geolocateControl}
          />
        </Map>
      </Box>
    );
  }
);

export default MapAWS;

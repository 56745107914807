import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Outlet, Params, useParams } from 'react-router-dom';

// mui
import { Box, Stack } from '@mui/material';

// components
import MapAWS from 'src/components/MapAWS/MapAWS';
import SearchBar from 'src/components/SearchBar/SearchBar';

// types
import { MapPoint, MapPosition } from 'src/shared/interfaces/mapPoint.interface';
import { MapControl } from 'src/shared/interfaces/mapControl.interface';

//scripts
import { getGeolocation } from 'src/utils/scripts/geolocation';

//redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { setGeolocation, setIsGeolocationEnable } from 'src/store/slices/geolocationSlice';

const MerchantsPage = () => {
  const dispatch = useAppDispatch();
  const urlParams: Readonly<Params<'id'>> = useParams();
  const mapControl = useRef<MapControl>(null);
  const currentGeolocation = useAppSelector((state) => state.geolocation.current);
  const [mapPoints, setMapPoints] = useState<MapPoint[] | null>(null);
  const [currentMarkerLocation, setCurrentMarkerLocation] = useState<MapPosition>();
  const [geolocate, setGeolocate] = useState(!urlParams.id);
  const container = useRef<HTMLDivElement>(null);
  const [mapHeight, setMapHeight] = useState(0);
  const [drawerHeaderRef, setDrawerHeaderRef] = useState<RefObject<HTMLDivElement> | null>(null);
  const [drawerInitialHeight, setDrawerInitialHeight] = useState(0);

  useEffect(() => {
    if (!drawerHeaderRef?.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const mapHeight =
        (container.current ? container.current.clientHeight : 0) -
        (drawerHeaderRef?.current ? drawerHeaderRef.current.clientHeight + 18 : 36) -
        50;
      setMapHeight(mapHeight);
    });
    resizeObserver.observe(drawerHeaderRef.current);
    return () => resizeObserver.disconnect();
  }, [drawerHeaderRef]);

  useEffect(() => {
    setCurrentGeolocation();
  }, []);

  function setCurrentGeolocation() {
    getGeolocation(
      (geolocation) => {
        if (geolocate) setCurrentMarkerLocation(geolocation);
        dispatch(setGeolocation(geolocation));
        dispatch(setIsGeolocationEnable(true));
      },
      () => {
        dispatch(setIsGeolocationEnable(false));
      }
    );
  }

  const showAllMarkers = () => {
    mapControl.current?.showAllMarkers();
  };

  return (
    <Stack width='100%' height='100%'>
      <SearchBar />
      <Box
        id={'stores-page'}
        width='100%'
        height='100%'
        display='flex'
        flexDirection='column'
        sx={{
          boxShadow: { xs: 0, sm: 6 },
          position: 'relative'
        }}
        ref={container}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            flex: 1,
            bgcolor: (theme) => theme.palette.light.main
          }}
        >
          {!!mapPoints && (
            <MapAWS
              ref={mapControl}
              points={mapPoints}
              currentPosition={currentMarkerLocation}
              geolocate={geolocate}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            height: drawerInitialHeight - 40
          }}
        />
        <Outlet
          context={{
            mapPoints: { value: mapPoints, setValue: setMapPoints },
            currentMarkerLocation: {
              value: currentMarkerLocation,
              setValue: setCurrentMarkerLocation
            },
            geolocate: {
              value: geolocate,
              setValue: setGeolocate
            },
            drawerInitialHeight: {
              value: drawerInitialHeight,
              setValue: setDrawerInitialHeight
            },
            showAllMarkers: showAllMarkers,
            headerRef: { value: drawerHeaderRef, setValue: setDrawerHeaderRef },
            container: container,
            currentGeolocation: currentGeolocation
          }}
        />
      </Box>
    </Stack>
  );
};

export default MerchantsPage;

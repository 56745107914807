export const BASE_PATH = '/';
export const GUARDOE_URL =
  (globalThis as any).import_meta_env.GUARDOE_URL || process.env.REACT_APP_GUARDOE_URL;

export const API_URL =
  ((globalThis as any).import_meta_env.API_URL ||
    process.env.REACT_APP_API_URL ||
    'http://localhost:8080') + process.env.PUBLIC_URL;

export const BASE_DOMAIN =
  (globalThis as any).import_meta_env.BASE_DOMAIN || process.env.REACT_APP_BASE_DOMAIN || '';

export const REACT_APP_GOOGLE_MAP_API_KEY =
  (globalThis as any).import_meta_env.GOOGLE_MAP_API_KEY ||
  process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
  '';
export const AMAZON_LOCATION_SERVICE_API_KEY =
  (globalThis as any).import_meta_env.AMAZON_LOCATION_SERVICE_API_KEY ||
  process.env.REACT_APP_AMAZON_LOCATION_SERVICE_API_KEY ||
  '';
export const AMAZON_LOCATION_SERVICE_MAP_NAME =
  (globalThis as any).import_meta_env.AMAZON_LOCATION_SERVICE_MAP_NAME ||
  process.env.REACT_APP_AMAZON_LOCATION_SERVICE_MAP_NAME ||
  '';
export const AMAZON_LOCATION_SERVICE_REGION =
  (globalThis as any).import_meta_env.AMAZON_LOCATION_SERVICE_REGION ||
  process.env.REACT_APP_AMAZON_LOCATION_SERVICE_REGION ||
  '';

import React from 'react';
import { List } from '@mui/material';
import MerchantsListItem from 'src/components/Merchants/MerchantListItem';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

interface Props {
  merchants: Merchant[] | null[];
  onMerchantClick?(merchant: Merchant): void;
  onMerchantLocationClick?(merchant: Merchant): void;
}

const MerchantList: React.FC<Props> = ({
  merchants,
  onMerchantClick = () => {},
  onMerchantLocationClick = () => {}
}) => {
  return (
    <List sx={{ width: '100%', pt: 0 }}>
      {merchants.map((merchant) =>
        merchant ? (
          <MerchantsListItem
            key={merchant.id}
            merchant={merchant}
            onClick={() => {
              onMerchantClick(merchant);
            }}
            onSecondaryClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              onMerchantLocationClick(merchant);
            }}
          />
        ) : (
          <MerchantsListItem key={merchant} merchant={merchant} />
        )
      )}
    </List>
  );
};

export default MerchantList;

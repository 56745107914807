import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// types
import { Discount } from 'src/shared/interfaces/discount.interface';
import { Merchant } from 'src/shared/interfaces/merchant.interface';
import { CouponsTab } from 'src/shared/enums';
import { Coupon } from 'src/shared/interfaces/coupon.interface';

interface discountsTabState {
  discounts: Discount[];
  coupons: Coupon[];
  activeDiscount: Discount | null;
  activeMerchant: Merchant | null;
  discountPage: {
    currentPage: number;
    isLastPage: boolean;
  };
  couponsPage: {
    currentPage: number;
    isLastPage: boolean;
  };
  currentTab: CouponsTab;
}

const initialState: discountsTabState = {
  discounts: [],
  activeDiscount: null,
  activeMerchant: null,
  currentTab: CouponsTab.DISCOUNT,
  couponsPage: {
    currentPage: 0,
    isLastPage: false
  },
  discountPage: {
    currentPage: 0,
    isLastPage: false
  },
  coupons: []
};

export const discountsTabSlice = createSlice({
  name: 'discountTab',
  initialState,

  reducers: {
    setDiscounts(state, action: PayloadAction<discountsTabState['discounts']>) {
      state.discounts = action.payload;
    },
    setActiveDiscount(state, action: PayloadAction<discountsTabState['activeDiscount']>) {
      state.activeDiscount = action.payload;
    },
    setActiveMerchant(state, action: PayloadAction<discountsTabState['activeMerchant']>) {
      state.activeMerchant = action.payload;
    },
    setCurrentTab(state, action: PayloadAction<discountsTabState['currentTab']>) {
      state.currentTab = action.payload;
    },
    setDiscountCurrentPage(state, action: PayloadAction<number>) {
      state.discountPage.currentPage = action.payload;
    },
    setDiscountIsLastPage(state, action: PayloadAction<boolean>) {
      state.discountPage.isLastPage = action.payload;
    },
    setCoupons(state, action: PayloadAction<discountsTabState['coupons']>) {
      state.coupons = action.payload;
    },
    setCouponCurrentPage(state, action: PayloadAction<number>) {
      state.couponsPage.currentPage = action.payload;
    },
    setCouponIsLastPage(state, action: PayloadAction<boolean>) {
      state.couponsPage.isLastPage = action.payload;
    }
  }
});

export const {
  setDiscounts,
  setCoupons,
  setActiveDiscount,
  setActiveMerchant,
  setDiscountCurrentPage,
  setDiscountIsLastPage,
  setCouponCurrentPage,
  setCouponIsLastPage,
  setCurrentTab
} = discountsTabSlice.actions;

export default discountsTabSlice.reducer;

import React, { FC } from 'react';
import { Box, Divider, ListItemButton, Typography } from '@mui/material';
import 'src/components/Discounts/styles.css';
import DiscountHeader from 'src/components/DiscountHeader/DiscountHeader';
import DiscountItem from 'src/components/DiscountItem/DiscountItem';
import { getDiscountAmount } from 'src/utils/scripts/discount';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { Item } from 'src/shared/interfaces/items.interface';

interface Props {
  discount: Discount | null;
  item: Item | null;
  onClick?: (discount: Discount) => void | null;
}

const DiscountsCard: FC<Props> = ({ discount, item, onClick }) => {
  return (
    <ListItemButton
      onClick={() => {
        if (onClick && discount) onClick(discount);
      }}
      className='shadowed'
      sx={{
        boxSizing: 'border-box',
        flexDirection: 'column',
        p: 0,
        pb: '1rem',
        bgcolor: 'white',
        borderRadius: '8px',
        textAlign: 'left',
        alignItems: 'stretch',
        pointerEvents: onClick ? 'auto' : 'none'
      }}
    >
      <DiscountHeader discount={discount} gap={2} showCouponIcon />
      <Box
        mt={0.4}
        mb={2.2}
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Divider sx={{ width: '100%', position: 'absolute', flex: 1 }}></Divider>
        {discount && (
          <Box
            width='fit-content'
            boxSizing='border-box'
            maxWidth='90%'
            bgcolor='primary.main'
            py={0.2}
            px={1.4}
            borderRadius={10}
            zIndex={10}
            sx={{ alignSelf: 'center' }}
          >
            <Typography fontSize='0.9rem' fontWeight='500' color={'white'}>
              Discount {getDiscountAmount(discount)}
            </Typography>
          </Box>
        )}
      </Box>
      <DiscountItem item={item} discount={discount} gap={1} bold />
    </ListItemButton>
  );
};

export default DiscountsCard;

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

// types
import { ItemTab } from 'src/shared/enums';

//utils
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { Item } from 'src/shared/interfaces/items.interface';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

interface itemsTabState {
  items: Item[];
  activeItem: Item | null;
  activeMerchant: Merchant | null;
  currentTab: ItemTab;
  currentPage: number;
  isLastPage: boolean;
  promptInstall: Event | null;
}

const initialState: itemsTabState = {
  items: [],
  activeItem: null,
  activeMerchant: null,
  currentTab: !getSubDomain() ? ItemTab.PURCHASED : ItemTab.ALL,
  currentPage: 0,
  isLastPage: false,
  promptInstall: null
};

export const itemsTabSlice = createSlice({
  name: 'itemTab',
  initialState,

  reducers: {
    setItems(state, action: PayloadAction<itemsTabState['items']>) {
      state.items = action.payload;
    },
    setActiveItem(state, action: PayloadAction<itemsTabState['activeItem']>) {
      state.activeItem = action.payload;
    },
    setActiveMerchant(state, action: PayloadAction<itemsTabState['activeMerchant']>) {
      state.activeMerchant = action.payload;
    },
    setCurrentTab(state, action: PayloadAction<itemsTabState['currentTab']>) {
      state.currentTab = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<itemsTabState['currentPage']>) {
      state.currentPage = action.payload;
    },
    setIsLastPage(state, action: PayloadAction<itemsTabState['isLastPage']>) {
      state.isLastPage = action.payload;
    }
  }
});

export const {
  setItems,
  setActiveItem,
  setActiveMerchant,
  setCurrentTab,
  setCurrentPage,
  setIsLastPage
} = itemsTabSlice.actions;

export default itemsTabSlice.reducer;

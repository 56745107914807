import React, { useState } from 'react';

//mui
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';

//types
import { InputHelperMessage } from 'src/shared/interfaces/inputHelperMessage.interface';
import { profileTheme } from 'src/components/ProfileEditInput/profile.theme';

//images

const REGEX_NOT_DIGITS = /\D/g;

interface Props {
  type: 'text' | 'tel' | 'password';
  name: string;
  label: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  value: string | number;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validate?: (event: React.ChangeEvent<HTMLInputElement>) => InputHelperMessage;
  customValidation?: boolean;
  onClick?: () => void;
  disabledEdit?: boolean;
}

export const ProfileEditInput: React.FC<Props> = ({
  type,
  value,
  name,
  label,
  placeholder,
  helperText,
  error,
  required,
  onChange,
  validate,
  customValidation = false,
  onClick,
  disabledEdit
}) => {
  const [message, setMessage] = useState<InputHelperMessage>({
    message: helperText,
    error: false
  });

  const handleValidation = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (validate) setMessage(validate(event));
  };

  const handleClickEdit = () => {
    const elements = document.getElementsByName(name);
    if (elements.length) elements[0].focus();
  };

  const blur = () => {
    const elements = document.getElementsByName(name);
    if (elements.length) elements[0].blur();
  };

  return (
    <FormControl sx={profileTheme} variant='outlined'>
      <InputLabel
        htmlFor='outlined-adornment-password'
        error={customValidation ? error : message.error}
        shrink
      >
        {label}
      </InputLabel>
      <OutlinedInput
        className='unfocused'
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        fullWidth
        disabled={disabledEdit}
        sx={{ border: '0px' }}
        inputProps={{ style: { padding: '18px 16px' } }}
        required={required}
        endAdornment={
          !disabledEdit && (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickEdit}
                sx={{ mr: 1, height: '40px', width: '40px' }}
              >
                <BorderColorIcon />
              </IconButton>
            </InputAdornment>
          )
        }
        error={customValidation ? error : message.error}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (type == 'tel') {
            handlePhoneChange(event, value.toString(), onChange);
          } else {
            onChange(event);
          }
          handleValidation(event);
        }}
        onClick={(e) => {
          if (onClick != undefined) {
            e.preventDefault();
            e.stopPropagation();
            blur();
            onClick();
          }
        }}
      />
      {(message.message || customValidation) && (
        <FormHelperText error={customValidation ? error : message.error}>
          {customValidation ? helperText : message.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const handlePhoneChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  value: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
) => {
  const selectionStart = event.target.selectionStart;
  const formattedValue = formatPhoneNumber(event.target.value?.toString() ?? '');
  const digitsDifference =
    value.replace(REGEX_NOT_DIGITS, '').length -
    formattedValue.replace(REGEX_NOT_DIGITS, '').length;
  let nonDigitalDifference = getNonDigitalDifference(
    selectionStart,
    event.target.value,
    formattedValue
  );
  if (value.length - event.target.value.length == 1 && digitsDifference == 0) {
    if (selectionStart && selectionStart > 1) {
      for (let i = selectionStart - 1; i >= 0; i--) {
        nonDigitalDifference--;
        if (/\d/.test(event.target.value[i])) {
          event.target.value = event.target.value.slice(0, i) + event.target.value.slice(i + 1);
          break;
        }
      }
    }
  }
  event.target.value = formatPhoneNumber(event.target.value?.toString() ?? '');
  if (selectionStart) {
    event.target.selectionStart = Math.max(selectionStart + nonDigitalDifference, 0);
    event.target.selectionEnd = Math.max(selectionStart + nonDigitalDifference, 0);
  }
  onChange(event);
};

const formatPhoneNumber = (phoneNumber: string): string => {
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  const regExp =
    cleanedPhoneNumber.length > 10 ? /^(\d{1})(\d{3})(\d{3})(\d{4})$/ : /^(\d{3})(\d{3})(\d{4})$/;

  const match = cleanedPhoneNumber.match(regExp);

  if (!match) return phoneNumber;

  return cleanedPhoneNumber.length > 10
    ? `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
    : `(${match[1]}) ${match[2]}-${match[3]}`;
};

function getNonDigitalDifference(
  selectionStart: number | null,
  value: string,
  formattedValue: string
) {
  let difference = 0;
  for (let i = 0; i < (selectionStart ?? value.length); i++) {
    const isValueNotDigit = REGEX_NOT_DIGITS.test(value[i]);
    const isFormattedValueNotDigit = REGEX_NOT_DIGITS.test(formattedValue[i + difference]);
    if (formattedValue[i + difference] == undefined) {
      difference--;
      continue;
    }
    if (
      !(isValueNotDigit && isFormattedValueNotDigit) &&
      value[i] != formattedValue[i + difference]
    ) {
      if (isValueNotDigit) {
        difference--;
      }
      if (isFormattedValueNotDigit) {
        difference++;
        i--;
      }
    }
  }
  return difference;
}

import React, { forwardRef, useImperativeHandle, useState } from 'react';

//components
import { CustomInput } from 'src/components/CustomInput/customInput';

//types
import { InputHelperMessage } from 'src/shared/interfaces/inputHelperMessage.interface';

//scripts
import { isPasswordValid } from 'src/features/inputValidation'; //constants
import { ERROR, HELPER_TEXT } from 'src/shared/consts/Messages.consts';

interface Props {
  password: string;
  confirmPassword?: string;
  passwordLabel?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface Validatable {
  validate(): boolean;
  isPasswordValid(password: string, confirmPassword?: string): boolean;
}

export const PasswordInput = forwardRef<Validatable, Props>(
  ({ password, confirmPassword, passwordLabel = 'Password', onChange }, ref) => {
    const [confirmPasswordHelperMessage, setConfirmPasswordHelperMessage] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    useImperativeHandle(ref, () => ({
      validate() {
        return validatePasswords();
      },
      isPasswordValid(password: string, confirmPassword: string) {
        return isPasswordValid(password) && password == confirmPassword;
      }
    }));

    const validatePassword = (value: string): InputHelperMessage => {
      if (!isPasswordMatch(value)) {
        setConfirmPasswordHelperMessage(ERROR.PASSWORDS_DO_NOT_MATCH);
        setConfirmPasswordError(true);
      } else {
        setConfirmPasswordHelperMessage('');
        setConfirmPasswordError(false);
      }
      const error = !isPasswordValid(value);
      const message = error ? HELPER_TEXT.PASSWORD : '';
      return { message: message, error: error };
    };

    const validateConfirmPassword = (value: string): InputHelperMessage => {
      const error = password !== '' && password !== value;
      const message = error ? ERROR.PASSWORDS_DO_NOT_MATCH : '';
      setConfirmPasswordHelperMessage(message);
      setConfirmPasswordError(error);
      return { message: message, error: error };
    };

    const isPasswordMatch = (value: string): boolean => {
      return !(value !== '' && confirmPassword !== '' && confirmPassword !== value);
    };

    const validatePasswords = (): boolean => {
      if (password !== confirmPassword) {
        setConfirmPasswordHelperMessage(ERROR.PASSWORDS_DO_NOT_MATCH);
        setConfirmPasswordError(true);
      }
      return isPasswordValid(password) && password == confirmPassword;
    };

    return (
      <>
        <CustomInput
          type='password'
          name='password'
          label={passwordLabel}
          helperText={HELPER_TEXT.PASSWORD}
          value={password}
          required
          onChange={onChange}
          validate={validatePassword}
        />
        <CustomInput
          type='password'
          name='confirmPassword'
          label='Confirm password'
          helperText={confirmPasswordHelperMessage}
          error={confirmPasswordError}
          value={confirmPassword}
          required
          onChange={onChange}
          validate={validateConfirmPassword}
          customValidation
        />
      </>
    );
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'src/store/axiosConfig';

// constants
import { DISCOUNTS_PATH, ITEMS_PATH, MERCHANTS_PATH } from 'src/shared/consts/Api.consts';

// types
import { AxiosResponse } from 'axios';
import { Page } from 'src/shared/interfaces/page.interface';
import { RequestError } from 'src/utils/types';
import { PageRequest } from 'src/shared/interfaces/pageRequest.interface';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { ItemInDiscount } from 'src/shared/interfaces/items.interface';

export const thunkDiscountsByMerchantId = createAsyncThunk<
  Page<Discount>,
  PageRequest & { merchantId: string }
>(MERCHANTS_PATH.MERCHANT_DISCOUNTS(), async ({ page, size, merchantId }, thunkAPI) => {
  try {
    const response: AxiosResponse<Page<Discount>> = await axiosInstance.get(
      MERCHANTS_PATH.MERCHANT_DISCOUNTS(merchantId)
    );

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
  }
});

export const thunkDiscounts = createAsyncThunk<Page<Discount>, PageRequest>(
  DISCOUNTS_PATH.DISCOUNTS,
  async (pagination, thunkAPI) => {
    try {
      const config = {
        params: { ...pagination, size: 15 }
      };

      const response = await axiosInstance.get(DISCOUNTS_PATH.DISCOUNTS, config);

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
    }
  }
);

export const thunkDiscountsByItemName = createAsyncThunk<
  Page<Discount>,
  PageRequest & { itemName: string }
>(DISCOUNTS_PATH.DISCOUNTS_BY_ITEM_NAME, async ({ itemName }, thunkAPI) => {
  try {
    const response = await axiosInstance.get(
      `${DISCOUNTS_PATH.DISCOUNTS_BY_ITEM_NAME}?itemName=${itemName}`
    );

    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
  }
});

export const thunkDiscountsByItemId = createAsyncThunk<
  Page<Discount>,
  { pageRequest?: PageRequest; itemId: string }
>(ITEMS_PATH.ITEM_DISCOUNTS(), async ({ pageRequest, itemId }, thunkAPI) => {
  try {
    const response = await axiosInstance.get(ITEMS_PATH.ITEM_DISCOUNTS(itemId));
    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
  }
});

export const thunkDiscount = createAsyncThunk<Discount, { id: number }>(
  DISCOUNTS_PATH.DISCOUNT(),
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.get(DISCOUNTS_PATH.DISCOUNT(String(data.id)));

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
    }
  }
);

export const thunkDiscountItems = createAsyncThunk<ItemInDiscount[], { id: number }>(
  DISCOUNTS_PATH.DISCOUNT_ITEMS(),
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.get(DISCOUNTS_PATH.DISCOUNT_ITEMS(String(data.id)));
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
    }
  }
);

import React from 'react';
import { Button, SvgIcon } from '@mui/material';
import { ReactComponent as LocationMarker } from 'src/images/location_marker.svg';
import { ButtonProps } from '@mui/material/Button/Button';

export default function LocationButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      variant='outlined'
      color={'secondary'}
      startIcon={<SvgIcon color={'inherit'} component={LocationMarker} inheritViewBox />}
      sx={{
        width: 36,
        height: 36,
        borderRadius: '8px',
        minWidth: 0,
        '& .MuiButton-startIcon': { margin: 0 },
        zIndex: 'fab',
        flexShrink: 0,
        pointerEvents: 'auto'
      }}
    />
  );
}

LocationButton.muiName = 'Button';

import { RefObject, useEffect, useRef } from 'react';

export const usePaginationObserver = (
  ref: RefObject<HTMLDivElement>,
  canLoad: boolean,
  onIntersecting: () => void
) => {
  const observer = useRef<IntersectionObserver>();
  useEffect(() => {
    if (!canLoad) return;
    if (observer.current) observer.current?.disconnect();
    if (ref.current) {
      const callback = function (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
      ) {
        if (entries[0].isIntersecting) {
          onIntersecting();
        }
      };
      observer.current = new IntersectionObserver(callback);
      observer.current.observe(ref.current);
    }
  }, [canLoad]);
};

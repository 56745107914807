import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'src/store/axiosConfig';

// constants
import { ORDER_PATH } from 'src/shared/consts/Api.consts';

// types
import { RequestError } from 'src/utils/types';
import { AxiosResponse } from 'axios';
import { Page } from 'src/shared/interfaces/page.interface';
import { PageRequest } from 'src/shared/interfaces/pageRequest.interface';
import { Order, OrderCreationDto } from 'src/shared/interfaces/order.interface';

export const thunkCreateOrder = createAsyncThunk<Order, OrderCreationDto>(
  ORDER_PATH.ORDERS + '_ADD',
  async (orderCreationDto, thunkAPI) => {
    try {
      const response: AxiosResponse<Order> = await axiosInstance.post(
        `${ORDER_PATH.ORDERS}`,
        orderCreationDto
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);
export const thunkUpdateOrder = createAsyncThunk<Order, OrderCreationDto>(
  ORDER_PATH.ORDERS_DISCOUNT,
  async (orderInfo, thunkAPI) => {
    try {
      const response: AxiosResponse<Order> = await axiosInstance.post(
        `${ORDER_PATH.ORDERS_DISCOUNT}`,
        orderInfo
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkOrders = createAsyncThunk<Page<Order>, PageRequest>(
  ORDER_PATH.ORDERS + '_GET',
  async (pagination, thunkAPI) => {
    try {
      const config = { params: { ...pagination } };
      const response: AxiosResponse<Page<Order>> = await axiosInstance.get(
        `${ORDER_PATH.ORDERS}`,
        config
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkOrder = createAsyncThunk<Order, { id: string }>(
  ORDER_PATH.ORDER + '_GET',
  async ({ id }, thunkAPI) => {
    try {
      const response: AxiosResponse<Order> = await axiosInstance.get(`${ORDER_PATH.ORDER(id)}`);

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkCancelOrder = createAsyncThunk<Order, { id: string }>(
  ORDER_PATH.CANCEL_ORDER + '_GET',
  async ({ id }, thunkAPI) => {
    try {
      const response: AxiosResponse<Order> = await axiosInstance.patch(
        `${ORDER_PATH.CANCEL_ORDER(id)}`
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

import React from 'react';
import { List } from '@mui/material';
import DiscountsListItem from 'src/components/Discounts/discount/DiscountsListItem';
import { Discount } from 'src/shared/interfaces/discount.interface';

interface Props {
  discounts: Discount[] | null[];
  showProductAmount?: boolean;
  showMerchantName?: boolean;

  onDiscountClick?(discount: Discount): void;
}

const DiscountList: React.FC<Props> = ({
  discounts,
  showProductAmount,
  showMerchantName,
  onDiscountClick = () => {}
}) => {
  return (
    <List disablePadding>
      {discounts.map((discount, index) =>
        discount ? (
          <DiscountsListItem
            key={discount.id}
            discount={discount}
            onClick={() => onDiscountClick(discount)}
            showProductAmount={showProductAmount}
            showMerchantName={showMerchantName}
          />
        ) : (
          <DiscountsListItem key={index} discount={discount} />
        )
      )}
    </List>
  );
};

export default DiscountList;

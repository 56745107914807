import React from 'react';

//mui
import { Box, Stack, Typography } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';

const AppInstallInstructionIOS = () => {
  return (
    <Stack gap={1}>
      <Typography>Install the app on your device to easily access it anytime.</Typography>
      <Box sx={{ display: 'flex' }}>
        <Typography>1. Tap on </Typography>
        <IosShareIcon sx={{ px: 0.5 }} />
      </Box>
      <Typography>
        2. Select <strong> Add to Home Screen </strong>
      </Typography>
    </Stack>
  );
};

export default AppInstallInstructionIOS;

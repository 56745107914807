import React, { FC } from 'react';
import { Box, Divider, ListItem, ListItemButton, Skeleton, Typography } from '@mui/material';
import { Item } from 'src/shared/interfaces/items.interface';
import DiscountItem from 'src/components/DiscountItem/DiscountItem';
import IF from 'src/components/IF';
import { getSubDomain } from 'src/utils/scripts/subdomain';

interface Props {
  item: Item | null;
  onClick?(): void;
}

const ItemsListItem: FC<Props> = ({ item, onClick = () => {} }) => {
  const subdomain = getSubDomain();

  return (
    <>
      {item ? (
        <ListItem
          className={'item'}
          sx={{
            width: 'auto',
            height: 'fit-content',
            flexDirection: 'column',
            borderRadius: '8px',
            p: 0
          }}
        >
          <ListItemButton
            dense
            sx={{ width: '100%', px: 0, py: 1.25, borderRadius: '8px', bgcolor: 'white' }}
            onClick={() => onClick()}
            disabled={!item.isActive || (!!subdomain && !item.eStore)}
          >
            <DiscountItem item={item} />
          </ListItemButton>
          <IF condition={!item.isActive || (!!subdomain && !item.eStore)}>
            <Box width='100%' pb={0.5} boxSizing='border-box'>
              <Divider />
              <Typography color='text.disabled' sx={{ px: 2, pt: 0.5, userSelect: 'none' }}>
                Not active
              </Typography>
            </Box>
          </IF>
        </ListItem>
      ) : (
        <Skeleton variant='rounded' width='auto' height={'4rem'} sx={{ mx: 2, my: 1 }} />
      )}
    </>
  );
};

export default ItemsListItem;

import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  input: string;
  autocomplete: string;
}

const AutocompleteTopography: React.FC<Props> = ({ input, autocomplete }) => {
  const autocompleteLowerCase = autocomplete.toLowerCase();
  const inputLowerCase = input.toLowerCase();
  const indexInputStart = autocompleteLowerCase.indexOf(inputLowerCase);
  const indexInputEnd = indexInputStart < 0 ? 0 : indexInputStart + input.length;

  return (
    <Typography
      variant='body1'
      color='text.disabled'
      sx={{ fontWeight: 'bold', pt: 0.5, pb: 0.5, maxWidth: '100%', overflowWrap: 'break-word' }}
    >
      {autocomplete.substring(0, indexInputStart)}
      <Box component='span' color='text.primary'>
        {autocomplete.substring(indexInputStart, indexInputEnd)}
      </Box>
      {autocomplete.substring(indexInputEnd, autocomplete.length)}
    </Typography>
  );
};

export default AutocompleteTopography;

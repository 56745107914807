import {
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import { Coupon } from 'src/shared/interfaces/coupon.interface';
import React, { useState } from 'react';
import { CouponExpirationTitle } from 'src/shared/enums/coupon.enum';
import Modal from 'src/components/Modal/Modal';

interface Props {
  item: Coupon;
}
const CouponItem: React.FC<Props> = ({ item }) => {
  const { numberOfVisitsLeft } = item.userNumberOfVisits;
  const progress = (numberOfVisitsLeft / item.numberOfVisitsRequired) * 100;
  const merchantName = item.items[0].merchantName;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const displayedItems = item.items.length < 2 ? item.items : item.items.slice(0, 2);
  return (
    <Box sx={{ bgcolor: '#fff', m: '8px 16px' }} p={3} border='1px solid #ccc' borderRadius='8px'>
      <Stack direction='column' spacing={2}>
        <Typography variant='h5'>{merchantName}</Typography>
        <Typography variant='subtitle1'>
          Discount: ${(item.discountAmount / 100).toFixed(2)} off the entire order
        </Typography>

        <Typography variant='body2'>
          Visits progress: {numberOfVisitsLeft}/{item.numberOfVisitsRequired}
        </Typography>
        <LinearProgress variant='determinate' value={progress} />

        <Typography variant='h6'>Items associated with this coupon:</Typography>

        <List>
          {displayedItems.map((item) => (
            <ListItem key={item.id}>
              <ListItemText
                primary={item.name}
                secondary={`Price: $${(item.price / 100).toFixed(2)}`}
              />
            </ListItem>
          ))}
        </List>
        {item.items.length > 2 && (
          <>
            <Button variant='contained' onClick={() => setIsModalOpen(true)}>
              Show more
            </Button>
            <Modal
              isOpen={isModalOpen}
              onClose={() => {
                setIsModalOpen(false);
              }}
              sx={{
                bgcolor: '#F2F2F2',
                height: '70vh',
                width: '90%',
                overflowY: 'auto'
              }}
            >
              <List>
                {item.items.map((item) => (
                  <ListItem
                    className='shadowed'
                    key={item.id}
                    sx={{
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      bgcolor: '#fff',
                      mb: '8px'
                    }}
                  >
                    <ListItemText
                      primary={item.name}
                      secondary={`Price: $${(item.price / 100).toFixed(2)}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Modal>
          </>
        )}

        <Typography variant='body2' color='textSecondary'>
          Coupon expiration:{' '}
          {item.couponExpiration === 'NEVER_EXPIRES'
            ? CouponExpirationTitle.NEVER_EXPIRES
            : CouponExpirationTitle.EXPIRES}
        </Typography>
      </Stack>
    </Box>
  );
};

export default CouponItem;

import React, { useEffect, useState } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { DayOfWeek } from 'src/shared/enums';
import CustomPopover from 'src/components/CustomPovower/CustomPopover';
import { ReactComponent as InfoIcon } from 'src/images/info_icon.svg';
import 'src/components/MerchantOpeningHours/styles.css';
import { Merchant, MerchantOpeningHours } from 'src/shared/interfaces/merchant.interface';

interface Props {
  merchant: Merchant;
  container: HTMLElement | undefined;
}

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
export const MerchantOpeningHoursContent: React.FC<Props> = ({ merchant, container }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [merchantCloses, setMerchantCloses] = useState<string>('');

  useEffect(() => {
    setMerchantCloses(getMerchantCloses());
  }, [merchant.openingHours]);

  function getOpeningHoursByDayOfWeek(dayOfWeek: DayOfWeek): MerchantOpeningHours[] {
    return merchant.openingHours
      .filter((it) => it.dayOfWeek.toUpperCase() == dayOfWeek)
      .sort((a, b) => a.startTime - b.startTime);
  }

  function formatOpeningHours(openingHours: MerchantOpeningHours): string {
    const startTime = formatTime(openingHours.startTime);
    const endTime = formatTime(openingHours.endTime);
    return `${startTime}-${endTime}`;
  }

  function formatTime(time: number): string {
    const hours = getHours(time);
    const minutes = getMinutes(time);
    return `${formatNumber(hours)}:${formatNumber(minutes)}`;
  }

  function formatNumber(n: number): string {
    return n >= 10 ? n.toString() : '0' + n;
  }

  function getHours(seconds: number) {
    return Math.floor(seconds / SECONDS_IN_HOUR);
  }

  function getMinutes(seconds: number) {
    const minutesInSeconds = seconds % SECONDS_IN_HOUR;
    return Math.floor(minutesInSeconds / SECONDS_IN_MINUTE);
  }

  function getMerchantCloses(): string {
    const now = new Date();
    const dayOfWeek = Object.values(DayOfWeek)[now.getDay()];
    const openingHours = getOpeningHoursByDayOfWeek(dayOfWeek);
    const currentSeconds = now.getHours() * SECONDS_IN_HOUR + now.getMinutes() * SECONDS_IN_MINUTE;
    const currentOpeningHours = openingHours.filter(
      (it) => it.startTime <= currentSeconds && currentSeconds <= it.endTime
    );
    if (!currentOpeningHours.length) return getMerchantOpensByNow(now);
    return `It closes ${formatTime(currentOpeningHours[0].endTime)}`;
  }

  function getMerchantOpensByNow(now: Date): string {
    const nextDay = new Date(now);
    nextDay.setDate(nextDay.getDate() + 1);
    while (nextDay.getDay() != now.getDay()) {
      const merchantOpens = getMerchantOpensByDate(nextDay);
      if (merchantOpens) return merchantOpens;
      nextDay.setDate(nextDay.getDate() + 1);
    }
    return 'It closed';
  }

  function getMerchantOpensByDate(date: Date): string | null {
    const dayOfWeek = Object.values(DayOfWeek)[date.getDay()];
    const openingHours = getOpeningHoursByDayOfWeek(dayOfWeek);
    if (!openingHours.length) return null;
    return `It opens ${capitalize(dayOfWeek.toLowerCase())} ${formatTime(
      openingHours[0].startTime
    )}`;
  }

  function capitalize(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  return (
    <Box
      sx={{
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        py: 1
      }}
    >
      <Typography variant={'body2'}>{merchantCloses}</Typography>

      <CustomPopover
        isOpen={isPopoverOpen}
        positions={['top', 'bottom', 'right', 'left']}
        align={'end'}
        padding={2}
        parentElement={container}
        onClickOutside={() => setIsPopoverOpen(false)}
        content={
          <Stack
            className='opening-hours'
            maxHeight={container ? container.clientHeight * 0.9 : 'auto'}
            gap={1.5}
            sx={{
              p: 1,
              bgcolor: 'white',
              borderRadius: '8px',
              overflowY: 'scroll',
              boxSizing: 'content-box'
            }}
          >
            {Object.values(DayOfWeek).map((dayOfWeek) => (
              <Stack key={dayOfWeek} direction='row' justifyContent='space-between' gap={4}>
                <Typography variant='body2' sx={{ textTransform: 'capitalize' }}>
                  {dayOfWeek.toLowerCase()}
                </Typography>
                <Stack gap={0.5}>
                  {getOpeningHoursByDayOfWeek(dayOfWeek).length ? (
                    <>
                      {getOpeningHoursByDayOfWeek(dayOfWeek).map((openingHours, index) => (
                        <Typography key={index} variant='body2'>
                          {formatOpeningHours(openingHours)}
                        </Typography>
                      ))}
                    </>
                  ) : (
                    <Typography variant='body2'>closed</Typography>
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
        }
      >
        <IconButton sx={{ px: 0.5, py: 0 }} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
          <InfoIcon />
        </IconButton>
      </CustomPopover>
    </Box>
  );
};

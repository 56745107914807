import React, { FC } from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

//component
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { AUTH_ROUTES } from 'src/shared/consts/Rout.consts';

interface Props {
  header: string;
}

const LoginToContinue: FC<Props> = ({ header }) => {
  const navigate = useNavigate();

  return (
    <Stack sx={{ height: '100%', gap: 2, justifyContent: 'center' }}>
      <Typography variant='h4'>{header}</Typography>
      <Button
        type='submit'
        variant='contained'
        sx={{ height: '48px' }}
        onClick={() => navigate(AUTH_ROUTES.LOGIN)}
      >
        Log In
      </Button>
      <Box display='flex' flexDirection='column' width='100%'>
        <Link style={{ cursor: 'pointer' }} component={ReactRouterLink} to={AUTH_ROUTES.REGISTER}>
          {"Don't have an account? Sign up"}
        </Link>
      </Box>
    </Stack>
  );
};

export default LoginToContinue;

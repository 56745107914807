import { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MERCHANTS_PATH, USERS_PATH } from 'src/shared/consts/Api.consts';
import { RequestError } from 'src/utils/types';
import {
  UserPasswordUpdate,
  UserProfile,
  UserUpdate
} from 'src/store/interfaces/users/userProfile';
import { axiosInstance } from 'src/store/axiosConfig';
import { Page } from 'src/shared/interfaces/page.interface';
import { PageRequest } from 'src/shared/interfaces/pageRequest.interface';
import { Merchant } from 'src/shared/interfaces/merchant.interface';

export const thunkUsersCurrent = createAsyncThunk<UserProfile, { token: string }>(
  USERS_PATH.USERS_CURRENT,
  async (data, thunkAPI) => {
    try {
      const response: AxiosResponse<UserProfile> = await axiosInstance.get(
        USERS_PATH.USERS_CURRENT
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkValidateOldPassword = createAsyncThunk<boolean, { password: string }>(
  USERS_PATH.VALIDATE_PASSWORD,
  async (params, thunkAPI) => {
    try {
      const config = {
        params: { ...params }
      };

      const response: AxiosResponse<boolean> = await axiosInstance.get(
        USERS_PATH.VALIDATE_PASSWORD,
        config
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkUserUpdate = createAsyncThunk<null, UserUpdate>(
  USERS_PATH.USERS,
  async (data, thunkAPI) => {
    try {
      const body = { ...data };

      const response: AxiosResponse<null> = await axiosInstance.post(USERS_PATH.USERS, body);

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkUserPasswordUpdate = createAsyncThunk<null, UserPasswordUpdate>(
  USERS_PATH.PASSWORD,
  async (data, thunkAPI) => {
    try {
      const body = { ...data };

      const response: AxiosResponse<null> = await axiosInstance.patch(USERS_PATH.PASSWORD, body);

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkUsersMerchants = createAsyncThunk<Page<Merchant>, PageRequest>(
  MERCHANTS_PATH.MERCHANTS,
  async (pagination, thunkAPI) => {
    try {
      const config = {
        params: { ...pagination }
      };

      const response: AxiosResponse<Page<Merchant>> = await axiosInstance.get(
        MERCHANTS_PATH.MERCHANTS,
        config
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RequestError } from 'src/utils/types';
import { AUTH_PATH } from 'src/shared/consts/Api.consts';
import { PostVerificationTokenDto, RegistrationInfo } from 'src/store/interfaces/auth';
import { axiosInstance } from 'src/store/axiosConfig';

interface RegisterDto {
  phoneNumber?: string;
  email?: string;
  fullName?: string;
  password: string;
  token?: string;
}

interface LoginDto {
  email?: string;
  password: string;
}

export const thunkRegister = createAsyncThunk<
  AxiosResponse,
  RegisterDto,
  {
    dispatch: Dispatch;
  }
>(AUTH_PATH.REGISTRATION, async (data, thunkAPI) => {
  const body = {
    fullName: data.fullName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    password: data.password
  };
  try {
    const response = await axiosInstance.post(AUTH_PATH.REGISTRATION, body);

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

export const thunkLogin = createAsyncThunk<
  AxiosResponse,
  LoginDto,
  {
    dispatch: Dispatch;
  }
>(AUTH_PATH.LOGIN, async (data, thunkAPI) => {
  // todo refactor
  const body: any = {
    email: data.email,
    password: data.password
  };
  try {
    const response = await axiosInstance.post(AUTH_PATH.LOGIN, body);

    return thunkAPI.fulfillWithValue(response.data.accessToken);
  } catch (e) {
    const error = e as RequestError;
    return thunkAPI.rejectWithValue({
      response: { data: { error: error.response.data.error }, status: error.response.status }
    });
  }
});

export const thunkRegisterByMerchant = createAsyncThunk<
  AxiosResponse,
  RegisterDto,
  {
    dispatch: Dispatch;
  }
>(AUTH_PATH.REGISTRATION_BY_MERCHANT, async (data, thunkAPI) => {
  const body = {
    fullName: data.fullName,
    email: data.email,
    phoneNumber: data.phoneNumber,
    password: data.password,
    merchantRegistrationToken: data.token
  };
  try {
    const response = await axiosInstance.post(AUTH_PATH.REGISTRATION_BY_MERCHANT, body);

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

export const thunkVerifyByEmail = createAsyncThunk<
  PostVerificationTokenDto,
  {
    token: string;
  }
>(AUTH_PATH.VERIFY_BY_EMAIL, async (data, thunkAPI) => {
  try {
    const response = await axiosInstance.get(`${AUTH_PATH.VERIFY_BY_EMAIL}?token=${data.token}`);

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

export const thunkResendEmailVerification = createAsyncThunk<
  string,
  {
    token: string;
  }
>(AUTH_PATH.RESEND_VERIFICATION, async (data, thunkAPI) => {
  try {
    // todo refactor
    // @ts-ignore
    const response = await axiosInstance.post(
      `${AUTH_PATH.RESEND_VERIFICATION}?token=${data.token}`
    );

    return thunkAPI.fulfillWithValue(response.data.accessToken);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

export const thunkSendEmailVerification = createAsyncThunk<
  string,
  {
    email: string;
  }
>(AUTH_PATH.SEND_VERIFICATION, async (data, thunkAPI) => {
  try {
    // todo refactor
    const body: any = {
      email: data.email
    };

    const response = await axiosInstance.post(AUTH_PATH.SEND_VERIFICATION, body);

    return thunkAPI.fulfillWithValue(response.data.accessToken);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

export const thunkRegistrationInfo = createAsyncThunk<
  RegistrationInfo,
  {
    token: string;
  }
>(AUTH_PATH.GET_REGISTRATION_INFO, async (data, thunkAPI) => {
  try {
    const response: AxiosResponse<RegistrationInfo> = await axiosInstance.get(
      `${AUTH_PATH.GET_REGISTRATION_INFO}?token=${data.token}`
    );

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

export const thunkPasswordResetEmail = createAsyncThunk<
  AxiosResponse,
  {
    email: string;
  }
>(AUTH_PATH.PASSWORD_RESET_EMAIL, async (data, thunkAPI) => {
  try {
    // todo refactor
    // @ts-ignore
    const response = await axiosInstance.post(
      `${AUTH_PATH.PASSWORD_RESET_EMAIL}?email=${data.email}`
    );

    return thunkAPI.fulfillWithValue(response.data.accessToken);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

export const thunkResetPassword = createAsyncThunk<
  AxiosResponse,
  {
    token: string;
    password: string;
  }
>(AUTH_PATH.RESET_PASSWORD, async (data, thunkAPI) => {
  try {
    // todo refactor
    const body: any = {
      token: data.token,
      password: data.password
    };

    const response = await axiosInstance.patch(AUTH_PATH.RESET_PASSWORD, body);

    return thunkAPI.fulfillWithValue(response.data.accessToken);
  } catch (e) {
    const error = e as RequestError;

    return thunkAPI.rejectWithValue(error.response.data.error);
  }
});

import React, { useEffect, useRef, useState } from 'react';
// redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
// mui
import { Box, Stack, Tab, Tabs } from '@mui/material';
import {
  setActiveMerchant,
  setCouponCurrentPage,
  setCouponIsLastPage,
  setCoupons,
  setCurrentTab,
  setDiscountCurrentPage,
  setDiscountIsLastPage,
  setDiscounts
} from 'src/store/slices/discountsTabSlice';
import TabContent from 'src/components/Tabs/TabsContent';
// components
import MerchantFilterSearchBar from 'src/components/MerchantFilterSearchBar/MerchantFilterSearchBar';
import SearchBar from 'src/components/SearchBar/SearchBar';
import Discounts from 'src/components/Discounts/discount/Discounts';
import { thunkDiscounts, thunkMerchantsCoupons, thunkMerchantsDiscounts } from 'src/store/thunks';
import { Page } from 'src/shared/interfaces/page.interface';
import styles from 'src/pages/Discounts/discount.module.css';
import CouponList from 'src/components/Discounts/coupons/CouponList';
import { CouponsTab } from 'src/shared/enums';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { Merchant } from 'src/shared/interfaces/merchant.interface';
import { Coupon } from 'src/shared/interfaces/coupon.interface';
import { thunkCoupons } from 'src/store/thunks/coupons';
import IF from 'src/components/IF';
import { getAccessToken } from 'src/utils/scripts';

function DiscountsPage() {
  const dispatch = useAppDispatch();
  const accessToken = getAccessToken();

  const discounts = useAppSelector((state) => state.discountsTab.discounts);
  const coupons = useAppSelector((state) => state.discountsTab.coupons);
  const currentTab = useAppSelector((state) => state.discountsTab.currentTab);
  const currentMerchant = useAppSelector((state) => state.discountsTab.activeMerchant);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isLoadingDiscount, setIsLoadingDiscount] = useState(true);
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(true);
  const [merchantID, setMerchantID] = useState<number | null>(null);

  const discountsRef = useRef(discounts);
  const couponsRef = useRef(coupons);

  useEffect(() => {
    discountsRef.current = discounts;
  }, [discounts]);

  useEffect(() => {
    couponsRef.current = coupons;
  }, [coupons]);

  useEffect(() => {
    if (currentTab === CouponsTab.DISCOUNT) {
      if (!discounts || discounts.length === 0) {
        fetchDiscounts(0).then();
      }
    } else {
      if (!!accessToken && (!coupons || coupons.length === 0)) {
        fetchCoupons(0).then();
      }
    }
  }, [currentTab]);

  const handleTabChange = (value: CouponsTab) => {
    dispatch(setCurrentTab(value));
  };

  const fetchDiscounts = async (pageNumber: number) => {
    setIsLoadingDiscount(true);
    const response = await dispatch(thunkDiscounts({ page: pageNumber, sort: 'name' }));
    const { content, last } = response.payload as Page<Discount>;
    dispatch(setDiscountIsLastPage(last));
    dispatch(setDiscounts(pageNumber === 0 ? content : [...discountsRef.current, ...content]));
    setIsLoadingDiscount(false);
  };

  const fetchCoupons = async (pageNumber: number) => {
    setIsLoadingCoupon(true);
    const response = await dispatch(thunkCoupons({ page: pageNumber }));
    const { content, last } = response.payload as Page<Coupon>;
    dispatch(setCouponIsLastPage(last));
    dispatch(setCoupons(pageNumber === 0 ? content : [...couponsRef.current, ...content]));
    setIsLoadingCoupon(false);
  };

  const fetchDiscountsByMerchantId = async (merchantId: number, pageNumber: number) => {
    setIsLoadingDiscount(true);
    const response = await dispatch(
      thunkMerchantsDiscounts({
        pagination: { page: pageNumber, sort: 'name' },
        merchantId: merchantId.toString()
      })
    );
    const { content, last } = response.payload as Page<Discount>;
    dispatch(setDiscountIsLastPage(last));
    dispatch(setDiscounts(pageNumber === 0 ? content : [...discountsRef.current, ...content]));
    setIsLoadingDiscount(false);
  };

  const fetchCouponsByMerchantId = async (merchantId: number, pageNumber: number) => {
    setIsLoadingCoupon(true);
    const response = await dispatch(
      thunkMerchantsCoupons({
        pagination: { page: pageNumber },
        merchantId: merchantId.toString()
      })
    );
    const { content, last } = response.payload as Page<Coupon>;
    dispatch(setCouponIsLastPage(last));
    dispatch(setCoupons(pageNumber === 0 ? content : [...couponsRef.current, ...content]));
    setIsLoadingCoupon(false);
  };

  const filterDiscountsByMerchant = (merchant: Merchant | null) => {
    setMerchantID(merchant?.id || null);
    if (merchant?.id !== currentMerchant?.id) {
      if (currentTab === CouponsTab.DISCOUNT) {
        dispatch(setDiscounts([]));
        dispatch(setDiscountCurrentPage(0));
        if (merchant?.id) {
          fetchDiscountsByMerchantId(merchant.id, 0).then();
        } else {
          fetchDiscounts(0).then();
        }
      } else {
        dispatch(setCoupons([]));
        dispatch(setCouponCurrentPage(0));
        if (merchant?.id) {
          fetchCouponsByMerchantId(merchant.id, 0).then();
        } else {
          fetchCoupons(0).then();
        }
      }
    }
    dispatch(setActiveMerchant(merchant));
  };

  const loadPageDiscounts = (pageNumber: number) => {
    dispatch(setDiscountCurrentPage(pageNumber));
    if (merchantID) {
      fetchDiscountsByMerchantId(merchantID, pageNumber).then();
      return;
    }
    fetchDiscounts(pageNumber).then();
  };

  const loadPageCoupons = (pageNumber: number) => {
    if (!!accessToken) {
      dispatch(setCouponCurrentPage(pageNumber));
      fetchCoupons(pageNumber).then();
    }
  };

  return (
    <Stack height='100%'>
      {isSearchOpen ? (
        <SearchBar onClose={() => setIsSearchOpen(false)} isOpenByDefault />
      ) : (
        <MerchantFilterSearchBar
          header='Discounts'
          currentMerchant={currentMerchant}
          onSearchSelected={() => setIsSearchOpen(true)}
          onMerchantSelect={filterDiscountsByMerchant}
        />
      )}
      <Box>
        <IF condition={!!accessToken}>
          <Tabs
            value={currentTab}
            variant='fullWidth'
            onChange={(event, value: CouponsTab) => handleTabChange(value)}
            centered
          >
            {Object.values(CouponsTab).map((tab) => (
              <Tab key={tab} value={tab} label={tab} />
            ))}
          </Tabs>
        </IF>
      </Box>

      <TabContent<CouponsTab>
        className={styles.discountTab}
        value={currentTab}
        index={CouponsTab.DISCOUNT}
      >
        <Discounts loadPage={loadPageDiscounts} isLoading={isLoadingDiscount} />
      </TabContent>
      <IF condition={!!accessToken}>
        <TabContent<CouponsTab>
          className={styles.discountTab}
          value={currentTab}
          index={CouponsTab.COUPONS}
        >
          <CouponList loadPage={loadPageCoupons} isLoading={isLoadingCoupon} />
        </TabContent>
      </IF>
    </Stack>
  );
}

export default DiscountsPage;

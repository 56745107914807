import React, { useCallback, useEffect } from 'react';
//Components
import { Alert, AlertTitle } from '@mui/material';
//Types
import { closeAlert, IAlert } from 'src/store/slices/mainSlice';
//Hooks
import { useAppDispatch } from 'src/hooks/hooks';

interface Props {
  alert: IAlert;
}

export const SingleAlert: React.FC<Props> = ({ alert }) => {
  const dispatch = useAppDispatch();
  const getTitle = () => {
    switch (alert.type) {
      case 'error':
        return 'Error';
      case 'warning':
        return 'Warning';
      case 'info':
        return 'Info';
      case 'success':
        return 'Success';
      default:
        return 'Success';
    }
  };
  const title = getTitle();
  const handleCloseError = useCallback(() => {
    dispatch(closeAlert(alert.uuid));
  }, [alert.uuid, dispatch]);

  useEffect(() => {
    const timeout = setTimeout(handleCloseError, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [handleCloseError]);

  return (
    <Alert key={alert.uuid} variant='outlined' severity={alert.type} onClose={handleCloseError}>
      <AlertTitle>
        <b>{title}</b>
      </AlertTitle>
      {alert.text}
    </Alert>
  );
};

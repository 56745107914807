import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//components
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import CustomAppBar from 'src/components/CustomAppBar/CustomAppBar';
import BackButton from 'src/components/BackButton/BackButton';
import MerchantListItem from 'src/components/Merchants/MerchantListItem';
import OrdersState from 'src/components/OrderState';
import OrderItemsList from 'src/components/OrderItems/OrderItemsList';
import IF from 'src/components/IF';
import CancelOrderButton from 'src/components/CancelOrderButton';

//redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { thunkOrder } from 'src/store/thunks';
import { setActiveOrder, setOrders } from 'src/store/slices/ordersSlice';

//types
import { OrderState } from 'src/shared/enums';

//enums
import { RequestStatuses } from 'src/utils/enums';

//constants
import { ITEMS_ROUTES, MERCHANTS_ROUTES } from 'src/shared/consts/Rout.consts';
import { API_URL } from 'src/config';
import { ORDER_PATH } from 'src/shared/consts/Api.consts';

//scripts
import { getDiscounts, getSubtotalFormatted, getTotalFormatted } from 'src/utils/scripts/orders';
import { formatItemPrice } from 'src/utils/scripts/items';
import { setActiveItem } from 'src/store/slices/itemsTabSlice';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { getAccessToken } from 'src/utils/scripts';
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { Merchant } from 'src/shared/interfaces/merchant.interface';
import { Order, OrderItem } from 'src/shared/interfaces/order.interface';

const OrderPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const order = useAppSelector((state) => state.orders.activeOrder);
  const orders = useAppSelector((state) => state.orders.orders);
  const eventAbortController = new AbortController();
  const accessToken = getAccessToken();
  const eStoreSubdomain = getSubDomain();
  const [discounts, setDiscounts] = useState(0);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!order || order.id.toString() != id!) {
      fetchOrder();
    }
    return () => {
      eventAbortController?.abort();
    };
  }, []);

  useEffect(() => {
    if (order && !eventAbortController && accessToken) {
      subscribeForOrderUpdate(order);
    }
  }, [order]);

  async function fetchOrder() {
    const response = await dispatch(thunkOrder({ id: id! }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      dispatch(setActiveOrder(response.payload as Order));
    }
  }

  const openMerchant = (merchant: Merchant) => {
    navigate(MERCHANTS_ROUTES.MERCHANT.replace(':id', merchant.id.toString()));
  };

  const openItem = (orderItem: OrderItem) => {
    dispatch(setActiveItem(orderItem.item));
    navigate(ITEMS_ROUTES.ITEM.replace(':id', orderItem.item.id.toString()));
  };

  function subscribeForOrderUpdate(order: Order) {
    const url = `${API_URL}/${ORDER_PATH.ORDER_EVENTS(order.id.toString())}`;
    const headers = {
      Authorization: 'Bearer ' + accessToken,
      'X-SUBDOMAIN': eStoreSubdomain ?? ''
    };

    const fetchData = async () => {
      await fetchEventSource(url, {
        method: 'GET',
        headers: headers,
        signal: eventAbortController.signal,
        openWhenHidden: true,
        onmessage(event) {
          if (!event.data) {
            return;
          }
          try {
            const parsedData = JSON.parse(event.data.trim());
            const result = parsedData as Order;
            updateOrder(result);
          } catch (error) {}
        },
        onclose() {
          eventAbortController.abort();
        }
      });
    };
    fetchData();
  }

  function updateOrder(updatedOrder: Order) {
    dispatch(setActiveOrder(updatedOrder));
    if (order != updatedOrder) {
      const index = orders.findIndex((it) => it.id == updatedOrder.id);
      if (index != -1) {
        const currentOrder = Object.assign({}, orders[index]);
        currentOrder.state = updatedOrder.state;
        dispatch(setOrders([...orders.slice(0, index), currentOrder, ...orders.slice(index + 1)]));
      }
    }
  }

  useEffect(() => {
    if (order) {
      setDiscounts(getDiscounts(order));
    }
  }, [order]);

  return (
    <Stack
      width='100%'
      height='100%'
      justifyContent='space-between'
      sx={{
        bgcolor: (theme) => theme.palette.light.main
      }}
    >
      <CustomAppBar
        toolbar={
          <>
            <BackButton onClick={() => goBack()} />
            <Typography variant='h4' color='text.primary' noWrap>
              Order
            </Typography>
            <Box width={36} />
          </>
        }
      >
        <MerchantListItem
          component='div'
          merchant={order?.merchant ?? null}
          divider={false}
          onSecondaryClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            if (order?.merchant) {
              openMerchant(order.merchant);
            }
          }}
        />
        <Divider variant='middle' />
        {!!order ? (
          <>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              gap={2}
              pt={1.4}
              pb={1}
              px={2}
            >
              <Stack direction='row' alignItems='center' gap={2}>
                <Typography variant='body1' color='text.secondary'>
                  ID:
                </Typography>
                <Typography variant='h6' fontWeight={600} textAlign='right'>
                  {order.externalId}
                </Typography>
              </Stack>
              <OrdersState orderState={order.state} />
            </Stack>
          </>
        ) : (
          <Skeleton sx={{ mx: 1.5, my: 1.5 }} />
        )}
      </CustomAppBar>
      {!!order && (
        <>
          <Box flex={1} overflow='auto'>
            <OrderItemsList orderItems={order.items} onClick={openItem} />
          </Box>
          <Stack bgcolor='white' sx={{ boxShadow: 4, zIndex: 100 }}>
            <Stack alignItems='stretch' gap={0.5} p={2}>
              <IF condition={!!discounts}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
                  <Typography variant='subtitle1'>Discount </Typography>{' '}
                  <Typography variant='subtitle1' sx={{ overflowWrap: 'break-word' }} noWrap>
                    -{formatItemPrice(discounts)}
                  </Typography>
                </Stack>
              </IF>
              <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
                <Typography variant='subtitle1'>Subtotal </Typography>{' '}
                <Typography variant='subtitle1' sx={{ overflowWrap: 'break-word' }} noWrap>
                  {getSubtotalFormatted(order)}
                </Typography>
              </Stack>

              <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
                <Typography variant='subtitle1'>Tax </Typography>{' '}
                <Typography variant='subtitle1' sx={{ overflowWrap: 'break-word' }} noWrap>
                  {formatItemPrice(order.tax)}
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
                <Typography variant='h6'>Total </Typography>{' '}
                <Typography
                  variant='h6'
                  fontWeight={600}
                  sx={{ overflowWrap: 'break-word' }}
                  noWrap
                >
                  {getTotalFormatted(order)}
                </Typography>
              </Stack>
            </Stack>
            <IF condition={order.state == OrderState.OPEN}>
              <Divider />
              <CancelOrderButton orderId={order.id} />
            </IF>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default OrderPage;

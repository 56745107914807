import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from 'src/store/axiosConfig';

import { MERCHANTS_PATH, USERS_MERCHANTS_PATH } from 'src/shared/consts/Api.consts';
import { AxiosResponse } from 'axios';
import { Page } from 'src/shared/interfaces/page.interface';
import { StoreDtoInterface } from 'src/store/interfaces/stores/storeDto.interface';
import { PageRequest } from 'src/shared/interfaces/pageRequest.interface';
import { RequestError } from 'src/utils/types';

export const thunkStores = createAsyncThunk<Page<StoreDtoInterface>, PageRequest>(
  MERCHANTS_PATH.MERCHANTS,
  async (data, thunkAPI) => {
    try {
      const config = {
        params: {
          page: data.page || 0,
          size: data.size || 10,
          sort: data.sort || 'merchant.id'
        }
      };

      const response: AxiosResponse<Page<StoreDtoInterface>> = await axiosInstance.get(
        `${MERCHANTS_PATH.MERCHANTS}`,
        config
      );

      return thunkAPI.fulfillWithValue(response.data);
    } catch (e) {
      return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
    }
  }
);

export const thunkAddStoreByRegistrationToken = createAsyncThunk<
  StoreDtoInterface,
  {
    token: string;
    registrationToken: string;
  }
>(USERS_MERCHANTS_PATH.USERS_MERCHANTS, async (data, thunkAPI) => {
  try {
    const response: AxiosResponse<StoreDtoInterface> = await axiosInstance.post(
      `${USERS_MERCHANTS_PATH.USERS_MERCHANTS}`,
      null,
      {
        params: {
          token: data.registrationToken
        }
      }
    );

    return thunkAPI.fulfillWithValue(response.data);
  } catch (e) {
    return thunkAPI.rejectWithValue((e as RequestError).response.data.error);
  }
});

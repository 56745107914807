import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// mui
import { Box, Button, Link, Stack, Typography } from '@mui/material';

//components
import { ProfileEditInput } from 'src/components/ProfileEditInput/ProfileEditInput';
import { PasswordInput, Validatable } from 'src/components/PasswordInput/PasswordInput';
import LoadingCircularProgress from 'src/components/LoadingCircularProgress';
import Modal from 'src/components/Modal/Modal';
import { CustomInput } from 'src/components/CustomInput/customInput';
import LogOutButton from 'src/components/LogOutButton/LogOutButton';

// utils
import { isPhoneNumberValid, validateEmail } from 'src/features/inputValidation';
import { RequestStatuses } from 'src/utils/enums';

// redux
import {
  thunkUserPasswordUpdate,
  thunkUserUpdate,
  thunkValidateOldPassword
} from 'src/store/thunks';
import { setUser } from 'src/store/slices/userSlice';
import { useAppDispatch } from 'src/hooks/hooks';

//types
import { UserProfile } from 'src/store/interfaces/users/userProfile';

//constants
import { ERROR, HELPER_TEXT } from 'src/shared/consts/Messages.consts';
import { AUTH_ROUTES } from 'src/shared/consts/Rout.consts';
import { InputHelperMessage } from 'src/shared/interfaces/inputHelperMessage.interface';

interface Props {
  user: UserProfile | null;
}
interface UserInfo {
  firstName: string;
  lastName: string;
  phone: string;
}

interface ProfileFormAttributes extends UserInfo {
  password: string;
  confirmPassword: string;
}

const normalizePhone = (phone: string) => phone.replace(/\D/g, '');

const ProfileComponent: React.FC<Props> = ({ user }) => {
  const defaultField: ProfileFormAttributes = {
    phone: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: ''
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const passwordInput = useRef<Validatable>(null);
  const [form, setForm] = useState(defaultField);
  const [currentUser, setCurrentUser] = useState<UserInfo | null>(null);
  const [oldPasswordHelperMessage, setOldPasswordHelperMessage] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [isOldPasswordSet, setIsOldPasswordSet] = useState(false);
  const [isInputOldPassword, setIsInputOldPassword] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [isUserUpdating, setIsUserUpdating] = useState(false);

  useEffect(() => {
    if (user) {
      setUserInfo(user);
    }
  }, [user]);

  function setUserInfo({ phoneNumber = '', firstName = '', lastName = '' }: UserProfile) {
    const user = {
      phone: phoneNumber ? phoneNumber : '',
      firstName: firstName ? firstName : '',
      lastName: lastName ? lastName : ''
    };
    setForm({
      password: '',
      confirmPassword: '',
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName
    });
    setCurrentUser({ phone: user.phone, firstName: user.firstName, lastName: user.lastName });
  }

  const fetchValidateOldPassword = async () => {
    if (isPasswordLoading) return;
    setIsPasswordLoading(true);

    const response = await dispatch(thunkValidateOldPassword({ password: oldPassword }));
    const content = response.payload as boolean;
    if (!content) {
      setOldPasswordHelperMessage(`Old password isn't valid`);
      setOldPasswordError(true);
    } else {
      setOldPasswordHelperMessage('');
      setOldPasswordError(false);
      setIsInputOldPassword(false);
    }
    setIsOldPasswordSet(content);

    setIsPasswordLoading(false);
  };

  const validatePassword = (): InputHelperMessage => {
    setOldPasswordHelperMessage('');
    setOldPasswordError(false);
    return { message: '', error: false };
  };

  const validatePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>): InputHelperMessage => {
    if (!event.target.value) {
      return { message: '', error: false };
    }
    // todo when validation will be needed
    // if (!event.target.value) {
    //   return { message: ERROR.MUST_PHONE_NUMBER, error: true };
    // }
    const error = !isPhoneNumberValid(event.target.value);
    const message = error ? ERROR.INCORRECT_PHONE_NUMBER : '';
    return { message: message, error: error };
  };

  const validateName = (event: React.ChangeEvent<HTMLInputElement>): InputHelperMessage => {
    const nameRegex = /^[A-Za-z\s.'-]+$/;
    const error = event.target.value ? !nameRegex.test(event.target.value) : false;
    const message = error ? ERROR.INCORRECT_FIELD : '';
    return { message: message, error: error };
  };

  const handleChange = useCallback(({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setForm((state) => ({ ...state, [name]: value }));
  }, []);

  const isPhonesChanged = useCallback(() => {
    const currentPhone = normalizePhone(currentUser?.phone || '');
    return currentPhone !== normalizePhone(form.phone);
  }, [currentUser, form.phone]);

  function isFullNameChanges(): boolean {
    const currentLast = currentUser?.lastName;
    const currentFirst = currentUser?.firstName;
    return currentFirst !== form.firstName || currentLast !== form.lastName;
  }

  function isPasswordChanged() {
    return form.password != '';
  }

  function hasChanges() {
    return isPhonesChanged() || isPasswordChanged() || isFullNameChanges();
  }

  function isChangesValid() {
    const validPhone = form.phone.trim().length > 0 && isPhoneNumberValid(form.phone);
    return (
      !isPasswordChanged() ||
      passwordInput.current?.isPasswordValid(form.password, form.confirmPassword)
    );
  }

  const saveChanges = () => {
    if (isUserUpdating || isPasswordLoading) return;
    if (isPhonesChanged() || isFullNameChanges()) {
      fetchUserUpdate().then();
    }
    if (isPasswordChanged()) {
      fetchPasswordUpdate().then();
    }
  };

  const fetchUserUpdate = async () => {
    setIsUserUpdating(true);
    const result = await dispatch(
      thunkUserUpdate({
        phoneNumber: form.phone.length ? form.phone : null,
        lastName: form.lastName.length ? form.lastName : null,
        firstName: form.firstName.length ? form.firstName : null
      })
    );
    if (result.meta.requestStatus === RequestStatuses.fulfilled) {
      setCurrentUser({ phone: form.phone, lastName: form.lastName, firstName: form.firstName });
      const updatedUser = Object.assign({}, user);
      updatedUser.phoneNumber = form.phone;
      updatedUser.firstName = form.firstName;
      updatedUser.lastName = form.lastName;
      dispatch(setUser(updatedUser));
    }
    setIsUserUpdating(false);
  };

  const fetchPasswordUpdate = async () => {
    setIsPasswordLoading(true);
    const result = await dispatch(
      thunkUserPasswordUpdate({ oldPassword: oldPassword, newPassword: form.password })
    );
    if (result.meta.requestStatus === RequestStatuses.fulfilled) {
      setForm({
        phone: form.phone,
        password: '',
        confirmPassword: '',
        firstName: form.firstName,
        lastName: form.lastName
      });
    }
    setOldPassword('');
    setIsOldPasswordSet(false);
    setIsPasswordLoading(false);
  };

  const setOldPasswordCallback = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setOldPassword(value);
    },
    []
  );

  return (
    <>
      <Typography variant='h4'>Profile</Typography>
      {!!user ? (
        <Stack sx={{ flex: 1, gap: 2 }}>
          <ProfileEditInput
            type='text'
            name='firstName'
            label='First Name'
            value={form.firstName}
            onChange={handleChange}
            validate={validateName}
          />
          <ProfileEditInput
            type='text'
            name='lastName'
            label='Last Name'
            value={form.lastName}
            onChange={handleChange}
            validate={validateName}
          />
          <ProfileEditInput
            type='tel'
            name='phone'
            label='Phone'
            required={true}
            placeholder={HELPER_TEXT.PHONE_NUMBER}
            value={form.phone}
            onChange={handleChange}
            validate={validatePhoneNumber}
          />
          <ProfileEditInput
            type='text'
            name='email'
            label='Email'
            placeholder='Email'
            value={user.email}
            disabledEdit={true}
            validate={validateEmail}
            onChange={() => {}}
          />
          {!isInputOldPassword && isOldPasswordSet ? (
            <PasswordInput
              ref={passwordInput}
              password={form.password}
              confirmPassword={form.confirmPassword}
              onChange={handleChange}
            />
          ) : (
            <ProfileEditInput
              type='password'
              name='password'
              label='Password'
              value='password placeholder'
              onChange={() => {}}
              onClick={() => {
                setIsInputOldPassword(true);
              }}
            />
          )}
          {hasChanges() && isChangesValid() && (
            <Button
              variant='contained'
              sx={{ height: '48px' }}
              onClick={saveChanges}
              disabled={isUserUpdating || isPasswordLoading}
            >
              <LoadingCircularProgress
                isLoading={isPasswordLoading || isUserUpdating}
                color='white'
                sx={{ p: 0.5, boxSizing: 'border-box' }}
                fullElement
              >
                Save
              </LoadingCircularProgress>
            </Button>
          )}
          <Modal
            isOpen={isInputOldPassword}
            onClose={() => {
              if (!isOldPasswordSet) setOldPassword('');
              setIsInputOldPassword(false);
            }}
            sx={{ gap: 2 }}
          >
            <Typography variant='h6' component='h2' sx={{ fontWeight: 'bold' }}>
              Please enter your old password
            </Typography>
            <CustomInput
              type='password'
              name='password'
              value={oldPassword}
              required
              onChange={setOldPasswordCallback}
              helperText={oldPasswordHelperMessage}
              error={oldPasswordError}
              validate={validatePassword}
              customValidation
            />
            <Box display='flex' justifyContent='right' width='100%'>
              <Link
                href='#'
                color='text.primary'
                onClick={() => navigate(AUTH_ROUTES.FORGOT_PASSWORD)}
              >
                {'Forgot password?'}
              </Link>
            </Box>
            <Button
              variant='contained'
              sx={{ height: '48px' }}
              onClick={() => {
                fetchValidateOldPassword();
              }}
            >
              <LoadingCircularProgress
                isLoading={isPasswordLoading}
                color='white'
                sx={{ p: 0.5, boxSizing: 'border-box' }}
                fullElement
              >
                Enter
              </LoadingCircularProgress>
            </Button>
          </Modal>
        </Stack>
      ) : (
        <Box sx={{ flex: 1 }}>
          <LoadingCircularProgress isLoading={!user} fullElement />
        </Box>
      )}
      <LogOutButton />
    </>
  );
};

export default ProfileComponent;

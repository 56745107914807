import { BASE_PATH } from 'src/config';

const MAIN = BASE_PATH;

const NOT_FOUND = '/not-found';
const ONBOARDING = `${MAIN}onboarding`;

const AUTH_ROUTES = {
  LOGIN: `${MAIN}login`,
  REGISTER: `${MAIN}register`,
  EMAIL_VERIFICATION: `${MAIN}email-verification`,
  FORGOT_PASSWORD: `${MAIN}forgot-password`,
  PASSWORD_RESET: `${MAIN}password-reset`,
  FORGOT_PASSWORD_CHECK_EMAIL: `${MAIN}forgot-password/check-email`,
  PRIVACY_POLICY: `${MAIN}privacy-policy`,
  TERMS_AND_CONDITIONS: `${MAIN}terms`
};

const MERCHANTS_ROUTES = {
  MERCHANTS: `${MAIN}stores`,
  MERCHANT: `${MAIN}stores/:id`
};

const DISCOUNTS_ROUTES = {
  DISCOUNTS: `${MAIN}discounts`,
  DISCOUNT: `${MAIN}discounts/:id`,
  DISCOUNT_STORES: `${MAIN}discounts/:id/stores`,
  DISCOUNT_ITEMS: `${MAIN}discounts/:id/products`
};

const ITEMS_ROUTES = {
  ITEMS: `${MAIN}products`,
  MERCHANT_ITEMS: `${MAIN}products?merchantId=:id`,
  ITEM: `${MAIN}products/:id`
};

const USER_ROUTES = {
  PROFILE: `${MAIN}profile`,
  MENU: `${MAIN}menu`
};

const CART_ROUTES = {
  CART: `${MAIN}cart`,
  CHECKOUT: `${MAIN}cart/checkout`
};

const ORDERS_ROUTES = {
  ORDERS: `${MAIN}orders`,
  ORDER: `${MAIN}orders/:id`
};

export {
  MAIN,
  NOT_FOUND,
  ONBOARDING,
  AUTH_ROUTES,
  DISCOUNTS_ROUTES,
  ITEMS_ROUTES,
  MERCHANTS_ROUTES,
  CART_ROUTES,
  USER_ROUTES,
  ORDERS_ROUTES
};

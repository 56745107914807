import React, { FC } from 'react';
import { Box, Skeleton, Stack, SvgIcon, Typography } from '@mui/material';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { getDiscountName, getDiscountTimeLimit } from 'src/utils/scripts/discount';

import { ReactComponent as ClockIcon } from 'src/images/clock_icon.svg';
import { ReactComponent as CouponIcon } from 'src/images/coupon_icon.svg';

interface Props {
  discount: Discount | null;
  isDiscountAvailable?: boolean;
  showCouponIcon?: boolean;
  gap?: number;
}

const DiscountHeader: FC<Props> = ({
  discount,
  isDiscountAvailable = true,
  showCouponIcon = false,
  gap = 1
}) => {
  return (
    <Stack p={2} flexDirection='row' justifyContent='space-between' alignItems='center'>
      <Stack gap={gap}>
        {!isDiscountAvailable && (
          <Typography variant='h3' fontWeight='bold' color='text.primary'>
            Discount not available
          </Typography>
        )}
        <Box display='flex' flexDirection='row' alignItems='center' gap={0.6}>
          {discount ? (
            <>
              <ClockIcon />
              <Typography fontSize='0.8rem' lineHeight='1.3rem' color={'text.secondary'}>
                {getDiscountTimeLimit(discount)}
              </Typography>
            </>
          ) : (
            <Skeleton width={'35%'} />
          )}
        </Box>
        {discount ? (
          <Typography
            variant='h5'
            textAlign='left'
            fontWeight='bold'
            color={isDiscountAvailable ? 'text.primary' : 'text.disabled'}
          >
            {getDiscountName(discount)}
          </Typography>
        ) : (
          <Skeleton width={'50%'} />
        )}
      </Stack>
      {showCouponIcon && discount?.coupon && (
        <SvgIcon
          component={CouponIcon}
          inheritViewBox
          sx={{
            color: 'transparent',
            width: '64px',
            height: '64px'
          }}
        />
      )}
    </Stack>
  );
};

export default DiscountHeader;

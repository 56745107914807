import React from 'react';
//mui
import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Typography
} from '@mui/material';

//components
import { ReactComponent as RightArrowIcon } from 'src/images/right_arrow_icon.svg';
import IF from 'src/components/IF';

export interface ISideMenuItemsListItem {
  icon: React.ElementType;
  title: string;
  onClick(): void;
  disableDivider?: boolean;
}

const SideMenuItemsList: React.FC<ISideMenuItemsListItem> = ({
  icon,
  title,
  onClick,
  disableDivider = false
}) => {
  return (
    <>
      <ListItem
        component='div'
        alignItems='flex-start'
        sx={{
          backgroundColor: 'white'
        }}
        disablePadding
      >
        <ListItemButton
          onClick={(event) => {
            event.preventDefault();
            onClick();
          }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            p: 2,
            gap: 0.5,
            backgroundColor: 'white'
          }}
        >
          <SvgIcon
            color='primary'
            sx={{
              width: 24,
              height: 24
            }}
            component={icon}
            inheritViewBox
          />
          <ListItemText
            sx={{
              flex: 1,
              mt: 0,
              mb: 0,
              alignSelf: 'start',
              userSelect: { xs: 'auto', md: 'none' }
            }}
            primary={<Typography sx={{ fontSize: '1rem' }}>{title}</Typography>}
          />
          <SvgIcon
            sx={{ fill: 'transparent', width: 24, height: 24 }}
            component={RightArrowIcon}
            inheritViewBox
          />
        </ListItemButton>
      </ListItem>
      <IF condition={!disableDivider}>
        <Divider variant='middle' sx={{ borderColor: '#D0DBE7' }} />
      </IF>
    </>
  );
};

export default SideMenuItemsList;

import { createAsyncThunk } from '@reduxjs/toolkit';

// constants
import { OMNI_ITEMS_PATH } from 'src/shared/consts/Api.consts';

//types
import { Page } from 'src/shared/interfaces/page.interface';
import { PageRequest } from 'src/shared/interfaces/pageRequest.interface';
import { RequestError } from 'src/utils/types';
import { axiosInstance } from 'src/store/axiosConfig';
import { Discount } from 'src/shared/interfaces/discount.interface';

export const thunkOmniItemsItemsByOmniItemId = createAsyncThunk<
  Page<Discount>,
  { pageRequest?: PageRequest; omniItemId: number; merchantIdNot: number | undefined }
>(OMNI_ITEMS_PATH.ITEMS(), async ({ pageRequest, omniItemId, merchantIdNot }, thunkAPI) => {
  try {
    const params = { ...pageRequest, merchantIdNot: merchantIdNot };
    const response = await axiosInstance.get(OMNI_ITEMS_PATH.ITEMS(omniItemId.toString()), {
      params: params
    });
    return thunkAPI.fulfillWithValue(response.data);
  } catch (error) {
    return thunkAPI.rejectWithValue((error as RequestError).response.data.error);
  }
});

import React from 'react';
import { Modal as ModalMUI, Stack, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface Props {
  isOpen: boolean;
  onClose(event: object, reason: string): void;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const modalStyle: SxProps<Theme> = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 3rem)',
  bgcolor: 'white',
  boxSizing: 'border-box',
  borderRadius: '8px',
  boxShadow: 24,
  py: 4,
  px: 2,
  textAlign: 'center',
  maxWidth: { xs: '100%', sm: '26rem' },
  outline: 'none'
};

const Modal: React.FC<Props> = ({ isOpen, onClose, children, sx }) => {
  return (
    <ModalMUI open={isOpen} onClose={onClose} sx={{ backdropFilter: 'blur(3px)' }}>
      <Stack sx={{ ...modalStyle, ...sx }}>{children}</Stack>
    </ModalMUI>
  );
};

export default Modal;

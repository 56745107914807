import { STORAGE } from 'src/shared/consts/Storage.consts';

export function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function getAccessToken() {
  return localStorage.getItem(STORAGE.ACCESS_TOKEN);
}

export function removeAccessToken() {
  return localStorage.clear();
}

export function getDistance(latFrom: number, lngFrom: number, latTo: number, lngTo: number) {
  if (!latFrom || !lngFrom) return null;
  const earthRadius = 6371; // Radius of the earth in km
  const latitudeDelta = degToRadians(latTo - latFrom);
  const longitudeDelta = degToRadians(lngTo - lngFrom);
  const a =
    Math.sin(latitudeDelta / 2) * Math.sin(latitudeDelta / 2) +
    Math.cos(degToRadians(latFrom)) *
      Math.cos(degToRadians(latTo)) *
      Math.sin(longitudeDelta / 2) *
      Math.sin(longitudeDelta / 2);
  const angularDistance = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * angularDistance; // Distance in km
}

function degToRadians(deg: number) {
  return deg * (Math.PI / 180);
}

export const cssVar = (name: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(name).trim();

import React from 'react';

//mui
import { TextField, TextFieldProps } from '@mui/material';

//components
import { NumericFormat } from 'react-number-format';

const QuantityTextField = ({ ...props }) => {
  return (
    <NumericFormat
      customInput={CustomTextField}
      thousandSeparator={true}
      fixedDecimalScale
      {...props}
    />
  );
};

function CustomTextField(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      variant='standard'
      color='primary'
      sx={{
        width: '70px',
        boxShadow: 'none',
        bgcolor: (theme) => theme.palette.light.main,
        '.MuiInputBase-input': {
          py: 1,
          border: 'none !important',
          textAlign: 'center'
        },
        '& :before, & :after': {
          border: 'none !important' // Вимкнення бордера при наведенні
        }
      }}
    >
      {props.children}
    </TextField>
  );
}

export default QuantityTextField;

import React, { FC } from 'react';
import { Item } from 'src/shared/interfaces/items.interface';

// mui
import { List } from '@mui/material';

// components
import ItemsListItem from 'src/components/Items/ItemsListItem';

interface Props {
  items: Item[] | null[];
  onItemClick?(item: Item): void;
}

const ItemsList: FC<Props> = ({ items, onItemClick = () => {} }) => {
  return (
    <List sx={{ width: '100%' }} disablePadding>
      {items.map((item, index) =>
        item ? (
          <ItemsListItem key={item.id} item={item} onClick={() => onItemClick(item)} />
        ) : (
          <ItemsListItem key={index - items.length} item={item} />
        )
      )}
    </List>
  );
};

export default ItemsList;

import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//mui
import { Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';

//components
import IF from 'src/components/IF';

//redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { setActiveItem } from 'src/store/slices/itemsTabSlice';
import { setCartItems } from 'src/store/slices/cartSlice';

//types
import { CartItem } from 'src/shared/interfaces/cartItem.interface';

//constants
import { CART_ROUTES, ITEMS_ROUTES } from 'src/shared/consts/Rout.consts';

//utils
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { getAccessToken } from 'src/utils/scripts';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import CartItemsList from 'src/components/Cart/CartItemsList';
import { fetchUpdateCartItem } from 'src/utils/scripts/fetchCart';
import { debounce } from 'src/utils/scripts/debounce';
import { getCartItemsWithNewQuantity } from 'src/utils/scripts/cart';

function Cart() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cartItems = useAppSelector((state) => state.cart.items);
  const [isLoading, setIsLoading] = useState(false);

  const subdomain = getSubDomain();
  const accessToken = getAccessToken();

  const openItemPage = useCallback(
    (cartItem: CartItem) => {
      dispatch(setActiveItem(cartItem.item));
      navigate(ITEMS_ROUTES.ITEM.replace(':id', cartItem.item.id.toString()));
    },
    [dispatch, navigate]
  );

  const debouncedFetch = useCallback(
    debounce((items: CartItem[]) => {
      setIsLoading(true);
      fetchUpdateCartItem(items, dispatch).then(() => setIsLoading(false));
    }, 1500),
    []
  );

  const changeCartItemQuantity = (cartItem: CartItem, newQuantity: number) => {
    const newCartItems = getCartItemsWithNewQuantity(cartItems, cartItem, newQuantity);
    dispatch(setCartItems(newCartItems));
    debouncedFetch(newCartItems);
  };

  const deleteCartItem = useCallback(
    (cartItem: CartItem) => {
      const updatedCartItems = cartItems.filter((item) => item !== cartItem);
      dispatch(setCartItems(updatedCartItems));
    },
    [cartItems, dispatch]
  );

  const handleCheckout = () => {
    navigate(CART_ROUTES.CHECKOUT);
  };

  const handleAddToCart = () => {
    navigate(ITEMS_ROUTES.ITEMS);
  };

  if (!cartItems.length) {
    return (
      <Stack
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%'
        }}
      >
        <RemoveShoppingCartIcon
          sx={{
            color: 'grey',
            fontSize: '17rem',
            opacity: '0.1'
          }}
        />
        <Typography sx={{ mb: 10 }} variant='h3'>
          Your cart is empty.
        </Typography>
        <Button onClick={handleAddToCart} variant='contained'>
          Add to cart
        </Button>
      </Stack>
    );
  }

  return (
    <IF condition={!!cartItems.length && !!subdomain && !!accessToken}>
      <Stack flex={1} overflow='auto'>
        <CartItemsList
          isLoading={isLoading}
          cartItems={cartItems}
          onClick={openItemPage}
          onQuantityChange={changeCartItemQuantity}
          onDelete={deleteCartItem}
        />
      </Stack>
      <Paper elevation={4} sx={{ p: 2, borderRadius: 0, zIndex: 100 }}>
        <Button
          disabled={isLoading}
          startIcon={isLoading ? <CircularProgress size={29} /> : null}
          fullWidth
          variant='contained'
          onClick={handleCheckout}
        >
          {!isLoading && 'Checkout'}
        </Button>
      </Paper>
    </IF>
  );
}

export default Cart;

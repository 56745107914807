import React, { FC } from 'react';

//components
import { Typography } from '@mui/material';

//types
import { OrderState } from 'src/shared/enums';

interface Props {
  orderState: OrderState;
}

const OrdersState: FC<Props> = ({ orderState }) => {
  function getStateColor(): string {
    switch (orderState) {
      case OrderState.OPEN: {
        return 'info.main';
      }
      case OrderState.COMPLETED: {
        return 'primary.main';
      }
      case OrderState.CANCELLED: {
        return 'error.main';
      }
    }
  }

  return (
    <Typography variant='body2' sx={{ userSelect: 'none', color: getStateColor() }}>
      {orderState}
    </Typography>
  );
};

export default OrdersState;

import { combineReducers } from '@reduxjs/toolkit';

import { mainSlice } from 'src/store/slices/mainSlice';
import { itemsTabSlice } from 'src/store/slices/itemsTabSlice';
import { merchantsTabSlice } from 'src/store/slices/merchantsTabSlice';
import { discountsTabSlice } from 'src/store/slices/discountsTabSlice';
import { userSlice } from 'src/store/slices/userSlice';
import { promptInstallSlice } from 'src/store/slices/promptInstallSlice';
import { geolocationSlice } from 'src/store/slices/geolocationSlice';
import { discountItemsSlice } from 'src/store/slices/discountItemsSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { cartSlice } from 'src/store/slices/cartSlice';
import { ordersSlice } from 'src/store/slices/ordersSlice';

const persistCartConfig = {
  key: 'cart',
  storage
};

const persistedCartReducer = persistReducer(persistCartConfig, cartSlice.reducer);

export const rootReducer = combineReducers({
  main: mainSlice.reducer,
  itemsTab: itemsTabSlice.reducer,
  merchantsTab: merchantsTabSlice.reducer,
  discountsTab: discountsTabSlice.reducer,
  user: userSlice.reducer,
  pwaInstall: promptInstallSlice.reducer,
  geolocation: geolocationSlice.reducer,
  discountItems: discountItemsSlice.reducer,
  orders: ordersSlice.reducer,
  cart: persistedCartReducer
});

import React, { FC } from 'react';

// mui
import { Box, Divider, ListItem, ListItemButton, Skeleton, Stack, Typography } from '@mui/material';

//types
//scripts
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { Item } from 'src/shared/interfaces/items.interface';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import { getItemName } from 'src/shared/enums';
import { formatItemPrice } from 'src/utils/scripts/items';
import IF from 'src/components/IF';

interface Props {
  item: Item | null;
  onClick?(): void;
}

const MerchantItemsListItem: FC<Props> = ({ item, onClick = () => {} }) => {
  const subdomain = getSubDomain();
  if (!item) {
    return <Skeleton variant='rounded' width='auto' height={'4rem'} sx={{ mx: 2, my: 1 }} />;
  }
  return (
    <>
      {item && (
        <ListItem
          className='item'
          sx={{
            width: 'auto',
            height: 'fit-content',
            borderRadius: '8px',
            p: '4px !important',
            backgroundColor: 'white',
            flexDirection: 'column'
          }}
        >
          <ListItemButton
            dense
            sx={{ width: '100%', p: 0, borderRadius: '8px', bgcolor: 'white' }}
            onClick={onClick}
            disabled={!!subdomain && !item.eStore}
          >
            <Stack direction='row' gap={1}>
              <Box className={`productImg ${!item.defaultImage?.url ? `productImgEmpty` : null}`}>
                {item.defaultImage?.url ? (
                  <img src={item.defaultImage.url} alt={item.name} />
                ) : (
                  <ImageNotSupportedOutlinedIcon className='productImgIcon' />
                )}
              </Box>
              <Box className='productItem'>
                <Box>
                  <Typography fontWeight='bold' variant='h5'>
                    {getItemName(item)}
                  </Typography>
                  <Typography color={'rgba(0,0,0,0.6)'} variant='body2'>
                    {item.merchantName}
                  </Typography>
                </Box>

                <Typography variant='h6' noWrap>
                  {formatItemPrice(item.price)}
                </Typography>
              </Box>
            </Stack>
          </ListItemButton>
          <IF condition={!!subdomain && !item.eStore}>
            <Box width='100%' pb={0.5} boxSizing='border-box'>
              <Divider />
              <Typography color='text.disabled' sx={{ px: 2, pt: 0.5, userSelect: 'none' }}>
                Not active
              </Typography>
            </Box>
          </IF>
        </ListItem>
      )}
    </>
  );
};

export default MerchantItemsListItem;

import React from 'react';
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  SvgIcon,
  Typography
} from '@mui/material';
import { SearchInterface } from 'src/store/interfaces/searches/search.interface';
import { ReactComponent as SearchIcon } from 'src/images/search_icon.svg';

interface Props {
  search: SearchInterface | null;
  onClick(): void;
}

const RecentSearchesListItem: React.FC<Props> = ({ search, onClick }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        dense
        sx={{
          p: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 1,
          px: 2,
          py: 1,
          ':hover': {
            color: 'grey'
          }
        }}
        onClick={() => onClick()}
      >
        <ListItemAvatar sx={{ minWidth: 'fit-content', m: 0, p: 0, alignSelf: 'start' }}>
          {search ? (
            <Box
              sx={{
                width: 20,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <SvgIcon
                sx={{
                  fill: (theme) => theme.palette.lightGrey.main,
                  width: 16.22,
                  height: 16.38
                }}
                component={SearchIcon}
                inheritViewBox
              />
            </Box>
          ) : (
            <Skeleton variant='rectangular' width={20} height={20} sx={{ mt: 0.5 }} />
          )}
        </ListItemAvatar>
        {search ? (
          <ListItemText
            sx={{
              flex: 1,
              mt: 0,
              mb: 0,
              alignSelf: 'start',
              userSelect: { xs: 'auto', md: 'none' },
              overflowWrap: 'break-word'
            }}
            primary={<Typography sx={{ fontWeight: 'bold' }}>{search.primaryValue}</Typography>}
            secondary={search.secondaryValue}
          />
        ) : (
          <Box sx={{ width: '100%' }}>
            <Skeleton width='60%' />
            <Skeleton />
          </Box>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default RecentSearchesListItem;

import React from 'react';

interface Props {
  condition: boolean;
  children: React.ReactNode;
  elseChildren?: React.ReactNode;
}

const IF: React.FC<Props> = ({ condition, children, elseChildren }) => {
  return condition ? <>{children}</> : <>{elseChildren}</>;
};

export default IF;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.css';
import 'swiper/css';

// mui
import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';

// components
import CustomAppBar from 'src/components/CustomAppBar/CustomAppBar';
import BackButton from 'src/components/BackButton/BackButton';
import MerchantListItem from 'src/components/Merchants/MerchantListItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import MerchantItemCard from 'src/components/MerchantItemCard/MerchantItemCard';
import DiscountCard from 'src/components/Discounts/discount/DiscountCard';
import ItemName from 'src/components/Items/ItemName';
import AddToCartButton from 'src/components/AddToCartButton';

// redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { thunkDiscountsByItemId, thunkItem, thunkMerchant } from 'src/store/thunks';
import { thunkOmniItemsItemsByOmniItemId } from 'src/store/thunks/omni-items';
import { setActiveMerchant } from 'src/store/slices/merchantsTabSlice';
import { setActiveItem } from 'src/store/slices/itemsTabSlice';
import { setActiveDiscount } from 'src/store/slices/discountsTabSlice';
import { setGeolocation, setIsGeolocationEnable } from 'src/store/slices/geolocationSlice';

// constants
import { DISCOUNTS_ROUTES, ITEMS_ROUTES, MERCHANTS_ROUTES } from 'src/shared/consts/Rout.consts';

// types
import { toItem } from 'src/shared/enums';
import { Page } from 'src/shared/interfaces/page.interface';

// scripts
import { getGeolocation } from 'src/utils/scripts/geolocation';
import { getMerchantDistance } from 'src/utils/scripts/merchants';
import { formatItemPrice } from 'src/utils/scripts/items';

// constants
import { RequestStatuses } from 'src/utils/enums';

//scripts
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { Discount } from 'src/shared/interfaces/discount.interface';
import { Item, MerchantItem } from 'src/shared/interfaces/items.interface';
import { Merchant } from 'src/shared/interfaces/merchant.interface';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import { getAccessToken } from 'src/utils/scripts';

function ItemPage() {
  const navigate = useNavigate();
  const accessToken = getAccessToken();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const item = useAppSelector((state) => state.itemsTab.activeItem);
  const merchant = useAppSelector((state) => state.merchantsTab.activeMerchant);
  const currentGeolocation = useAppSelector((state) => state.geolocation.current);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [omniItems, setOmniItems] = useState<MerchantItem[]>([]);
  const [isMerchantLoading, setIsMerchantLoading] = useState(true);
  const [isGeolocationLoading, setIsGeolocationLoading] = useState(true);
  const [isDiscountsLoading, setIsDiscountsLoading] = useState(true);
  const [isOmniItemsLoading, setIsOmniItemsLoading] = useState(true);
  const subdomain = getSubDomain();

  const goBack = () => {
    navigate(-1);
  };

  const fetchItem = async () => {
    const response = await dispatch(thunkItem({ id: id! }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      dispatch(setActiveItem(response.payload as Item));
    }
  };

  const fetchMerchant = async (merchantId: number) => {
    setIsMerchantLoading(true);
    const response = await dispatch(thunkMerchant({ id: merchantId.toString() }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      const content = response.payload as Merchant;
      dispatch(setActiveMerchant(content));
      setIsMerchantLoading(false);
    }
  };

  const fetchDiscounts = async () => {
    setIsDiscountsLoading(true);
    const response = await dispatch(thunkDiscountsByItemId({ itemId: id! }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      const { content } = response.payload as Page<Discount>;
      setDiscounts(content);
      setIsDiscountsLoading(false);
    }
  };

  const fetchOmniItems = async (omniItemId: number, merchantId: number) => {
    setIsOmniItemsLoading(true);
    const response = await dispatch(
      thunkOmniItemsItemsByOmniItemId({
        omniItemId: omniItemId,
        merchantIdNot: merchantId
      })
    );
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      const { content } = response.payload as Page<MerchantItem>;
      if (currentGeolocation) {
        content.forEach(
          (item) =>
            (item.merchant.distance = getMerchantDistance(item.merchant, currentGeolocation))
        );
        content.sort((a, b) => (a.merchant.distance ?? 0) - (b.merchant.distance ?? 0));
      }
      setOmniItems(content);
      setIsOmniItemsLoading(false);
    }
  };

  useEffect(() => {
    if (item) {
      fetchDiscounts();
      if (item.omniItemId) {
        fetchOmniItems(item.omniItemId, item.merchantId);
      } else {
        setIsOmniItemsLoading(false);
      }
    }
  }, [item]);

  useEffect(() => {
    if (!item || item.id.toString() != id!) {
      fetchItem();
    }
  }, [item, id]);

  useEffect(() => {
    if (item && (!merchant || merchant.id != item.merchantId)) {
      fetchMerchant(item.merchantId);
    } else if (merchant && merchant.distance == null && currentGeolocation) {
      const content: Merchant = Object.assign({}, merchant);
      content.distance = getMerchantDistance(merchant, currentGeolocation);
      dispatch(setActiveMerchant(content));
    }
    if (merchant?.id != null && merchant?.id == item?.merchantId) setIsMerchantLoading(false);
  }, [merchant, item]);

  useEffect(() => {
    setCurrentGeolocation();
    return () => {
      dispatch(setActiveItem(null));
    };
  }, []);

  useEffect(() => {
    if (merchant && currentGeolocation && !isGeolocationLoading) {
      const content: Merchant = Object.assign({}, merchant);
      content.distance = getMerchantDistance(merchant, currentGeolocation);
      if (merchant.distance != content.distance) dispatch(setActiveMerchant(content));
    }
  }, [merchant, currentGeolocation, dispatch]);

  useEffect(() => {
    if (omniItems.length && currentGeolocation && !isGeolocationLoading) {
      const compareString = omniItems.map((it) => it.merchant.distance).toString();
      const content: MerchantItem[] = [];
      omniItems.forEach((val) => content.push(Object.assign({}, val)));
      content.forEach(
        (item) => (item.merchant.distance = getMerchantDistance(item.merchant, currentGeolocation))
      );
      content.sort((a, b) => (a.merchant.distance ?? 0) - (b.merchant.distance ?? 0));
      if (compareString != content.map((it) => it.merchant.distance).toString())
        setOmniItems(content);
    }
  }, [omniItems, currentGeolocation, isGeolocationLoading]);

  function setCurrentGeolocation() {
    getGeolocation(
      (geolocation) => {
        setIsGeolocationLoading(false);
        dispatch(setGeolocation(geolocation));
        dispatch(setIsGeolocationEnable(true));
      },
      () => {
        setIsGeolocationLoading(false);
        dispatch(setIsGeolocationEnable(false));
      }
    );
  }

  const openMerchant = (selectedMerchant: Merchant) => {
    if (selectedMerchant != merchant) dispatch(setActiveMerchant(selectedMerchant));
    navigate(MERCHANTS_ROUTES.MERCHANT.replace(':id', selectedMerchant.id.toString()));
  };

  const openMerchantItem = (merchantItem: MerchantItem) => {
    if (merchantItem) {
      const path = ITEMS_ROUTES.ITEM.replace(':id', merchantItem.id.toString());
      const selectedItem: Item = toItem(merchantItem);
      dispatch(setActiveMerchant(merchantItem.merchant));
      dispatch(setActiveItem(selectedItem));
      navigate(path);
    }
  };

  const discountClickHandler = (discount: Discount) => {
    dispatch(setActiveDiscount(discount));
    navigate(DISCOUNTS_ROUTES.DISCOUNT.replace(':id', discount.id.toString()));
  };

  return (
    <Stack
      width='100%'
      height='100%'
      sx={{
        bgcolor: (theme) => theme.palette.light.main
      }}
    >
      <CustomAppBar
        toolbar={
          <>
            <BackButton onClick={() => goBack()} />
            <ItemName item={item} />
          </>
        }
      >
        {!!item?.customName && item?.customName != item?.name && (
          <>
            <Box px={2} py={1}>
              <Typography
                variant='body1'
                color='text.secondary'
                align='left'
                sx={{ overflowWrap: 'break-word' }}
              >
                Original name: {item.name}
              </Typography>
            </Box>
            <Divider variant='middle' />
          </>
        )}
        <MerchantListItem
          component='div'
          merchant={isMerchantLoading ? null : merchant}
          divider={false}
          onSecondaryClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            if (merchant) {
              openMerchant(merchant);
            }
          }}
        />
        <Divider variant='middle' />
        {item && !isMerchantLoading ? (
          <Stack justifyContent='center' alignItems='center' gap={2} pt={1.4} pb={1} px={2}>
            <Box
              height={150}
              width={150}
              className={`productImg ${!item.defaultImage?.url ? `productImgEmpty` : null}`}
            >
              {item.defaultImage?.url ? (
                <img src={item.defaultImage.url} alt={item.name} />
              ) : (
                <ImageNotSupportedOutlinedIcon
                  sx={{ fontSize: '100px !important' }}
                  className='productImgIcon'
                />
              )}
            </Box>
            <Box sx={{ flexDirection: 'row' }} className='productItem'>
              <Typography variant='body1' color='text.secondary'>
                Price
              </Typography>
              <Typography variant='h5' fontWeight={600} textAlign='right'>
                {formatItemPrice(item.price)}
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Skeleton sx={{ mx: 1.5, my: 1.5 }} />
        )}
        {!!subdomain && accessToken && !!item && <AddToCartButton item={item} />}
      </CustomAppBar>
      <Box
        sx={{
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        {isOmniItemsLoading || isDiscountsLoading ? (
          <Skeleton variant='rounded' width='auto' height={'13.4rem'} sx={{ my: 2.5, mx: 2.4 }} />
        ) : (
          <>
            {discounts.length ? (
              <Box>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={-16}
                  centeredSlides={true}
                  className='mySwiper'
                >
                  {discounts.map((discount) => (
                    <SwiperSlide key={discount.id}>
                      <DiscountCard
                        discount={discount}
                        item={item}
                        onClick={discountClickHandler}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            ) : (
              <Box display='flex' alignItems='center' justifyContent='center' height='5rem'>
                <Typography color={'text.secondary'}>No discounts available</Typography>
              </Box>
            )}
          </>
        )}
        {isOmniItemsLoading || isDiscountsLoading || isGeolocationLoading ? (
          <Box sx={{ mx: 2 }}>
            <Skeleton width='60%' />
            <Box width='fit-content' display='flex' gap={2} mt={2.4}>
              <Skeleton variant='rounded' width='12rem' height={'10.8rem'} />
              <Skeleton variant='rounded' width='12rem' height={'10.8rem'} />
            </Box>
          </Box>
        ) : (
          <>
            <Typography variant='h5' fontWeight={600} textAlign='left' sx={{ px: 2 }}>
              Stores with this product
            </Typography>
            {omniItems.length ? (
              <Box>
                <Swiper slidesPerView={1.69} spaceBetween={-16} className='mySwiper'>
                  {omniItems.map((omniItem) => (
                    <SwiperSlide key={omniItem.id}>
                      <MerchantItemCard
                        item={omniItem}
                        onClick={openMerchantItem}
                        onSecondaryClick={(
                          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                        ) => {
                          event.stopPropagation();
                          if (omniItem.merchant) {
                            openMerchant(omniItem.merchant);
                          }
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            ) : (
              <Box display='flex' alignItems='center' justifyContent='center' height='4rem'>
                <Typography color={'text.secondary'}>No other stores found</Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Stack>
  );
}

export default ItemPage;

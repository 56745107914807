import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { MapPosition } from 'src/shared/interfaces/mapPoint.interface';

interface GeolocationState {
  current: MapPosition | null;
  isGeolocationEnable: boolean | null;
}

const initialState: GeolocationState = {
  current: null,
  isGeolocationEnable: null
};

export const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,

  reducers: {
    setGeolocation(state, action: PayloadAction<GeolocationState['current']>) {
      state.current = action.payload;
    },
    setIsGeolocationEnable(state, action: PayloadAction<GeolocationState['isGeolocationEnable']>) {
      state.isGeolocationEnable = action.payload;
    }
  }
});

export const { setGeolocation, setIsGeolocationEnable } = geolocationSlice.actions;

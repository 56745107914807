import React, { FC } from 'react';
import { Box, ListItem, ListItemButton, ListItemText, Skeleton, Typography } from '@mui/material';
import { Discount } from 'src/shared/interfaces/discount.interface';
import 'src/components/Discounts/styles.css';
import { ReactComponent as CouponIcon } from 'src/images/coupon_icon.svg';
import {
  getDiscountAmount,
  getDiscountName,
  getDiscountProductsAmount
} from 'src/utils/scripts/discount';

interface Props {
  discount: Discount | null;
  showProductAmount?: boolean;
  showMerchantName?: boolean;
  onClick?(): void;
}

const DiscountsListItem: FC<Props> = ({
  discount,
  showProductAmount = true,
  showMerchantName = false,
  onClick = () => {}
}) => {
  return (
    <>
      {discount ? (
        <ListItem
          className='item'
          onClick={onClick}
          sx={{
            width: 'auto',
            height: 'fit-content',
            p: 0,
            borderRadius: '8px',
            backgroundColor: 'white'
          }}
        >
          <ListItemButton dense sx={{ pr: 1, gap: 2, borderRadius: '8px' }}>
            <ListItemText
              sx={{ flex: 1 }}
              primary={
                <Typography sx={{ fontWeight: 'bold' }} noWrap>
                  {getDiscountName(discount)}
                </Typography>
              }
              secondary={
                <>
                  {showMerchantName && <>{discount!.merchant?.name}</>}
                  {showMerchantName && showProductAmount && <br />}
                  {showProductAmount && <>{getDiscountProductsAmount(discount!)}</>}
                </>
              }
            />
            {discount.coupon && <CouponIcon />}
            <Box
              className={'dashed-border'}
              sx={{
                minWidth: { xs: '4rem', md: '6rem' },
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                pl: 1
              }}
              gap='2px'
            >
              <Typography
                variant={'body2'}
                color={'text.secondary'}
                sx={{ fontSize: '10px', lineHeight: '14px' }}
                noWrap
              >
                Discount
              </Typography>
              <Typography variant={'h4'} sx={{ fontSize: '18px', lineHeight: '25.2px' }} noWrap>
                {getDiscountAmount(discount)}
              </Typography>
            </Box>
          </ListItemButton>
        </ListItem>
      ) : (
        <Skeleton variant='rounded' width='auto' height={'3rem'} sx={{ mx: 2, my: 1 }} />
      )}
    </>
  );
};

export default DiscountsListItem;

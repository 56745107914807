//DiscountHeader

import React, { FC } from 'react';
import { Skeleton, Stack, Typography } from '@mui/material';
import { DiscountType, getItemName } from 'src/shared/enums';
import { formatItemPrice } from 'src/utils/scripts/items';
import { Item } from 'src/shared/interfaces/items.interface';
import { Discount } from 'src/shared/interfaces/discount.interface';

interface Props {
  item: Item | null;
  discount?: Discount | null;
  gap?: number;
  bold?: boolean;
}

const DiscountItem: FC<Props> = ({ item, discount, gap = 0.4, bold = false }) => {
  function getPriceWithDiscount(price: number): number {
    let priceWithDiscount = price;
    if (discount) {
      switch (discount.discountType) {
        case DiscountType.PERCENT:
          if (discount.amounts[0].amount != 0)
            priceWithDiscount = price - (price * discount.amounts[0].amount) / 100;
          break;
        case DiscountType.OVERRIDE:
          priceWithDiscount = discount.amounts[0].amount;
          break;
        default:
          priceWithDiscount = price - discount.amounts[0]?.amount || 0;
          break;
      }
    }
    return Math.max(priceWithDiscount, 0);
  }

  return (
    <Stack width='100%' gap={gap} sx={{ px: 2, boxSizing: 'border-box' }}>
      {item ? (
        <>
          <Stack direction='row' justifyContent='space-between' gap={2}>
            <Typography variant='body1' color='text.secondary'>
              Products
            </Typography>
            <Typography
              variant={bold ? 'h5' : 'body1'}
              fontWeight={500}
              textAlign='right'
              sx={{ maxWidth: '75%', overflowWrap: 'break-word' }}
            >
              {getItemName(item)}
            </Typography>
          </Stack>
          <Stack direction='row' justifyContent='space-between' gap={2}>
            <Typography variant='body1' color='text.secondary'>
              Price
            </Typography>
            <Typography
              variant={bold ? 'h5' : 'body1'}
              fontWeight={bold ? 600 : 500}
              textAlign='right'
            >
              {formatItemPrice(getPriceWithDiscount(item.price))}
            </Typography>
          </Stack>
        </>
      ) : (
        <>
          <Skeleton width='auto' />
          <Skeleton width='auto' />
        </>
      )}
    </Stack>
  );
};

export default DiscountItem;

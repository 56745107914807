import React, { FC } from 'react';

// mui
import { List } from '@mui/material';

// components
import CartItemsListItem from 'src/components/Cart/CartItemsListItem';
import { CartItem } from 'src/shared/interfaces/cartItem.interface';

interface Props {
  cartItems: CartItem[];
  isLoading: boolean;
  onClick(cartItem: CartItem): void;
  onQuantityChange(cartItem: CartItem, quantity: number): void;
  onDelete(cartItem: CartItem): void;
}

const CartItemsList: FC<Props> = ({
  cartItems,
  onClick,
  onQuantityChange,
  onDelete,
  isLoading
}) => {
  return (
    <List sx={{ width: '100%' }} disablePadding>
      {cartItems.map((item, i) => (
        <CartItemsListItem
          key={'cartList-' + i}
          cartItem={item}
          isLoading={isLoading}
          onClick={() => onClick(item)}
          setQuantity={(quantity) => onQuantityChange(item, quantity)}
          onDelete={() => onDelete(item)}
        />
      ))}
    </List>
  );
};

export default CartItemsList;

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//components
import { AppBar, Box, Skeleton, Stack, Typography } from '@mui/material';
import InfiniteScroll from 'src/components/InfiniteScroll/InfiniteScroll';
import { OrdersList } from 'src/components/Orders';
import LoginToContinue from 'src/components/LoginToContinue';

//redux
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { thunkOrders } from 'src/store/thunks';
import {
  setActiveOrder,
  setCurrentPage,
  setIsLastPage,
  setOrders
} from 'src/store/slices/ordersSlice';

//types
import { RequestStatuses } from 'src/utils/enums';
import { Page } from 'src/shared/interfaces/page.interface';

//constants
import { ORDERS_ROUTES } from 'src/shared/consts/Rout.consts';

//scripts
import { getAccessToken } from 'src/utils/scripts';
import { API_URL } from 'src/config';
import { ORDER_PATH } from 'src/shared/consts/Api.consts';
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Order } from 'src/shared/interfaces/order.interface';

const OrdersPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state) => state.orders.orders);
  const [isLoading, setIsLoading] = useState(true);
  const currentPage = useAppSelector((state) => state.orders.currentPage);
  const isLastPage = useAppSelector((state) => state.orders.isLastPage);
  const eventAbortController = new AbortController();
  const accessToken = getAccessToken();
  const eStoreSubdomain = getSubDomain();
  const ordersRef = useRef(orders);

  useEffect(() => {
    if (!orders.length && accessToken) {
      fetchOrders(0);
    } else {
      setIsLoading(false);
    }
    subscribeForOrdersUpdate();
    return () => {
      eventAbortController?.abort();
    };
  }, []);

  useEffect(() => {
    ordersRef.current = orders;
  }, [orders]);

  async function fetchOrders(pageNumber: number) {
    setIsLoading(true);
    const response = await dispatch(thunkOrders({ page: pageNumber, sort: 'creationDate,DESC' }));
    if (response.meta.requestStatus === RequestStatuses.fulfilled) {
      setOrdersResponse(response.payload as Page<Order>, pageNumber);
      setIsLoading(false);
    }
  }

  function setOrdersResponse(payload: Page<Order>, pageNumber: number) {
    const { content } = payload;
    dispatch(setIsLastPage(payload.last));
    dispatch(setCurrentPage(pageNumber));
    if (pageNumber == 0) {
      dispatch(setOrders(content));
    } else {
      dispatch(setOrders([...ordersRef.current, ...content]));
    }
  }

  function loadPage(pageNumber: number) {
    fetchOrders(pageNumber);
  }

  function openOrderPage(order: Order) {
    dispatch(setActiveOrder(order));
    navigate(ORDERS_ROUTES.ORDER.replace(':id', order.id.toString()));
  }

  function subscribeForOrdersUpdate() {
    const url = `${API_URL}/${ORDER_PATH.ORDERS_EVENTS}`;
    const headers = {
      Authorization: 'Bearer ' + accessToken,
      'X-SUBDOMAIN': eStoreSubdomain ?? ''
    };

    const fetchData = async () => {
      await fetchEventSource(url, {
        method: 'GET',
        headers: headers,
        signal: eventAbortController.signal,
        openWhenHidden: true,
        onmessage(event) {
          if (!event.data) {
            return;
          }
          try {
            const parsedData = JSON.parse(event.data.trim());
            const result = parsedData as Order;
            updateOrder(result);
          } catch (error) {}
        },
        onclose() {
          eventAbortController.abort();
        }
      });
    };
    fetchData();
  }

  function updateOrder(updatedOrder: Order) {
    const orders = ordersRef.current;
    const index = orders.findIndex((it) => it.id == updatedOrder.id);
    if (index != -1) {
      const currentOrder = Object.assign({}, orders[index]);
      currentOrder.state = updatedOrder.state;
      dispatch(setOrders([...orders.slice(0, index), currentOrder, ...orders.slice(index + 1)]));
    }
  }

  return (
    <Stack
      width='100%'
      height='100%'
      sx={{
        boxSizing: 'border-box'
      }}
    >
      <AppBar position='static' sx={{ height: 'fit-content', zIndex: 1000 }}>
        <Typography variant='h4' color='white' my={1}>
          Orders
        </Typography>
      </AppBar>
      <Stack flex={1} overflow='auto'>
        {!!accessToken ? (
          <InfiniteScroll
            elementsLength={orders.length}
            isLoading={isLoading}
            isLastPage={isLastPage}
            pageNumber={currentPage}
            notFoundMessage={'No orders yet'}
            loadPage={loadPage}
            pageLoader={
              <Box sx={{ position: 'relative', top: '-0.5rem' }}>
                <Skeleton />
              </Box>
            }
          >
            <OrdersList orders={orders} onClick={openOrderPage} />
          </InfiniteScroll>
        ) : (
          <Box height='100%' p={2}>
            <LoginToContinue header={'Log in to see your orders'} />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default OrdersPage;

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ItemInDiscount } from 'src/shared/interfaces/items.interface';

// types

interface discountItemsState {
  discountId: number | null;
  discountItems: ItemInDiscount[];
}

const initialState: discountItemsState = {
  discountId: null,
  discountItems: []
};

export const discountItemsSlice = createSlice({
  name: 'discountItemsSlice',
  initialState,

  reducers: {
    setDiscountItems(state, action: PayloadAction<discountItemsState['discountItems']>) {
      state.discountItems = action.payload;
    },
    setDiscountId(state, action: PayloadAction<discountItemsState['discountId']>) {
      state.discountId = action.payload;
    }
  }
});

export const { setDiscountItems, setDiscountId } = discountItemsSlice.actions;

export default discountItemsSlice.reducer;

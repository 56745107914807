import React, { FC } from 'react';

//components
import { List } from '@mui/material';
import OrderItemsListItem from 'src/components/OrderItems/OrderItemsListItem';
import { OrderItem } from 'src/shared/interfaces/order.interface';

//types

interface Props {
  orderItems: OrderItem[];
  onClick(orderItem: OrderItem): void;
}

const OrderItemsList: FC<Props> = ({ orderItems, onClick }) => {
  return (
    <List sx={{ width: '100%' }} disablePadding>
      {orderItems.map((orderItem) => (
        <OrderItemsListItem
          key={orderItem.item.id}
          orderItem={orderItem}
          onClick={() => onClick(orderItem)}
        />
      ))}
    </List>
  );
};

export default OrderItemsList;

import { InputHelperMessage } from 'src/shared/interfaces/inputHelperMessage.interface';
import { HELPER_TEXT } from 'src/shared/consts/Messages.consts';
import React from 'react';

//TODO refactor
export function isEmailValid(email: string): boolean {
  const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,4}$/;
  return emailRegExp.test(email);
}

export function isPhoneNumberPartiallyValid(phoneNumber: string | undefined): boolean {
  if (!phoneNumber) return true;
  const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);
  const regExp = /^(1|)?(\d{3}(?!$))?(\d{1,3}|)?(\d{1,4}|)$/;
  const match = cleanedPhoneNumber.match(regExp);

  return match != undefined && match?.length > 0;
}

export function isPhoneNumberValid(phoneNumber: string | undefined): boolean {
  if (!phoneNumber) return true;
  const cleanedPhoneNumber = cleanPhoneNumber(phoneNumber);
  const phoneNumberRegExp = /^(1|)?\d{3}\d{3}\d{4}$/;
  return phoneNumberRegExp.test(cleanedPhoneNumber);
}

export function cleanPhoneNumber(phoneNumber: string | undefined): string {
  if (!phoneNumber) return '';
  return phoneNumber.replace(/\D/g, '');
}

export function isPasswordValid(password: string): boolean {
  const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  return passwordRegExp.test(password);
}

export function validateEmail(content: React.ChangeEvent<HTMLInputElement>): InputHelperMessage {
  const value = content.target.value;
  if (value === '') {
    return { message: 'Please enter an email address', error: true };
  }

  const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const forbiddenCharsRegExp = /[!#$%^&*()+={}[\]<>?/|\\]/;

  if (forbiddenCharsRegExp.test(value)) {
    return { message: 'Email contains forbidden characters', error: true };
  }

  const isEmailValid = emailRegExp.test(value);
  const message = isEmailValid ? '' : 'Invalid email format';
  return { message: message, error: !isEmailValid };
}

export function validatePassword(content: any): InputHelperMessage {
  const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  const isPasswordValid = passwordRegExp.test(content as string);
  const message = isPasswordValid ? '' : HELPER_TEXT.PASSWORD;
  return { message: message, error: !isPasswordValid };
}

export const inputValidation = (name: string, content: any): InputHelperMessage => {
  switch (name) {
    case 'fullName':
      const nameAndSurname = content as string;
      const arrayOfNames = nameAndSurname.split(' ');
      if (content === '' || arrayOfNames.length < 2 || arrayOfNames[1].length === 0) {
        return { message: 'Please enter your name and surname', error: true };
      }
      return { message: '', error: false };
    case 'email':
      return validateEmail(content);
    case 'password':
      return validatePassword(content);
    default:
      return { message: '', error: false };
  }
};

import React from 'react';
//Components
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { SingleAlert } from './singleAlert';
import IF from 'src/components/IF';
//Hooks
import { useAppSelector } from 'src/hooks/hooks';

const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          textAlign: 'left'
        }
      }
    }
  }
});

export const Alerts: React.FC = () => {
  const { alerts } = useAppSelector((state) => state.main);
  return (
    <IF condition={alerts.length !== 0}>
      <ThemeProvider theme={theme}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='end'
          gap='20px'
          overflow='hidden'
          position='absolute'
          zIndex={9999}
          bottom='60px'
          maxWidth='90%'
          sx={{ right: 0, left: 0, margin: '0 auto', width: '80%' }}
        >
          {alerts.map((alert) => (
            <SingleAlert key={alert.uuid} alert={alert} />
          ))}
        </Box>
      </ThemeProvider>
    </IF>
  );
};

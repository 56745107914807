import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// constants
import { AUTH_ROUTES } from 'src/shared/consts/Rout.consts';

// utils
import { getAccessToken } from 'src/utils/scripts';
import { getSubDomain } from 'src/utils/scripts/subdomain';

export const PrivateRoute = () => {
  const token = getAccessToken();
  const subdomain = getSubDomain();

  if (!token && !subdomain) {
    return <Navigate to={AUTH_ROUTES.LOGIN} replace={true} />;
  }

  return <Outlet />;
};

const ERROR = {
  PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
  INCORRECT_PHONE_NUMBER: 'Incorrect phone number',
  MUST_PHONE_NUMBER: 'Phone number is required',
  INCORRECT_FIELD: 'Only letters, spaces, dots, dashes, and apostrophes are allowed.'
};

const HELPER_TEXT = {
  PASSWORD:
    'Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter and a digit',
  PHONE_NUMBER: '(XXX) XXX-XXXX'
};

export { ERROR, HELPER_TEXT };
